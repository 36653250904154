import React, { useEffect, useState, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useMutation } from "react-query";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from '@mui/material/utils';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import moment from "moment";

import Config from "../config";
import API from "../APIs/API";
import Sidebar from "../components/Sidebar";
import { useMe } from "../hooks/useMe";
import { EmployeeReportLink } from "../components/EmployeeReportLink";

import "../styles/profile.scss";
let selected = false;


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black,
    fontWeight: 600
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const LeaveCredits = ({ type }) => {
  const { admin_location_id, id } = useParams();
  const { user: loggedUser, permissions } = useMe(admin_location_id);

  const pageUserType = type === "Non-Teacher" ? "non-teachers" : "teachers";
  const navigate = useNavigate();

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState(null);
  const [detail, setDetail] = useState(null);
  const [loadingDetail, setLoadingDetail] = useState(false);

  const leaveCreate = useMutation((body) => new API().addLeave(body), {
    onSuccess(data) {
      if (data) {
        toast.success("Leave created Successfully!");
        let linkBack = '';
        if (admin_location_id) {
          linkBack = `/${pageUserType}/${admin_location_id}/leave/view/${data["data"]["id"]}`;
        } else {
          linkBack = `/leave${type == 'All' ? '' : `/${pageUserType}`}`;
        }
        navigate(linkBack);
      } else {
        toast.error("Leave creation Failed!");
      }
    },
  });

  const fetch = useMemo(
    () =>
      debounce((txt, callback) => {
        console.log(txt, selected)
        if (txt && !selected) {
          setLoading(true);
          new API().getLeaveCreditsEmps(txt).then((data) => {
            console.log(data);
            setLoading(false);
            if (data != null) {
              callback(data.rows);
            }
          });
        } else {
          selected = false;
        }
      }, 500),
    [],
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch(inputValue, (results) => {
      if (active) {
        let newOptions = [];

        if (results) {
          newOptions = [...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch])

  let linkBack = '';
  if (admin_location_id) {
    linkBack = `/${pageUserType}/${admin_location_id}/leave`;
  } else {
    linkBack = `/leave${type == 'All' ? '' : `/${pageUserType}`}`;
  }

  const getLeaveReportURL = (row) => {
    let url = Config.api_url + "/v1/admin/leave/print_leave_form?id=" + row?.id;
    return url;
  };

  return (
    <div className="wrapper">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="content">
        <div className="profile">
          <div className="profileContainer">
            {<div className="profileSection">
              <Link to={linkBack} className="backButtonSection">
                <KeyboardBackspaceIcon className="backButton" />
              </Link>
              <br />
              <br />

              <form>
                <div className="container">
                  <div className="row">
                    <div className="row">
                      <div className="col col-4">
                        <h1>Leave Credits</h1>
                      </div>
                      <div className="col col-8">
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                          {/* {<EmployeeReportLink
                            url={getLeaveReportURL(employee)} className={'saveBtn btn btn-primary btn-block mb-3'}
                            filename={`leave_${employee?.employee_no}`}
                          >
                            Print Report
                          </EmployeeReportLink>} */}
                        </div>
                      </div>
                    </div>
                    <hr />

                    <div className="row">
                      <div className="row" style={{ margin: '0px' }}>
                        <div className="col col-4">
                          <div className="form-outline">
                            <label className="form-label" htmlFor="name_report">
                              <b>Name Report</b>
                            </label>
                            <br />
                            <Autocomplete
                              getOptionLabel={(option) =>
                                typeof option === 'string' ? option : option.name_report
                              }
                              className="autocomplete"
                              filterOptions={(x) => x}
                              options={options}
                              autoComplete
                              includeInputInList
                              filterSelectedOptions
                              value={value}
                              noOptionsText={inputValue ? "No Records Found" : "Type to search"}
                              loading={loading}
                              onChange={(event, newValue) => {
                                setValue(newValue);
                              }}
                              onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} fullWidth />
                              )}
                              renderOption={(props, option) => {
                                const { id, onClick, ...optionProps } = props;
                                return (
                                  <div key={id} {...optionProps} style={{ padding: '5px' }}
                                    onClick={e => {
                                      console.log(option)
                                      setEmployee(option);
                                      setDetail(null);
                                      setLoadingDetail(true);
                                      new API().getLeaveCredits(option.employee_no).then((data) => {
                                        console.log(data);
                                        setDetail(data.data);
                                        setLoadingDetail(false);
                                      });
                                      selected = true;
                                      onClick(e);
                                    }}>
                                    <div>
                                      <div>{option.name_report}</div>
                                      <div style={{ fontSize: '13px', color: 'gray' }}>{option.employee_no}</div>
                                    </div>
                                  </div>
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="col col-4">
                          <div className="form-outline">
                            <label className="form-label" htmlFor="employee_no">
                              <b>Employee No</b>
                            </label>
                            <br />
                            <input
                              className="form-control"
                              type="text"
                              name="employee_no"
                              id="employee_no"
                              value={employee?.employee_no}
                            />
                          </div>
                        </div>
                        <div className="col col-4">
                          <div className="form-outline">
                            <label className="form-label" htmlFor="occup_pos_title">
                              <b>Occup Pos Title</b>
                            </label>
                            <br />
                            <input
                              className="form-control"
                              type="text"
                              name="occup_pos_title"
                              id="occup_pos_title"
                              value={employee?.occup_pos_title}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ margin: '12px', paddingTop: '20px' }}>
                        {loadingDetail && <div>
                          <div style={{ padding: 20, textAlign: 'center' }}>
                            <CircularProgress size={40} color="inherit" />
                          </div>
                        </div>}
                        {!loadingDetail != null && detail != null && <TableContainer style={{ padding: '0px' }} component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell width={70}>No</StyledTableCell>
                                <StyledTableCell align="left">Leave Type</StyledTableCell>
                                <StyledTableCell align="left">Used</StyledTableCell>
                                <StyledTableCell align="left">Available</StyledTableCell>
                                <StyledTableCell align="left">Due</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {detail.map((row, idx) => (
                                <StyledTableRow
                                  key={idx}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <StyledTableCell component="th" scope="row" width={70}>
                                    {idx + 1}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">{row.type}</StyledTableCell>
                                  <StyledTableCell align="left">{row.used}</StyledTableCell>
                                  <StyledTableCell align="left">{row.available}</StyledTableCell>
                                  <StyledTableCell align="left">{(row.due && row.due != 'N/A') ? moment(row.due).format('ddd D MMM YYYY') : row.due}</StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>}
                        {/* {!loadingDetail != null && detail != null && <div className="leave-creadits">
                          <div className="header">
                            <div>No</div>
                            <div>Leave Type</div>
                            <div>Used</div>
                            <div>Available</div>
                            <div>Due</div>
                          </div>
                          {detail.map((d, idx) => {
                            return <div key={idx} className="content">
                              <div>{idx + 1}</div>
                              <div>{d.type}</div>
                              <div>{d.used}</div>
                              <div>{d.available}</div>
                              <div>{d.due}</div>
                            </div>
                          })}
                        </div>} */}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaveCredits;
