import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useMutation } from "react-query";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from '@mui/material/CircularProgress';

import API from "../APIs/API";
import Sidebar from "../components/Sidebar";
import { useMe } from "../hooks/useMe";

import "../styles/profile.scss";

const HrAdd = ({ type, viewMode }) => {
  const { user: loggedUser } = useMe();

  const pageUserType = type === "Non-Teacher" ? "non-teachers" : "teachers";
  const { admin_location_id, id } = useParams();
  const navigate = useNavigate();

  const hrUpdate = useMutation((body) => new API().updateHr(body), {
    onSuccess(data) {
      if (data) {
        toast.success("Form updated Successfully!");
        let linkBack = '';
        if (admin_location_id) {
          linkBack = `/${pageUserType}/${admin_location_id}/hr`;
        } else {
          linkBack = `/hr${type == 'All' ? '' : `/${pageUserType}`}`;
        }
        navigate(linkBack);
      } else {
        toast.error("Form update Failed!");
      }
    },
  });

  const loadData = () => {
    new API().getHrById(id, admin_location_id).then((data) => {
      const hrData = data["data"]["hr"];
      set_hr(hrData);
    });
  }

  const { data: users = [], isFetching: isUsersFetching } = useQuery(["users"], {
    queryFn: async () => {
      return await new API().getAllUsers('?is_all=1');
    },
  });

  let initialData = {
    admin_location_id: admin_location_id,
    hr_type: '',
    user_type: ((type && type != 'All') ? type : ''),
    created_by: loggedUser?.email,
    hr_head_users: []
  };

  const [event, setEvent] = useState(null);
  const [hr, set_hr] = useState(null);

  useEffect(() => {
    if (id && viewMode) {
      loadData();
    } else {
      setEvent(initialData);
    }
  }, [id])

  useEffect(() => {
    if (hr) {
      const hr_head_users = hr?.hr_head_users || [];
      const users2 = users?.rows || [];
      if (users2.length > 0 && hr_head_users.length > 0) {
        setEvent({ ...hr, hr_head_users: users2.filter(u => hr_head_users.includes(u.id)) });
      } else {
        setEvent({ ...hr });
      }
    }
  }, [users, hr])

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (!event.hr_head_users || event.hr_head_users.length == 0) {
    //   toast.error("Please select hr head users!");
    //   return;
    // }

    hrUpdate.mutate({
      ...event,
      id,
      admin_location_id,
      user_type: ((type && type != 'All') ? type : ''),
      hr_head_users: (event.hr_head_users || []).map(u => u.id)
    });
  };

  let linkBack = '';
  if (admin_location_id) {
    linkBack = `/${pageUserType}/${admin_location_id}/hr`;
  } else {
    linkBack = `/hr${type == 'All' ? '' : `/${pageUserType}`}`;
  }

  return (
    <div className="wrapper">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="content">
        <div className="profile">
          <div className="profileContainer">
            <div className="profileSection">
              <Link
                to={linkBack}
                className="backButtonSection"
              >
                <KeyboardBackspaceIcon className="backButton" />
              </Link>
              <br />
              <br />
              {(event == null) && <div>
                <div style={{ padding: 20, textAlign: 'center' }}>
                  <CircularProgress size={40} color="inherit" />
                </div>
              </div>}
              {event != null && <form onSubmit={(e) => handleSubmit(e)}>
                <div className="container">
                  <div className="row">
                    <div className="row">
                      <div className="col col-sm">
                        <h1>{viewMode ? 'Details' : 'Create New'}</h1>
                      </div>
                      <div className="col col-sm" style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                        <button
                          type="submit"
                          className="saveBtn btn btn-primary btn-block mb-3"
                        >
                          {viewMode ? 'Save Changes' : 'Create'}
                        </button>
                      </div>
                    </div>
                    <hr />

                    <div className="row">
                      <div className="col col-8">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="hr_type">
                            <b>HR Type</b>
                          </label>
                          <br />
                          <input
                            className="form-control"
                            type="text"
                            name="hr_type"
                            id="hr_type"
                            value={event?.hr_type || ''}
                            disabled
                          />
                        </div>
                        <div className="form-outline2" style={{ paddingTop: '5px' }}>
                          <label className="form-label">
                            <b>HR Users</b>
                          </label>
                          <br />
                          <Autocomplete
                            multiple
                            id="combo-box"
                            options={(users?.rows || []).filter(u => u.email)}
                            fullWidth
                            getOptionLabel={(option) => `${option.given_name || ''} ${option.surname || ''}(${option.email})`}
                            loading={isUsersFetching}
                            disabled={false}
                            renderInput={(params) => (
                              <TextField
                                sx={{ "& input": { width: "100%" } }}
                                {...params}
                                placeholder={event?.hr_type ? `Select ${event.hr_type || ''} User` : ''}
                              />
                            )}
                            onChange={(e, value) => {
                              setEvent({ ...event, hr_head_users: value });
                            }}
                            filterSelectedOptions
                            value={event?.hr_head_users || []}
                          />
                        </div>
                      </div>
                      <div className="col col-4">

                      </div>
                    </div>
                  </div>
                </div>
              </form>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HrAdd;
