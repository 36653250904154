import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useMutation } from "react-query";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from '@mui/material/CircularProgress';
import { Modal, AutoComplete as AutoComplete2, Input } from "antd";

import API from "../APIs/API";
import Sidebar from "../components/Sidebar";
import { useMe } from "../hooks/useMe";

import "../styles/profile.scss";

const BranchAdd = ({ type, viewMode }) => {
  const { user: loggedUser } = useMe();

  const pageUserType = type === "Non-Teacher" ? "non-teachers" : "teachers";
  const { admin_location_id, id } = useParams();
  const navigate = useNavigate();
  const branchCreate = useMutation((body) => new API().addBranch(body), {
    onSuccess(data) {
      if (data) {
        toast.success("Branch created Successfully!");
        let linkBack = '';
        if (admin_location_id) {
          linkBack = `/${pageUserType}/${admin_location_id}/branch`;
        } else {
          linkBack = `/branch${type == 'All' ? '' : `/${pageUserType}`}`;
        }
        navigate(linkBack);
      } else {
        toast.error("Branch creation Failed!");
      }
    },
  });

  const branchUpdate = useMutation((body) => new API().updateBranch(body), {
    onSuccess(data) {
      if (data) {
        toast.success("Branch updated Successfully!");
        let linkBack = '';
        if (admin_location_id) {
          linkBack = `/${pageUserType}/${admin_location_id}/branch`;
        } else {
          linkBack = `/branch${type == 'All' ? '' : `/${pageUserType}`}`;
        }
        navigate(linkBack);
      } else {
        toast.error("Branch update Failed!");
      }
    },
  });

  const deleteDivison = useMutation({
    mutationFn: () => new API().deleteBranch(id),
    onSuccess: (data) => {
      if (data) {
        toast.success("Branch deleted successfully");
        let linkBack = '';
        if (admin_location_id) {
          linkBack = `/${pageUserType}/${admin_location_id}/branch`;
        } else {
          linkBack = `/branch${type == 'All' ? '' : `/${pageUserType}`}`;
        }
        navigate(linkBack);
      } else {
        toast.error("Failed to delete Branch!");
      }
    },
  });

  const loadData = () => {
    new API().getBranchById(id).then((data) => {
      const branchData = data["data"]["branch"];
      set_branch(branchData);
    });
  }

  const { data: accountNoDescList = [] } = useQuery(["account_no_desc_list"], {
    queryFn: async () => {
      const result = await new API().getAccountNoDescList(admin_location_id);
      return [...result["data"]];
    },
  });

  const { data: users = [], isFetching: isUsersFetching } = useQuery(["users"], {
    queryFn: async () => {
      return await new API().getAllUsers('?is_all=1');
    },
  });

  let initialData = {
    admin_location_id: admin_location_id,
    user_type: ((type && type != 'All') ? type : ''),
    created_by: loggedUser?.email,
    branch_head_users: [],
    account_no_desc: ''
  };

  const [event, setEvent] = useState(null);
  const [branch, set_branch] = useState(null);

  useEffect(() => {
    if (id && viewMode) {
      loadData();
    } else {
      setEvent(initialData);
    }
  }, [id])

  useEffect(() => {
    if (branch) {
      const branch_head_users = branch?.branch_head_users || [];
      const users2 = users?.rows || [];
      if (users2.length > 0 && branch_head_users.length > 0) {
        setEvent({ ...branch, branch_head_users: users2.filter(u => branch_head_users.includes(u.id)) });
      }
    }
  }, [users, branch])

  const handleChange = (e) => {
    setEvent({ ...event, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!event.branch_head_users || event.branch_head_users.length == 0) {
      toast.error("Please select branch head users!");
      return;
    }
    if (!event.account_no_desc || event.account_no_desc.length == 0) {
      toast.error("Please select account no desc!");
      return;
    }

    if (viewMode) {
      branchUpdate.mutate({
        ...event,
        branch_head_users: event.branch_head_users.map(u => u.id)
      });
    } else {
      branchCreate.mutate({
        ...event,
        branch_head_users: event.branch_head_users.map(u => u.id)
      });
    }
  };

  let linkBack = '';
  if (admin_location_id) {
    linkBack = `/${pageUserType}/${admin_location_id}/branch`;
  } else {
    linkBack = `/branch${type == 'All' ? '' : `/${pageUserType}`}`;
  }

  const handleDeleteBranch = (e) => {
    e.preventDefault();
    e.stopPropagation();

    Modal.confirm({
      title: "Are you sure?",
      content: "Are you sure you want to delete this branch?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        deleteDivison.mutate();
      },
    });
  };

  return (
    <div className="wrapper">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="content">
        <div className="profile">
          <div className="profileContainer">
            <div className="profileSection">
              <Link
                to={linkBack}
                className="backButtonSection"
              >
                <KeyboardBackspaceIcon className="backButton" />
              </Link>
              <br />
              <br />
              {(event == null || accountNoDescList == null || accountNoDescList.length == 0) && <div>
                <div style={{ padding: 20, textAlign: 'center' }}>
                  <CircularProgress size={40} color="inherit" />
                </div>
              </div>}
              {event != null && accountNoDescList && accountNoDescList.length > 0 && <form onSubmit={(e) => handleSubmit(e)}>
                <div className="container">
                  <div className="row">
                    <div className="row">
                      <div className="col col-sm">
                        <h1>{viewMode ? 'Branch Details' : 'Create New Branch'}</h1>
                      </div>
                      <div className="col col-sm" style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                        {viewMode && <button type="button"
                          className="saveBtn btn btn-primary btn-block mb-3"
                          onClick={handleDeleteBranch}
                        >
                          Delete
                        </button>}
                        <button
                          type="submit"
                          className="saveBtn btn btn-primary btn-block mb-3"
                        >
                          {viewMode ? 'Save Changes' : 'Create'}
                        </button>
                      </div>
                    </div>
                    <hr />

                    <div className="row">
                      <div className="col col-8">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="account_no_desc">
                            <b>Account No Desc</b>
                          </label>
                          <br />
                          {/* <input
                            className="form-control"
                            type="text"
                            name="account_no_desc"
                            id="account_no_desc"
                            value={event?.account_no_desc}
                            onChange={(e) => handleChange(e)}
                            required
                          /> */}
                          <AutoComplete2
                            options={accountNoDescList.map(a => ({ label: a, value: a }))}
                            name="account_no_desc"
                            size="large"
                            style={{ width: '100%' }}
                            onChange={value => handleChange({ target: { value, name: "account_no_desc" } })}
                          >
                            <Input
                              value={event?.account_no_desc}
                            />
                          </AutoComplete2>
                        </div>
                        <div className="form-outline2" style={{ paddingTop: '5px' }}>
                          <label className="form-label">
                            <b>Branch Head Users</b>
                          </label>
                          <br />
                          <Autocomplete
                            multiple
                            id="combo-box"
                            options={(users?.rows || []).filter(u => u.email)}
                            fullWidth
                            getOptionLabel={(option) => `${option.given_name || ''} ${option.surname || ''}(${option.email})`}
                            loading={isUsersFetching}
                            disabled={false}
                            renderInput={(params) => (
                              <TextField
                                sx={{ "& input": { width: "100%" } }}
                                {...params}
                                placeholder="Select branch head user"
                              />
                            )}
                            onChange={(e, value) => {
                              setEvent({ ...event, branch_head_users: value });
                            }}
                            filterSelectedOptions
                            value={event?.branch_head_users || []}
                          />
                        </div>
                      </div>
                      <div className="col col-4">

                      </div>
                    </div>
                  </div>
                </div>
              </form>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BranchAdd;
