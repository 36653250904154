import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useMutation } from "react-query";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import CircularProgress from '@mui/material/CircularProgress';
import moment from "moment";
import { Modal } from "antd";

import API from "../APIs/API";
import Sidebar from "../components/Sidebar";
import { useMe } from "../hooks/useMe";
import { EmployeeReportLink } from "../components/EmployeeReportLink";
import Config from "../config";

import { TYPE_OF_LEAVE } from "../constants";

import "../styles/profile.scss";

const LeaveReturn = ({ type, viewMode }) => {
  const pageUserType = type === "Non-Teacher" ? "non-teachers" : "teachers";
  const { admin_location_id, id } = useParams();
  const { user: loggedUser, permissions } = useMe(admin_location_id);
  const navigate = useNavigate();

  const [isApproved, setIsApproved] = useState(false);
  const [isDivisionHead, setIsDivisionHead] = useState(false);

  const loadData = () => {
    if (id && viewMode) {
      new API().getLeaveReturnById({ id }).then((data) => {
        const returnData = data["return"];
        setIsApproved(returnData.status == 'Processed');
        if (!returnData) {
          setNoLeave(true);
          setEvent(null);
          return;
        }

        setNoLeave(false);

        setEvent({
          ...returnData,
          expected_return_date: dayjs(returnData.leave_info.resumption_date),
          actual_return_date: returnData.actual_return_date ? dayjs(returnData.actual_return_date) : null,
          reason: returnData.reason
        })
      });
      return;
    }
    new API().getLeaveReturnByEmployeeNo({ employee_no: loggedUser.employee_no }).then((data) => {
      const returnData = data["leave"];
      if (!returnData) {
        setNoLeave(true);
        setEvent(null);
        return;
      }

      setNoLeave(false);

      setEvent({
        ...returnData,
        expected_return_date: returnData.expected_return_date ? dayjs(returnData.expected_return_date) : null,
        actual_return_date: returnData.return_info?.actual_return_date ? dayjs(returnData.return_info?.actual_return_date) : null,
        reason: returnData.return_info?.reason
      })
    });
  }

  const leaveReturnCreate = useMutation((body) => new API().addLeaveReturn(body), {
    onSuccess(data) {
      if (data) {
        toast.success("Form created Successfully!");
        loadData();
      } else {
        toast.error("Form creation Failed!");
      }
    },
  });

  const leaveReturnProcess = useMutation((data) => new API().processLeaveReturn(data), {
    onSuccess(data) {
      if (data) {
        toast.success("Form processed Successfully!");
        setEvent(null);
        loadData();
      } else {
        toast.error("Form processing Failed!");
      }
    },
  });

  const deleteLeaveReturn = useMutation({
    mutationFn: () => new API().deleteLeaveReturn(id),
    onSuccess: (data) => {
      if (data) {
        toast.success("The form has been successfully deleted");
        navigate(`/${pageUserType}/${admin_location_id}/leave`);
      } else {
        toast.error("Failed to delete!");
      }
    },
  });

  const handleDeleteLeaveReturn = (e) => {
    e.preventDefault();
    e.stopPropagation();

    Modal.confirm({
      title: "Are you sure?",
      content: "Are you sure you want to delete this form?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        deleteLeaveReturn.mutate();
      },
    });
  };

  const [event, setEvent] = useState(null);
  const [editable, setEditable] = useState(!viewMode);
  const [noLeave, setNoLeave] = useState(null);

  useEffect(() => {
    if (loggedUser && event && viewMode) {
      const account_no_desc = event.employee_info?.account_no_desc || '';
      if (loggedUser.divisions && loggedUser.divisions.length > 0) {
        for (let idx = 0; idx < loggedUser.divisions.length; idx++) {
          const division = loggedUser.divisions[idx];

          if (division.account_no_desc && division.account_no_desc.length > 0) {
            if (division.account_no_desc.includes(account_no_desc)) {
              setIsDivisionHead(true);
            }
          }
        }
      }
    }
  }, [loggedUser, event])

  useEffect(() => {
    if (loggedUser) {
      loadData();
    }
  }, [loggedUser])

  const handleChange = (e) => {
    setEvent({ ...event, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!event.actual_return_date) {
      toast.error("Please select actual return date!");
      return;
    }

    const payload = {
      id: event.return_info?.id,
      employee_no: loggedUser.employee_no,
      leave_id: event.id,
      admin_location_id: event.admin_location_id,
      reason: event.reason,
      admin_location_id: event.admin_location_id,
      actual_return_date: event.actual_return_date ? event.actual_return_date.format("YYYY-MM-DD") : null
    }

    leaveReturnCreate.mutate(payload);
  };

  let linkBack = '';
  if (admin_location_id) {
    linkBack = `/${pageUserType}/${admin_location_id}/leave`;
  } else {
    linkBack = `/leave${type == 'All' ? '' : `/${pageUserType}`}`;
  }

  const getLeaveReturnReportURL = (row) => {
    let url = Config.api_url + "/v1/admin/leave/print_leave_return_form?id=" + row.id;
    return url;
  };

  return (
    <div className="wrapper">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="content">
        <div className="profile">
          <div className="profileContainer">
            <div className="profileSection">
              <Link to={linkBack} className="backButtonSection">
                <KeyboardBackspaceIcon className="backButton" />
              </Link>
              <br />
              <br />
              {noLeave && <div>
                <div style={{ padding: 20, textAlign: 'center' }}>
                  There is no valid leave!!!
                </div>
              </div>}
              {noLeave == null && event == null && <div>
                <div style={{ padding: 20, textAlign: 'center' }}>
                  <CircularProgress size={40} color="inherit" />
                </div>
              </div>}
              {event != null && <form onSubmit={(e) => handleSubmit(e)}>
                <div className="container">
                  <div className="row">
                    <div className="row">
                      <div className="col col-4">
                        <h1>Return From Leave</h1>
                      </div>
                      <div className="col col-8"
                        style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', padding: '0px' }}>
                        {viewMode && event.leave_info.is_returned && <EmployeeReportLink
                          url={getLeaveReturnReportURL(event)} className={'saveBtn btn btn-primary btn-block mb-3'}
                          filename={`leave_return_${event.employee_info?.name_report || event.employee_no}`}
                        >
                          Print Return from Leave Report
                        </EmployeeReportLink>}
                        {viewMode && !event?.leave_info?.is_returned && <button
                          type="button"
                          className={'saveBtn btn btn-primary btn-block mb-3'}
                          onClick={e => handleDeleteLeaveReturn(e)}
                        >
                          Delete
                        </button>}
                        {!viewMode && event?.return_info == null && <button
                          type="submit"
                          className="saveBtn btn btn-primary btn-block mb-3"
                        >
                          Submit
                        </button>}
                        {event?.return_info != null && <button
                          type="submit"
                          className="saveBtn btn btn-primary btn-block mb-3"
                        >
                          Save Changes
                        </button>}
                        {viewMode && !permissions.employee_user && event?.leave_info != null && <button
                          type="button"
                          className={'saveBtn btn btn-primary btn-block mb-3' + ((isApproved || !permissions.salary_officer_user) ? ' disabled' : '')}
                          disabled={isApproved || !permissions.salary_officer_user}
                          onClick={e => {
                            leaveReturnProcess.mutate({
                              id: event.id,
                              salary_officer_status: 'Approved'
                            });
                          }}
                        >
                          {event.leave_info.is_returned ? 'Processed' : 'Pending'}
                        </button>}
                      </div>
                    </div>
                    <hr />

                    <div className="row">
                      <div className="row" style={{ margin: '0px' }}>
                        <div className="col col-4">
                          <div className="form-outline">
                            <label className="form-label" htmlFor="type_of_leave">
                              <b>Type of leave</b>
                            </label>
                            <br />
                            <select
                              className="form-control"
                              name="type_of_leave"
                              id="type_of_leave"
                              value={event?.leave_info?.type_of_leave || event?.type_of_leave}
                              disabled
                            >
                              <option key={'none'} value={''}>{''}</option>
                              {TYPE_OF_LEAVE.map((item) => (
                                <option key={item} value={item}>{item}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                        {event?.type_of_leave == 'Other' && <div className="col col-8">
                          <div className="form-outline">
                            <label className="form-label" htmlFor="leave_name">
                              <b>Leave name</b>
                            </label>
                            <br />
                            <input
                              className="form-control"
                              type="text"
                              name="leave_name"
                              id="leave_name"
                              value={event?.leave_name}
                              disabled
                            />
                          </div>
                        </div>}
                      </div>
                      <div className="col col-4">
                        <div className="form-outline">
                          <label className="form-label">
                            <b>Expected return date</b>
                          </label>
                          <br />
                          <DatePicker className="date-input"
                            value={event?.expected_return_date}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col col-4">
                        <div className="form-outline">
                          <label className="form-label">
                            <b>Actual return date</b>
                          </label>
                          <br />
                          <DatePicker className="date-input"
                            value={event?.actual_return_date}
                            disabled={!editable}
                            onAccept={value => {
                              setEvent({ ...event, actual_return_date: value });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col col-4">

                      </div>
                      {event?.actual_return_date && event?.expected_return_date && !dayjs(event?.expected_return_date).isSame(event?.actual_return_date, 'date') && <div className="col col-8">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="reason">
                            <b>Reason for late/ early return</b>
                          </label>
                          <br />
                          <textarea
                            className="form-control"
                            type="text"
                            name="reason"
                            id="reason"
                            rows={3}
                            value={event?.reason}
                            disabled={!editable}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>}

                      {!permissions.employee_user && viewMode && <>
                        <div className="header3" style={{ marginTop: '20px' }}>
                          Approval by Branch/Section Head
                        </div>
                        <div className="row" style={{ margin: '0px', paddingRight: '28px' }}>
                          <div className="col col-8 leave-box" style={{ paddingLeft: '15px' }}>
                            <button
                              type="button"
                              style={{ float: 'unset', marginLeft: '15px' }}
                              className={'saveBtn btn btn-primary btn-block' + ((isApproved || !permissions.branch_head_user || !!event?.branch_head_status) ? ' disabled' : '')}
                              disabled={isApproved || !permissions.branch_head_user || !!event?.branch_head_status}
                              onClick={e => {
                                leaveReturnProcess.mutate({
                                  id: event.id,
                                  branch_head_status: 'Approved'
                                });
                              }}
                            >
                              {event?.branch_head_status ? 'Approved' : 'Pending'}
                            </button>
                            {event?.branch_head != null && <div style={{ paddingLeft: '15px' }} className="leave-timestamp">{event.branch_head.name_report + ', ' + (event.branch_head.occup_pos_title || '') + ', ' + moment(event.branch_head_updated_at).format('D MMM YYYY hh:mm:ss A')}</div>}
                          </div>
                        </div>
                        {/* <div className="header3" style={{ marginTop: '10px' }}>
                          Approval by Division Head
                        </div>
                        <div className="row" style={{ margin: '0px', paddingRight: '28px' }}>
                          <div className="col col-8 leave-box" style={{ paddingLeft: '15px' }}>
                            <button
                              type="button"
                              style={{ float: 'unset', marginLeft: '15px' }}
                              className={'saveBtn btn btn-primary btn-block' + ((!permissions.division_head_user && !isDivisionHead) || !!event?.division_head_status ? ' disabled' : '')}
                              disabled={isApproved || (!permissions.division_head_user && !isDivisionHead) || !!event?.division_head_status}
                              onClick={e => {
                                leaveReturnProcess.mutate({
                                  id: event.id,
                                  division_head_status: 'Approved'
                                });
                              }}
                            >
                              {event?.division_head_status ? 'Approved' : 'Pending'}
                            </button>
                            {event?.division_head != null && <div style={{ paddingLeft: '15px' }} className="leave-timestamp">{event.division_head.name_report + ', ' + (event.division_head.occup_pos_title || '') + ', ' + moment(event.division_head_updated_at).format('D MMM YYYY hh:mm:ss A')}</div>}
                          </div>
                        </div>
                        <div className="header3" style={{ marginTop: '10px' }}>
                          Approval by Department Delegate/ Authorised Officer
                        </div>
                        <div className="row" style={{ margin: '0px', paddingRight: '28px' }}>
                          <div className="col col-8 leave-box" style={{ paddingLeft: '15px' }}>
                            <button
                              type="button"
                              style={{ float: 'unset', marginLeft: '15px' }}
                              className={'saveBtn btn btn-primary btn-block' + (!permissions.agency_head_delegate_user || !!event?.authorized_officer_status ? ' disabled' : '')}
                              disabled={isApproved || !permissions.agency_head_delegate_user || !!event?.authorized_officer_status}
                              onClick={e => {
                                leaveReturnProcess.mutate({
                                  id: event.id,
                                  authorized_officer_status: 'Approved'
                                });
                              }}
                            >
                              {event?.authorized_officer_status ? 'Approved' : 'Pending'}
                            </button>
                          {event?.authorized_officer != null && <div style={{ paddingLeft: '15px' }} className="leave-timestamp">{event.authorized_officer.name_report + ', ' + (event.authorized_officer.occup_pos_title || '') + ', ' + moment(event.authorized_officer_updated_at).format('D MMM YYYY hh:mm:ss A')}</div>}
                          </div>
                        </div> */}
                      </>}
                      {viewMode && <div style={{ display: 'flex', gap: '15px', paddingTop: '25px' }}>
                        <div style={{ paddingTop: '4px', fontSize: '18px' }}>Return from Leave Status:</div>
                        <button
                          type="button"
                          style={{ float: 'unset' }}
                          className={'saveBtn btn btn-primary btn-block mb-3' + ((!permissions.salary_officer_user || isApproved) ? ' disabled' : '')}
                          disabled={isApproved || !permissions.salary_officer_user}
                          onClick={e => {
                            leaveReturnProcess.mutate({
                              id: event.id,
                              salary_officer_status: 'Approved'
                            });
                          }}
                        >
                          {isApproved ? 'Processed' : 'Pending'}
                        </button>
                      </div>}
                    </div>
                  </div>
                </div>
              </form>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaveReturn;
