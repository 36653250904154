import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { DataGrid, useGridApiContext, GridCellModes } from "@mui/x-data-grid";
import { useQuery, useMutation } from "react-query";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import moment from "moment";
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from "react-toastify";

import { useDebounce } from "../hooks/useDebounce";
import Sidebar from "../components/Sidebar";
import { usePaginationParams } from "../hooks/usePaginationParams";
import { makeQueryString } from "../APIs/request";
import {
  ADMIN_LOCATION_LEVEL_USER_OPTIONS, SALARY_OFFICER_USER, 
  AGENCY_HEAD_DELEGATE_USER, HR_MANAGER_USER, HR_DELEGATE_USER
} from "../constants";

import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import Config from "../config";
import API from "../APIs/API";
import { AuthenticatedLink } from "../components/AuthenticatedLink";
import { EmployeeReportLink } from "../components/EmployeeReportLink";
import { useMe } from "../hooks/useMe";

import "../styles/list.scss";
import "../styles/datatable.scss";
import "../styles/reports.scss";

function InputCell(params) {
  return <div style={{ maxWidth: '100%', whiteSpace: 'break-spaces' }}>{params.value}</div>;
}

function TextInputCell(props) {
  const { id, value, field, hasFocus } = props;
  const apiRef = useGridApiContext();
  const ref = React.useRef();

  React.useLayoutEffect(() => {
    if (hasFocus) {
      ref.current.focus();
    }
  }, [hasFocus]);

  const handleChange = (event) => {
    apiRef.current.setEditCellValue({ id, field, value: event.target.value });
  };

  return (
    <div className="form-outline" style={{ width: '100%', position: 'relative', padding: '3px 3px' }}>
      <textarea ref={ref}
        className="form-control"
        type="text"
        value={value || ''}
        rows={3}
        onChange={handleChange}
      />
    </div>
  );
}

function SelectInputCell(props) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = (event) => {
    apiRef.current.setEditCellValue({ id, field, value: event.target.value });
  };

  return (
    <div className="form-outline" style={{ width: '100%', padding: '0px 3px' }}>
      <select
        className="form-select"
        value={value || ''}
        onChange={handleChange}
      >
        <option value={""}></option>
        <option value={"Approved"}>Approved</option>
        <option value={"Declined"}>Declined</option>
      </select>
    </div>
  );
}

const ReportsPayDockingAttendance = () => {
  const { admin_location_id, usertype } = useParams();
  const { permissions, user: loggedUser } = useMe(admin_location_id);

  const [adminNoDesc, setAdminNoDesc] = useState("All");
  const [paypointDesc, setPaypointDesc] = useState("All");
  const [search, setSearch] = useState("");
  const [payNoSelect, setPayNoSelect] = useState({ label: "", id: -1 });
  const [cellModesModel, setCellModesModel] = React.useState({});

  const updatePaydocking = useMutation((body) => new API().updatePayDockingData(body), {
    onSuccess: (data) => {
      if (data) {
        console.log("Updated Successfully !");
      } else {
        toast.error("Data Update Failed !");
      }
    }
  })

  const { gridParams, queryParams } = usePaginationParams({
    initialSortModel: [{ field: "name_report", sort: "asc" }],
  });
  const searchDebounced = useDebounce(search, 1000);

  const { data: attendance, isFetching, refetch } = useQuery(
    [
      "attendance_paydocking",
      makeQueryString(queryParams, {
        admin_location_id: admin_location_id,
        search: searchDebounced,
        pay_no: payNoSelect.id,
        account_no_desc: adminNoDesc,
        paypoint_desc: paypointDesc,
      }),
    ],
    {
      queryFn: async () => {
        const queryString = makeQueryString(queryParams, {
          admin_location_id: admin_location_id,
          search: searchDebounced,
          pay_no: payNoSelect.id,
          account_no_desc: adminNoDesc,
          paypoint_desc: paypointDesc,
          user_type: usertype,
        });
        const result = await new API().getAttendancePayDocking(queryString);
        if (result != null) {
          return result;
        }
        return [];
      },
    },
  );

  const { data: payloads, isPayloadFetching } = useQuery(["payloads"], {
    queryFn: async () => {
      const result = await new API().getPayloads();
      if (result != null) {
        return result["data"];
      }
      return [];
    },
  });

  const { data: accountNoDescList = [] } = useQuery(["account_no_desc_list"], {
    queryFn: async () => {
      const result = await new API().getAccountNoDescList(admin_location_id);
      if (result) {
        if (
          loggedUser?.access_privilege === ADMIN_LOCATION_LEVEL_USER_OPTIONS.ACCOUNT_NO_DESC_USER
          || loggedUser?.access_privilege === ADMIN_LOCATION_LEVEL_USER_OPTIONS.PAYPOINT_DESC_USER
        ) {
          setAdminNoDesc(loggedUser.account_no_desc);
        }
        return ["All", ...result["data"]];
      }
      return ["All"];
    },
    enabled: loggedUser != null
  });

  const { data: paypointDescList = [] } = useQuery(["paypoint_desc_list"], {
    queryFn: async () => {
      const result = await new API().getPaypointDescList(
        admin_location_id,
        adminNoDesc,
      );
      if (result) {
        if (
          loggedUser.access_privilege === ADMIN_LOCATION_LEVEL_USER_OPTIONS.PAYPOINT_DESC_USER
        ) {
          setPaypointDesc(loggedUser.paypoint_desc);
        }
        return ["All", ...result["data"]];
      }
      return ["All"];
    },
    enabled: adminNoDesc !== "All",
  });

  const [adminLocation, setAdminLocation] = useState({
    admin_location: "",
    admin_desc: "",
  });

  useEffect(() => {
    new API().getAdminLocationById(admin_location_id).then((data) => {
      if (data != null) {
        setAdminLocation({ ...data["data"] });
      }
    });
  }, [admin_location_id]);

  const handleCellModesModelChange = React.useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const handleCellClick = React.useCallback((params, event) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (event.target.nodeType === 1 && !event.currentTarget.contains(event.target)) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {},
            ),
          }),
          {},
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {},
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const getGeneralReportURL = () => {
    let url = Config.api_url + "/v1/admin/report/print_paydocking";
    const queryParams = { pay_no: payNoSelect.id, user_type: usertype };
    if (admin_location_id !== undefined) {
      queryParams.admin_location_id = admin_location_id;
    }
    if (adminNoDesc !== "All") {
      queryParams.account_no_desc = adminNoDesc;
    }
    if (paypointDesc !== "All") {
      queryParams.paypoint_desc = paypointDesc;
    }
    const queryString = makeQueryString(queryParams);
    return url + queryString;
  };

  const getEmployeeReportURL = (row) => {
    let url = Config.api_url + "/v1/admin/report/employee/print_paydocking?pay_no=" + payNoSelect.id;
    url += `&user_type=${usertype}`;
    url += `&employee_no=${row["employee_no"]}`;
    if (admin_location_id !== undefined) {
      url += "&admin_location_id=" + admin_location_id;
    }
    return url;
  };

  if (!loggedUser) {
    return (<div style={{ padding: 20, textAlign: 'center' }}><CircularProgress color="inherit" /></div>);
  }

  const actionColumn = [
    {
      field: "action",
      headerName: "Report",
      width: 120,
      sortable: false,
      renderCell: ({ row }) => (
        <div className="cellAction">
          <EmployeeReportLink
            url={getEmployeeReportURL(row)}
            filename={`paydocking_${row.name_report}`}
            disabled={row.status && row.status != 'Processed'}
          >
            Print Report
          </EmployeeReportLink>
        </div>
      ),
    },
  ];

  const handleProcessedAction = (row) => {
    if (!row.agency_head_delegate) {
      toast.error("This record must be approved/declined before it can proceed!");
      return;
    }

    updatePaydocking.mutate({
      ...row,
      admin_location_id,
      pay_no: attendance.payload.pay_no,
      salary_officer_user_id: loggedUser.id
    }, { onSuccess: () => refetch() });
  }

  const reportsColumns = [
    {
      field: "employee_no",
      headerName: "Employee No",
      width: 180,
    },
    {
      field: "name_report",
      headerName: "Name Report",
      width: 200,
    },
    {
      field: "occup_pos_title",
      headerName: "Occup Pos Title",
      width: 300,
    },
    {
      field: "days_to_be_docked",
      headerName: "No of days to be docked",
      width: 200,
      cellClassName: ({ row }) => {
        return `days_to_be_docked`;
      }
    },
    {
      field: "status",
      headerName: "Status",
      width: 140,
      sortable: false,
      cellClassName: ({ row }) => {
        if (row && (row.status || '').toLowerCase() == 'pending') {
          return `status-pending`;
        }

        return;
      }
    },
    {
      field: "hr_delegate_remark",
      headerName: "HR Delegate's Remark",
      width: 280,
      sortable: false,
      editable: permissions.super || permissions.hr_delegate_user || permissions.hr_manager_user,
      renderEditCell: (params) => {
        return <TextInputCell {...params} />;
      },
      renderCell: (params) => {
        return <InputCell {...params} />;
      },
    },
    {
      field: "agency_head_delegate",
      headerName: "Agency Head Delegate",
      width: 200,
      sortable: false,
      editable: permissions.super || permissions.agency_head_delegate_user,
      renderEditCell: (params) => {
        return <SelectInputCell {...params} />;
      },
      renderCell: (params) => {
        return <InputCell {...params} />;
      },
    },
    {
      field: "agency_head_delegate_remark",
      headerName: "Agency Head Delegate's Remark",
      width: 280,
      sortable: false,
      editable: permissions.super || permissions.agency_head_delegate_user,
      renderEditCell: (params) => {
        return <TextInputCell {...params} />;
      },
      renderCell: (params) => {
        return <InputCell {...params} />;
      },
    },
    {
      field: "processed_by",
      headerName: "Processed by",
      width: 160,
      sortable: false,
      renderCell: ({ row }) => {
        if (row.status == 'Processed' || !(permissions.super || permissions.salary_officer_user)) {
          return (
            <div className="cellAction">
              <a className="pendingButton disabled text-decoration-none" role='button'>{row.status}</a>
            </div>
          )
        }

        return (
          <div className="cellAction">
            <a className="pendingButton text-decoration-none" role='button'
              onClick={() => handleProcessedAction(row)}>{row.status}</a>
          </div>
        )
      },
    },
  ];

  const transformedRows = attendance?.rows?.map((row) => ({ ...row, ...(!row?.occup_pos_title && { occup_pos_title: 'Unattached' }) }))

  return (
    <div className="wrapper">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="content">
        <div className="reports">
          <div className="reportsContainer">
            <div className="reportsSection">
              <div className="datatable">
                <Link
                  to={`/reports/location-level/${usertype}/${admin_location_id}`}
                  className="backButtonSection"
                >
                  <KeyboardBackspaceIcon className="backButton" />
                </Link>
                <br />
                <br />
                <div
                  className="datatableTitle"
                  style={{ marginBottom: "20px" }}
                >
                  Pay Docking for Pay No {isFetching ? `... ` : `${attendance.payload?.pay_no}-${moment(attendance.payload?.end_at).format("YYYY")}(${moment.utc(attendance.payload?.start_at).format("DD/MM/YYYY")} ~ ${moment.utc(attendance.payload?.end_at).format("DD/MM/YYYY")}) for ${(adminLocation?.admin_desc || '...')}`}
                </div>
                <div className="my-2">
                  <span>
                    {attendance
                      ? `${moment.utc(attendance.payload?.start_at).format("DD/MM/YYYY")} ~ ${moment.utc(attendance.payload?.end_at).format("DD/MM/YYYY")}`
                      : ""}
                  </span>
                </div>
                <div className="d-flex justify-content-between gap-3">
                  <div className="d-flex justify-content-start gap-3">
                    <Autocomplete
                      disablePortal
                      id="combo-box"
                      sx={{ width: 200 }}
                      disabled={isPayloadFetching}
                      renderInput={(params) => (
                        <TextField {...params} label="Pay no" />
                      )}
                      options={payloads ? payloads : []}
                      onChange={(e, value) => {
                        setPayNoSelect(value);
                      }}
                      disableClearable
                      value={
                        attendance
                          ? {
                              label: `Pay ${attendance.payload?.pay_no} - ${moment(attendance.payload?.end_at).format("YYYY")}`,
                              id: attendance?.payload?.id,
                            }
                          : payNoSelect
                      }
                    />
                    {!permissions.employee_user && <AuthenticatedLink
                      url={getGeneralReportURL()}
                      filename={`print_paydocking`}
                      disabled={!attendance?.count}
                    >
                      Print Report
                    </AuthenticatedLink>}
                  </div>
                  <div className="w-100">
                    <Autocomplete
                      disablePortal
                      id="combo-box"
                      options={accountNoDescList}
                      fullWidth
                      disabled={
                        loggedUser.access_privilege ===
                        ADMIN_LOCATION_LEVEL_USER_OPTIONS.ACCOUNT_NO_DESC_USER ||
                        loggedUser.access_privilege ===
                        ADMIN_LOCATION_LEVEL_USER_OPTIONS.PAYPOINT_DESC_USER
                      }
                      renderInput={(params) => (
                        <TextField
                          sx={{ "& input": { width: "100%" } }}
                          {...params}
                          label="Account no desc"
                        />
                      )}
                      onChange={(e, value) => setAdminNoDesc(value)}
                      disableClearable
                      value={adminNoDesc}
                    />
                  </div>
                  <div className="w-100">
                    <Autocomplete
                      hidden={adminNoDesc === "All"}
                      disablePortal
                      id="combo-box"
                      options={paypointDescList}
                      fullWidth
                      disabled={
                        loggedUser.access_privilege ===
                        ADMIN_LOCATION_LEVEL_USER_OPTIONS.PAYPOINT_DESC_USER
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Paypoint desc" />
                      )}
                      onChange={(e, value) => setPaypointDesc(value)}
                      disableClearable
                      value={paypointDesc}
                    />
                  </div>
                  <input
                    className="form-control w-50"
                    type="text"
                    name="search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search"
                  />
                </div>
                <br />
                <DataGrid
                  className="datagrid paydocking-grid"
                  rows={transformedRows || []}
                  columns={actionColumn.concat(reportsColumns)}
                  rowCount={attendance?.count ?? 0}
                  loading={isFetching}
                  getRowId={(row) => row.id}
                  // onRowEditStop={(params, event) => {
                  //   console.log('params.reason', { params, event })
                  //   // if (params.reason === GridCellEditStopReasons.cellFocusOut) {
                  //   //   event.defaultMuiPrevented = true;
                  //   // }
                  // }}
                  cellModesModel={cellModesModel}
                  onCellClick={handleCellClick}
                  onCellModesModelChange={handleCellModesModelChange}
                  processRowUpdate={(updatedRow, originalRow) => {
                    console.log({ updatedRow, originalRow });
                    const agency_head_delegate = updatedRow.agency_head_delegate || '';
                    const agency_head_delegate_remark = updatedRow.agency_head_delegate_remark || '';
                    const hr_delegate_remark = updatedRow.hr_delegate_remark || '';
                    let payload = {
                      admin_location_id,
                      employee_no: updatedRow.employee_no,
                      pay_no: attendance.payload.pay_no
                    }

                    if (agency_head_delegate != (originalRow.agency_head_delegate || '')) {
                      payload = {
                        ...payload,
                        agency_head_delegate,
                        agency_head_delegate_user_id: loggedUser.id
                      }
                    }

                    if (agency_head_delegate_remark != (originalRow.agency_head_delegate_remark || '')) {
                      payload = {
                        ...payload,
                        agency_head_delegate_remark,
                        agency_head_delegate_user_id: loggedUser.id
                      }
                    }

                    if (hr_delegate_remark != (originalRow.hr_delegate_remark || '')) {
                      payload = {
                        ...payload,
                        hr_delegate_remark,
                        hr_delegate_user_id: loggedUser.id
                      }
                    }

                    if (Object.keys(payload).length > 3) {
                      updatePaydocking.mutate({
                        ...{ ...updatedRow },
                        ...payload
                      }, { onSuccess: () => refetch() });
                    }

                    return updatedRow;
                  }}
                  onProcessRowUpdateError={(error) => {
                    console.log(error)
                  }}
                  experimentalFeatures={{ newEditingApi: true }}
                  {...gridParams}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportsPayDockingAttendance;
