import "../styles/list.scss";
import Sidebar from "../components/Sidebar";
import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {DataGrid} from "@mui/x-data-grid";
import API from "../APIs/API";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {useQuery} from "react-query";
import {usePaginationParams} from "../hooks/usePaginationParams";
import {makeQueryString} from "../APIs/request";
import moment from "moment/moment";
import {useMe} from "../hooks/useMe";

const Events = ({type, check_admin_loc_id}) => {
  const {permissions} = useMe();
  let pageUserType = type === "Non-Teacher" ? "non-teachers" : "teachers";
  let pathname = window.location.pathname;
  let admin_location_id = check_admin_loc_id ? pathname.split("/")[2] : null;
  const [eventLoc, setEventLoc] = useState();

  const {gridParams, queryParams} = usePaginationParams();

  const {data: devices = [], isFetching} = useQuery([
    "events",
    makeQueryString(queryParams, {admin_location_id, user_type: (type || '').toLowerCase()}),
  ], {
    queryFn: async () => {
      const queryString = makeQueryString(queryParams, {
        admin_location_id, user_type: (type || '').toLowerCase()
      });
      return await new API().getAllEvents(queryString);
    },
    enabled: !!admin_location_id || type != null,
  });

  useEffect(() => {
    if (admin_location_id) {
      new API().getAdminLocationById(admin_location_id).then((data) => {
        if (data != null) {
          setEventLoc(`for ${data["data"].admin_desc || ''}`);
        }
      });
    } else {
      let el = (type && type != 'All') ? `for ${type}` : '';
      setEventLoc(el);
    }
  }, [admin_location_id]);

  const deviceColumns = [
    {
      field: "date",
      headerName: "Date",
      width: 255,
      renderCell: ({row}) => {
        const date = moment(row.date);
        const to_date = moment(row.to_date);

        if (to_date && !to_date.isSame(date)) {
          return (
            <>
              <p>{date.format("dddd DD MMMM YYYY")} To {to_date.format("dddd DD MMMM YYYY")}</p>
            </>
          );
        }

        return (
          <>
            <p>{moment(row.date).format("dddd DD MMMM YYYY")}</p>
          </>
        );
      },
    },
    {
      field: "event_name",
      headerName: "Event",
      width: 300,
    },
    {
      field: "created_at",
      headerName: "Created at",
      width: 200,
      renderCell: ({row}) => {
        return (
          <>
            <p>{moment(row.created_at).format("YYYY-MM-DD HH:mm:ss")}</p>
          </>
        );
      },
    },
    {
      field: "updated_at",
      headerName: "Updated at",
      width: 200,
      renderCell: ({row}) => {
        return (
          <>
            <p>{moment(row.updated_at).format("YYYY-MM-DD HH:mm:ss")}</p>
          </>
        );
      },
    },
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 80,
      renderCell: (params) => {
        let linkTo = `/${pageUserType}/${admin_location_id}/events/view/${params.row.id}`;

        if (!admin_location_id) {
          linkTo = `view/${params.row.id}`;
        }

        return (
          <div className="cellAction">
            <Link
              to={linkTo}
              style={{textDecoration: "none"}}
            >
              <div className="viewButton">View</div>
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <div className="wrapper">
      <div className="sidebar">
        <Sidebar/>
      </div>
      <div className="content">
        <div className="list">
          <div className="listContainer">
            <div className="datatable">
              {admin_location_id != null && <>
                <Link to={`/${pageUserType}`} className="backButtonSection">
                  <KeyboardBackspaceIcon className="backButton"/>
                </Link>
                <br/>
                <br/>
              </>}

              {!permissions.employee_user && <div className="datatableTitle">
                Events {eventLoc}
                {admin_location_id != null && <Link
                  to={`/${pageUserType}/${admin_location_id}/events/add`}
                  className="link"
                >
                  <button className="addBtn">Add new</button>
                </Link>}
                {admin_location_id == null && <Link
                  to={`/events${type == 'All' ? '' : `/${pageUserType}`}/add`}
                  className="link"
                >
                  <button className="addBtn">Add new</button>
                </Link>}
              </div>}
              <DataGrid
                className="datagrid"
                rows={devices?.rows || []}
                columns={actionColumn.concat(deviceColumns)}
                rowCount={devices?.count ?? 0}
                loading={isFetching}
                {...gridParams}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
