import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { Link } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import dayjs from "dayjs";

import { UploadFile } from "../components/UploadFile";
import Config from "../config";
import { allProvinces, getDistricts, getLLG, getWards } from "../constants";

function ordinal_suffix_of(i) {
  let j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
}

const OtherDetails = ({ handleChange2, otherDetails, setOtherDetails, editable }) => {
  const [districts, setDistricts] = useState([]);
  const [LLG, setLLG] = useState([]);
  const [wards, setWards] = useState([]);

  const [current_districts, setCurrentDistricts] = useState([]);
  const [current_LLG, setCurrentLLG] = useState([]);
  const [current_wards, setCurrentWards] = useState([]);

  const [spouse_districts, setSpouseDistricts] = useState([]);
  const [spouse_LLG, setSpouseLLG] = useState([]);
  const [spouse_wards, setSpouseWards] = useState([]);

  useEffect(() => {
    if (otherDetails) {
      setDistricts(getDistricts(otherDetails.province));
      setLLG(getLLG(otherDetails.province, otherDetails.district));
      setWards(getWards(otherDetails.province, otherDetails.district, otherDetails.llg));

      setCurrentDistricts(getDistricts(otherDetails.current_province));
      setCurrentLLG(getLLG(otherDetails.current_province, otherDetails.current_district));
      setCurrentWards(getWards(otherDetails.current_province, otherDetails.current_district, otherDetails.current_llg));

      setSpouseDistricts(getDistricts(otherDetails.spouse_province));
      setSpouseLLG(getLLG(otherDetails.spouse_province, otherDetails.spouse_district));
      setSpouseWards(getWards(otherDetails.spouse_province, otherDetails.spouse_district, otherDetails.spouse_llg));
    }
  }, [otherDetails])

  const addBeneficiaries = () => {
    setOtherDetails({ ...otherDetails, num_of_beneficiaries: (otherDetails.num_of_beneficiaries || 0) + 1 });
  }

  const stringToDayjs = val => {
    if (!val) return val;

    if (typeof val == 'string') {
      return dayjs(val);
    }

    return val;
  }

  return (
    <div>
      {otherDetails != null && <>
        <div className="row mt-3">
          <div className="col col-sm header2">Personal details</div>
        </div>
        <hr />
        <div className="row">
          <div className="col col-sm">
            <div className="form-outline">
              <label className="form-label" htmlFor="place_of_birth">
                <b>Place of Birth</b>
              </label>
              <br />
              <input
                className="form-control"
                type="text"
                name="place_of_birth"
                id="place_of_birth"
                value={otherDetails.place_of_birth}
                onChange={(e) => handleChange2(e)}
                disabled={!editable}
              />
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="province">
                <b>Province</b>
              </label>
              <br />
              <select
                className="form-control"
                name="province"
                id="province"
                value={otherDetails.province}
                disabled={!editable}
                onChange={(e) => {
                  setDistricts(getDistricts(e.target.value));
                  handleChange2(e);
                }}
              >
                <option key={'none'} value={''}>{''}</option>
                {allProvinces.map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="district">
                <b>District</b>
              </label>
              <br />
              <select
                className="form-control"
                name="district"
                id="district"
                value={otherDetails.district}
                disabled={!editable}
                onChange={(e) => {
                  setLLG(getLLG(otherDetails.province, e.target.value));
                  handleChange2(e);
                }}
              >
                <option key={'none'} value={''}>{''}</option>
                {districts.map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="llg">
                <b>LLG</b>
              </label>
              <br />
              <select
                className="form-control"
                name="llg"
                id="llg"
                value={otherDetails.llg}
                disabled={!editable}
                onChange={(e) => {
                  setWards(getWards(otherDetails.province, otherDetails.district, e.target.value));
                  handleChange2(e);
                }}
              >
                <option key={'none'} value={''}>{''}</option>
                {LLG.map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="ward">
                <b>Ward</b>
              </label>
              <br />
              <select
                className="form-control"
                name="ward"
                id="ward"
                value={otherDetails.ward}
                disabled={!editable}
                onChange={(e) => handleChange2(e)}
              >
                <option key={'none'} value={''}>{''}</option>
                {wards.map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="village">
                <b>Village/Town</b>
              </label>
              <br />
              <input
                className="form-control"
                type="text"
                name="village"
                id="village"
                disabled={!editable}
                value={otherDetails.village}
                onChange={(e) => handleChange2(e)}
              />
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="tribe">
                <b>Tribe</b>
              </label>
              <br />
              <input
                className="form-control"
                type="text"
                name="tribe"
                id="tribe"
                disabled={!editable}
                value={otherDetails.tribe}
                onChange={(e) => handleChange2(e)}
              />
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="clan">
                <b>Clan</b>
              </label>
              <br />
              <input
                className="form-control"
                type="text"
                name="clan"
                id="clan"
                disabled={!editable}
                value={otherDetails.clan}
                onChange={(e) => handleChange2(e)}
              />
            </div>
          </div>
          <div className="col col-sm">
            <div className="form-outline">
              <label className="form-label" htmlFor="type_of_birth">
                <b>Type of Birth</b>
              </label>
              <br />
              <select
                className="form-control"
                name="type_of_birth"
                id="type_of_birth"
                disabled={!editable}
                value={otherDetails.type_of_birth}
                onChange={(e) => handleChange2(e)}
              >
                <option key={'none'} value={''}>{''}</option>
                <option key={'Single'} value={'Single'}>{'Single'}</option>
                <option key={'Twins'} value={'Twins'}>{'Twins'}</option>
                <option key={'Triplets'} value={'Triplets'}>{'Triplets'}</option>
                <option key={'Quadruplets'} value={'Quadruplets'}>{'Quadruplets'}</option>
              </select>
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="order_of_chlid">
                <b>Order of Child</b>
              </label>
              <br />
              <select
                className="form-control"
                name="order_of_chlid"
                id="order_of_chlid"
                disabled={!editable}
                value={otherDetails.order_of_chlid}
                onChange={(e) => handleChange2(e)}
              >
                <option key={'none'} value={''}>{''}</option>
                {Array.from({ length: 20 }, (v, k) => k + 1).map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="marital_status">
                <b>Marital status</b>
              </label>
              <br />
              <select
                className="form-control"
                name="marital_status"
                id="marital_status"
                disabled={!editable}
                value={otherDetails.marital_status}
                onChange={(e) => handleChange2(e)}
              >
                <option key={'none'} value={''}>{''}</option>
                <option key={'Never Married'} value={'Never Married'}>{'Never Married'}</option>
                <option key={'Married'} value={'Married'}>{'Married'}</option>
                <option key={'Separated'} value={'Separated'}>{'Separated'}</option>
                <option key={'Divorced'} value={'Divorced'}>{'Divorced'}</option>
                <option key={'Widow'} value={'Widow'}>{'Widow'}</option>
                <option key={'Widower'} value={'Widower'}>{'Widower'}</option>
              </select>
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="degree">
                <b>Degree</b>
              </label>
              <br />
              <input
                className="form-control"
                type="text"
                name="degree"
                id="degree"
                disabled={!editable}
                placeholder="i.e. Bachelor in Computer Science"
                value={otherDetails.degree}
                onChange={(e) => handleChange2(e)}
              />
            </div>
            <div className="row">
              <div className="col col-sm">
                <div className="form-outline">
                  <label className="form-label">
                    <b>From date</b>
                  </label>
                  <br />
                  <DatePicker className="date-input"
                    value={stringToDayjs(otherDetails.degree_from_date)}
                    views={['year']} disabled={!editable}
                    onAccept={value => {
                      setOtherDetails({ ...otherDetails, degree_from_date: value });
                    }}
                  />
                </div>
              </div>
              <div className="col col-sm">
                <div className="form-outline">
                  <label className="form-label">
                    <b>To date</b>
                  </label>
                  <br />
                  <DatePicker className="date-input"
                    value={stringToDayjs(otherDetails.degree_to_date)}
                    views={['year']} disabled={!editable}
                    onAccept={value => {
                      setOtherDetails({ ...otherDetails, degree_to_date: value });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="form-outline">
              <label className="form-label">
                <b>Upload your Degree Certificate</b>
                {otherDetails.degree_file != null && <Link style={{ paddingLeft: '7px' }}
                  to={`${Config.api_url}/v1/admin/employee/file/${otherDetails.degree_file}`} target="_blank">
                  View uploaded file
                </Link>}
              </label>
              <br />
              <div>
                <UploadFile
                  invalidateQueries="employees"
                  url={`${Config.api_url}/v1/admin/employee/file`}
                  name="file" disabled={!editable}
                  callback={src => setOtherDetails({ ...otherDetails, degree_file: src })}
                />
              </div>
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="denomination">
                <b>Denomination</b>
              </label>
              <br />
              <input
                className="form-control"
                type="text"
                name="denomination"
                id="denomination"
                disabled={!editable}
                value={otherDetails.denomination}
                onChange={(e) => handleChange2(e)}
              />
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="nid_number">
                <b>NID Number</b>
              </label>
              <br />
              <input
                className="form-control"
                type="text"
                name="nid_number"
                id="nid_number"
                disabled={!editable}
                value={otherDetails.nid_number}
                onChange={(e) => handleChange2(e)}
              />
            </div>
            <div className="form-outline">
              <label className="form-label">
                <b>Upload your NID Card</b>
                {otherDetails.nid_number_file != null && <Link style={{ paddingLeft: '7px' }}
                  to={`${Config.api_url}/v1/admin/employee/file/${otherDetails.nid_number_file}`} target="_blank">
                  View uploaded file
                </Link>}
              </label>
              <br />
              <div>
                <UploadFile
                  invalidateQueries="employees"
                  url={`${Config.api_url}/v1/admin/employee/file`}
                  name="file" disabled={!editable}
                  callback={src => setOtherDetails({ ...otherDetails, nid_number_file: src })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col col-sm header2">Current Residential Address</div>
        </div>
        <hr />
        <div className="row">
          <div className="col col-sm">
            <div className="form-outline">
              <label className="form-label" htmlFor="current_province">
                <b>Province</b>
              </label>
              <br />
              <select
                className="form-control"
                name="current_province"
                id="current_province"
                value={otherDetails.current_province}
                disabled={!editable}
                onChange={(e) => {
                  setCurrentDistricts(getDistricts(e.target.value));
                  handleChange2(e);
                }}
              >
                <option key={'none'} value={''}>{''}</option>
                {allProvinces.map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="current_district">
                <b>District</b>
              </label>
              <br />
              <select
                className="form-control"
                name="current_district"
                id="current_district"
                value={otherDetails.current_district}
                disabled={!editable}
                onChange={(e) => {
                  setCurrentLLG(getLLG(otherDetails.current_province, e.target.value));
                  handleChange2(e);
                }}
              >
                <option key={'none'} value={''}>{''}</option>
                {current_districts.map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="col col-sm">
            <div className="form-outline">
              <label className="form-label" htmlFor="current_llg">
                <b>LLG</b>
              </label>
              <br />
              <select
                className="form-control"
                name="current_llg"
                id="current_llg"
                value={otherDetails.current_llg}
                disabled={!editable}
                onChange={(e) => {
                  setCurrentWards(getWards(otherDetails.current_province, otherDetails.current_district, e.target.value));
                  handleChange2(e);
                }}
              >
                <option key={'none'} value={''}>{''}</option>
                {current_LLG.map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="current_ward">
                <b>Ward</b>
              </label>
              <br />
              <select
                className="form-control"
                name="current_ward"
                id="current_ward"
                disabled={!editable}
                value={otherDetails.current_ward}
                onChange={(e) => handleChange2(e)}
              >
                <option key={'none'} value={''}>{''}</option>
                {current_wards.map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="current_village">
                <b>Village/Town</b>
              </label>
              <br />
              <input
                className="form-control"
                type="text"
                name="current_village"
                id="current_village"
                disabled={!editable}
                value={otherDetails.current_village}
                onChange={(e) => handleChange2(e)}
              />
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col col-sm header2">Spouse details</div>
        </div>
        <hr />
        <div className="row">
          <div className="col col-sm">
            <div className="form-outline">
              <label className="form-label" htmlFor="spouse_given_name">
                <b>Given name</b>
              </label>
              <br />
              <input
                className="form-control"
                type="text"
                name="spouse_given_name"
                id="spouse_given_name"
                disabled={!editable}
                value={otherDetails.spouse_given_name}
                onChange={(e) => handleChange2(e)}
              />
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="spouse_surname">
                <b>Surname</b>
              </label>
              <br />
              <input
                className="form-control"
                type="text"
                name="spouse_surname"
                id="spouse_surname"
                disabled={!editable}
                value={otherDetails.spouse_surname}
                onChange={(e) => handleChange2(e)}
              />
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="spouse_gender">
                <b>Gender</b>
              </label>
              <br />
              <select
                className="form-control"
                name="spouse_gender"
                id="spouse_gender"
                disabled={!editable}
                value={otherDetails.spouse_gender}
                onChange={(e) => handleChange2(e)}
              >
                <option key={'none'} value={''}>{''}</option>
                <option key={'Male'} value={'Male'}>{'Male'}</option>
                <option key={'Female'} value={'Female'}>{'Female'}</option>
              </select>
            </div>
            <div className="form-outline">
              <label className="form-label">
                <b>Date of birth</b>
              </label>
              <br />
              <DatePicker className="date-input"
                value={stringToDayjs(otherDetails.spouse_date_of_birth)}
                disabled={!editable}
                onAccept={value => {
                  setOtherDetails({ ...otherDetails, spouse_date_of_birth: value });
                }}
              />
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="spouse_place_of_borth">
                <b>Place of birth</b>
              </label>
              <br />
              <input
                className="form-control"
                type="text"
                name="spouse_place_of_borth"
                id="spouse_place_of_borth"
                disabled={!editable}
                value={otherDetails.spouse_place_of_borth}
                onChange={(e) => handleChange2(e)}
              />
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="spouse_occupation">
                <b>Occupation</b>
              </label>
              <br />
              <input
                className="form-control"
                type="text"
                name="spouse_occupation"
                id="spouse_occupation"
                disabled={!editable}
                value={otherDetails.spouse_occupation}
                onChange={(e) => handleChange2(e)}
              />
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="spouse_denomination">
                <b>Denomination</b>
              </label>
              <br />
              <input
                className="form-control"
                type="text"
                name="spouse_denomination"
                id="spouse_denomination"
                disabled={!editable}
                value={otherDetails.spouse_denomination}
                onChange={(e) => handleChange2(e)}
              />
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="spouse_nid_number">
                <b>NID Number</b>
              </label>
              <br />
              <input
                className="form-control"
                type="text"
                name="spouse_nid_number"
                id="spouse_nid_number"
                disabled={!editable}
                value={otherDetails.spouse_nid_number}
                onChange={(e) => handleChange2(e)}
              />
            </div>
            <div className="form-outline">
              <label className="form-label">
                <b>Upload your NID Card</b>
                {otherDetails.spouse_nid_number_file != null && <Link style={{ paddingLeft: '7px' }}
                  to={`${Config.api_url}/v1/admin/employee/file/${otherDetails.spouse_nid_number_file}`} target="_blank">
                  View uploaded file
                </Link>}
              </label>
              <br />
              <div>
                <UploadFile
                  invalidateQueries="employees"
                  url={`${Config.api_url}/v1/admin/employee/file`}
                  name="file" disabled={!editable}
                  callback={src => setOtherDetails({ ...otherDetails, spouse_nid_number_file: src })}
                />
              </div>
            </div>
          </div>
          <div className="col col-sm">
            <div className="form-outline">
              <label className="form-label" htmlFor="spouse_province">
                <b>Province</b>
              </label>
              <br />
              <select
                className="form-control"
                name="spouse_province"
                id="spouse_province"
                value={otherDetails.spouse_province}
                disabled={!editable}
                onChange={(e) => {
                  setSpouseDistricts(getDistricts(e.target.value));
                  handleChange2(e);
                }}
              >
                <option key={'none'} value={''}>{''}</option>
                {allProvinces.map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="spouse_district">
                <b>District</b>
              </label>
              <br />
              <select
                className="form-control"
                name="spouse_district"
                id="spouse_district"
                value={otherDetails.spouse_district}
                disabled={!editable}
                onChange={(e) => {
                  setSpouseLLG(getLLG(otherDetails.spouse_province, e.target.value));
                  handleChange2(e);
                }}
              >
                <option key={'none'} value={''}>{''}</option>
                {spouse_districts.map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="spouse_llg">
                <b>LLG</b>
              </label>
              <br />
              <select
                className="form-control"
                name="spouse_llg"
                id="spouse_llg"
                disabled={!editable}
                value={otherDetails.spouse_llg}
                onChange={(e) => {
                  setSpouseWards(getWards(otherDetails.spouse_province, otherDetails.spouse_district, e.target.value));
                  handleChange2(e);
                }}
              >
                <option key={'none'} value={''}>{''}</option>
                {spouse_LLG.map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="spouse_ward">
                <b>Ward</b>
              </label>
              <br />
              <select
                className="form-control"
                name="spouse_ward"
                id="spouse_ward"
                disabled={!editable}
                value={otherDetails.spouse_ward}
                onChange={(e) => handleChange2(e)}
              >
                <option key={'none'} value={''}>{''}</option>
                {spouse_wards.map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="spouse_village">
                <b>Village/Town</b>
              </label>
              <br />
              <input
                className="form-control"
                type="text"
                name="spouse_village"
                id="spouse_village"
                disabled={!editable}
                value={otherDetails.spouse_village}
                onChange={(e) => handleChange2(e)}
              />
            </div>
            <div className="form-outline">
              <label className="form-label">
                <b>Date of Marriage</b>
              </label>
              <br />
              <DatePicker className="date-input"
                value={stringToDayjs(otherDetails.spouse_date_of_marriage)}
                disabled={!editable}
                onAccept={value => {
                  setOtherDetails({ ...otherDetails, spouse_date_of_marriage: value });
                }}
              />
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="spouse_type_of_marriage">
                <b>Type of Marriage</b>
              </label>
              <br />
              <select
                className="form-control"
                name="spouse_type_of_marriage"
                id="spouse_type_of_marriage"
                disabled={!editable}
                value={otherDetails.spouse_type_of_marriage}
                onChange={(e) => handleChange2(e)}
              >
                <option key={'none'} value={''}>{''}</option>
                <option key={'Civil'} value={'Civil'}>{'Civil'}</option>
                <option key={'Customary'} value={'Customary'}>{'Customary'}</option>
              </select>
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="spouse_marriage_registration_no">
                <b>Marriage Registration No</b>
              </label>
              <br />
              <input
                className="form-control"
                type="text"
                name="spouse_marriage_registration_no"
                id="spouse_marriage_registration_no"
                disabled={!editable}
                value={otherDetails.spouse_marriage_registration_no}
                onChange={(e) => handleChange2(e)}
              />
            </div>
            <div className="row">
              <div className="col col-sm">
                <div className="form-outline">
                  <label className="form-label">
                    <b>Upload face photo</b>
                    {otherDetails.spouse_face_photo_file != null && <Link style={{ paddingLeft: '7px' }}
                      to={`${Config.api_url}/v1/admin/employee/file/${otherDetails.spouse_face_photo_file}`} target="_blank">
                      View uploaded file
                    </Link>}
                  </label>
                  <br />
                  <div>
                    <UploadFile
                      invalidateQueries="employees"
                      url={`${Config.api_url}/v1/admin/employee/file`}
                      name="file" disabled={!editable}
                      callback={src => setOtherDetails({ ...otherDetails, spouse_face_photo_file: src })}
                    />
                  </div>
                </div>
              </div>
              <div className="col col-sm">
                <div className="form-outline">
                  <label className="form-label">
                    <b>Upload birth certificate</b>
                    {otherDetails.spouse_birth_certificate_file != null && <Link style={{ paddingLeft: '7px' }}
                      to={`${Config.api_url}/v1/admin/employee/file/${otherDetails.spouse_birth_certificate_file}`} target="_blank">
                      View uploaded file
                    </Link>}
                  </label>
                  <br />
                  <div>
                    <UploadFile
                      invalidateQueries="employees"
                      url={`${Config.api_url}/v1/admin/employee/file`}
                      name="file" disabled={!editable}
                      callback={src => setOtherDetails({ ...otherDetails, spouse_birth_certificate_file: src })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {(otherDetails.num_of_beneficiaries || 1) > 0 && <>
          <div className="mt-5 num_of_beneficiaries">
            <div className="header2">Beneficiary details</div>
            {editable && <div className="num_of_beneficiaries_icon" onClick={addBeneficiaries}><AddCircleOutlineOutlinedIcon /></div>}
          </div>
          <hr />
          {Array.from({ length: (otherDetails.num_of_beneficiaries || 0) }, (v, k) => k + 1).map((k) => (
            <div key={k}>
              <div style={{ paddingBottom: 3, position: 'relative', display: 'flex', gap: '10px' }}>
                <div>{ordinal_suffix_of(k)} beneficiary:</div>
                {editable && <div className="remove-btn" onClick={e => {
                  const keys = Object.keys(otherDetails || {}).filter(x => x.startsWith(k + "_" + "beneficiary_"));
                  console.log(otherDetails)
                  console.log(k, keys)
                  keys.forEach(key => {
                    delete otherDetails[key];
                  })
                  for (let idxx = k + 1; idxx <= (otherDetails.num_of_beneficiaries || 1); idxx++) {
                    const len = String(idxx).length;
                    const keysidxx = Object.keys(otherDetails || {}).filter(x => x.startsWith(idxx + "_" + "beneficiary_"));
                    console.log(keysidxx)
                    keysidxx.forEach(key => {
                      const p = key.substring(len);
                      console.log(p);
                      otherDetails[k + p] = otherDetails[key];
                      if (idxx == (otherDetails.num_of_beneficiaries || 1)) {
                        delete otherDetails[key];
                      }
                    })
                  }
                  otherDetails.num_of_beneficiaries = (otherDetails.num_of_beneficiaries || 0) - 1;
                  console.log(otherDetails);
                  setOtherDetails({ ...otherDetails });
                }}><RemoveCircleOutlineIcon /></div>}
              </div>
              <div className="row">
                <div className="col col-sm">
                  <div className="form-outline">
                    <label className="form-label" htmlFor={k + "_" + "beneficiary_given_name"}>
                      <b>Given name</b>
                    </label>
                    <br />
                    <input
                      className="form-control"
                      type="text"
                      name={k + "_" + "beneficiary_given_name"}
                      id={k + "_" + "beneficiary_given_name"}
                      value={otherDetails[k + "_" + "beneficiary_given_name"]}
                      disabled={!editable}
                      onChange={(e) => handleChange2(e)}
                    />
                  </div>
                  <div className="form-outline">
                    <label className="form-label" htmlFor={k + "_" + "beneficiary_surname"}>
                      <b>Surname</b>
                    </label>
                    <br />
                    <input
                      className="form-control"
                      type="text"
                      name={k + "_" + "beneficiary_surname"}
                      id={k + "_" + "beneficiary_surname"}
                      disabled={!editable}
                      value={otherDetails[k + "_" + "beneficiary_surname"]}
                      onChange={(e) => handleChange2(e)}
                    />
                  </div>
                </div>
                <div className="col col-sm">
                  <div className="form-outline">
                    <label className="form-label">
                      <b>Date of birth</b>
                    </label>
                    <br />
                    <DatePicker className="date-input"
                      value={stringToDayjs(otherDetails[k + "_" + "beneficiary_date_of_birth"])}
                      disabled={!editable}
                      onAccept={value => {
                        setOtherDetails({ ...otherDetails, [k + "_" + "beneficiary_date_of_birth"]: value });
                      }}
                    />
                  </div>
                  <div className="row">
                    <div className="col col-sm">
                      <div className="form-outline">
                        <label className="form-label">
                          <b>Upload face photo</b>
                          {otherDetails[k + "_" + "beneficiary_face_photo_file"] != null && <Link style={{ paddingLeft: '7px' }}
                            to={`${Config.api_url}/v1/admin/employee/file/${otherDetails[k + "_" + "beneficiary_face_photo_file"]}`} target="_blank">
                            View uploaded file
                          </Link>}
                        </label>
                        <br />
                        <div>
                          <UploadFile
                            invalidateQueries="employees"
                            url={`${Config.api_url}/v1/admin/employee/file`}
                            name="file" disabled={!editable}
                            callback={src => setOtherDetails({ ...otherDetails, [k + "_" + "beneficiary_face_photo_file"]: src })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col col-sm">
                      <div className="form-outline">
                        <label className="form-label">
                          <b>Upload birth certificate</b>
                          {otherDetails[k + "_" + "beneficiary_birth_cert_file"] != null && <Link style={{ paddingLeft: '7px' }}
                            to={`${Config.api_url}/v1/admin/employee/file/${otherDetails[k + "_" + "beneficiary_birth_cert_file"]}`} target="_blank">
                            View uploaded file
                          </Link>}
                        </label>
                        <br />
                        <div>
                          <UploadFile
                            invalidateQueries="employees"
                            url={`${Config.api_url}/v1/admin/employee/file`}
                            name="file" disabled={!editable}
                            callback={src => setOtherDetails({ ...otherDetails, [k + "_" + "beneficiary_birth_cert_file"]: src })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {k < (otherDetails.num_of_beneficiaries || 1) && <hr />}
            </div>
          ))}
        </>}
      </>}
    </div>
  );
};

export default OtherDetails;
