import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useQuery } from "react-query";
import moment from "moment/moment";
import Chip from '@mui/material/Chip';

import { usePaginationParams } from "../hooks/usePaginationParams";
import { makeQueryString } from "../APIs/request";
import { useMe } from "../hooks/useMe";
import Sidebar from "../components/Sidebar";
import API from "../APIs/API";

import "../styles/list.scss";

const Hr = ({ type, check_admin_loc_id }) => {
  const { permissions } = useMe();
  let pageUserType = type === "Non-Teacher" ? "non-teachers" : "teachers";
  let pathname = window.location.pathname;
  let admin_location_id = check_admin_loc_id ? pathname.split("/")[2] : null;
  const [eventLoc, setEventLoc] = useState();
  const [hrList, setHrList] = useState([]);

  const { gridParams, queryParams } = usePaginationParams();

  const { data: hrs = [], isFetching } = useQuery([
    "hr",
    makeQueryString(queryParams, { admin_location_id, user_type: (type || '').toLowerCase() }),
  ], {
    queryFn: async () => {
      const queryString = makeQueryString(queryParams, {
        admin_location_id, user_type: (type || '').toLowerCase()
      });
      return await new API().getAllHrs(queryString);
    },
    enabled: !!admin_location_id || type != null,
  });

  useEffect(() => {
    if (admin_location_id) {
      new API().getAdminLocationById(admin_location_id).then((data) => {
        if (data != null) {
          setEventLoc(`for ${data["data"].admin_desc || ''}`);
        }
      });
    } else {
      let el = (type && type != 'All') ? `for ${type}` : '';
      setEventLoc(el);
    }
  }, [admin_location_id]);

  const getRow = ({ id, hr_type }, rows) => {
    const row = (rows || []).find(r => r.hr_type == hr_type);

    return {
      hr_type,
      ...(row || {}),
      id
    }
  }

  useEffect(() => {
    if (hrs) {
      const rows = hrs.rows || [];
      console.log(hrs);
      setHrList([
        getRow({
          id: 1,
          hr_type: 'HR Manager'
        }, rows),
        getRow({
          id: 2,
          hr_type: 'HR Delegate'
        }, rows),
        getRow({
          id: 3,
          hr_type: 'Salary Officer'
        }, rows),
        getRow({
          id: 4,
          hr_type: 'Agency Head'
        }, rows)
      ])
    }
  }, [hrs]);

  const deviceColumns = [
    {
      field: "hr_type",
      headerName: "Hr Type",
      width: 200,
    },
    {
      field: "hr_head_users",
      headerName: "Hr Users",
      width: 330,
      renderCell: ({ row }) => {
        if (!row.hr_head_users || row.hr_head_users.length == 0) {
          return 'No Users';
        }

        return (
          <div style={{ columnGap: '7px', rowGap: '3px', display: 'flex', flexWrap: 'wrap', padding: '2px 0px' }}>
            {row.hr_head_users.map(a => {
              return <Chip key={a.email} label={a.email} size="small" />
            })}
          </div>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created at",
      width: 200,
      renderCell: ({ row }) => {
        return (
          <>
            <p>{row.created_at ? moment(row.created_at).format("YYYY-MM-DD HH:mm:ss") : ''}</p>
          </>
        );
      },
    },
    {
      field: "updated_at",
      headerName: "Updated at",
      width: 200,
      renderCell: ({ row }) => {
        return (
          <>
            <p>{row.updated_at ? moment(row.updated_at).format("YYYY-MM-DD HH:mm:ss") : ''}</p>
          </>
        );
      },
    },
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 80,
      renderCell: (params) => {
        let linkTo = `/${pageUserType}/${admin_location_id}/hr/view/${params.row.id}`;

        if (!admin_location_id) {
          linkTo = `view/${params.row.id}`;
        }

        return (
          <div className="cellAction">
            <Link
              to={linkTo}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <div className="wrapper">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="content">
        <div className="list">
          <div className="listContainer">
            <div className="datatable">
              {admin_location_id != null && <>
                <Link to={`/${pageUserType}`} className="backButtonSection">
                  <KeyboardBackspaceIcon className="backButton" />
                </Link>
                <br />
                <br />
              </>}

              {!permissions.employee_user && <div className="datatableTitle">
                Human Resource {eventLoc}
                {/* {admin_location_id != null && <Link
                  to={`/${pageUserType}/${admin_location_id}/hr/add`}
                  className="link"
                >
                  <button className="addBtn">Add new</button>
                </Link>}
                {admin_location_id == null && <Link
                  to={`/hr${type == 'All' ? '' : `/${pageUserType}`}/add`}
                  className="link"
                >
                  <button className="addBtn">Add new</button>
                </Link>} */}
              </div>}
              <DataGrid
                className="divisions-grid"
                rows={hrList}
                columns={actionColumn.concat(deviceColumns)}
                rowCount={4}
                loading={isFetching}
                {...gridParams}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hr;
