import { useMemo } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import API from "../APIs/API";
import {
  BUREAUCRAT_USER, NON_TEACHER, NON_TEACHER_USER, SUPER_USER,
  TEACHER, TEACHER_USER, EMPLOYEE_USER, SALARY_OFFICER_USER,
  AGENCY_HEAD_DELEGATE_USER, HR_MANAGER_USER, HR_DELEGATE_USER,
  DIVISION_HEAD_USER, BRANCH_HEAD_USER
} from "../constants";

export function normalizeReportName(name_report) {
  const name = (name_report || '').split(', ');
  name.reverse();
  return name.join(' ');
}

/**
 * Return should contain:
 *
 * userAccessPrivilege
 * userAdminLocationLevel
 *
 */
export function useMe(admin_location_id) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const logout = useMutation(() => new API().logout(), {
    onSuccess: () => {
      localStorage.clear();
      navigate("/login");
    },
  });

  const { data: user, isError, isLoading } = useQuery({
    queryKey: "/users/me",
    queryFn: async () => {
      const result = await new API().getMe();
      if (result == null) {
        queryClient.invalidateQueries("/users/me");
        logout.mutate();
        return null;
      }

      const data = result["data"];

      return {
        "id": data["id"],
        "email": data["email"],
        "access_privilege2": data["access_privilege"] ?? "",
        "access_privilege": data["access_privilege"] ?? "",
        "admin_location_level_user": data["admin_location_level_user"] ?? "",
        "creator": data["creator"] ?? "",
        "surname": data["surname"] ?? "",
        "given_name": data["given_name"] ?? "",
        "date_of_birth": data["date_of_birth"],
        "job_title": data["job_title"] ?? "",
        "office_phone": data["office_phone"] ?? "",
        "mobile_number": data["mobile_number"] ?? "",
        "admin_desc": data["admin_desc"] ?? "",
        "account_no_desc": data["account_no_desc"] ?? "",
        "paypoint_desc": data["paypoint_desc"] ?? "",
        "created_at": data["created_at"] ?? "",
        "updated_at": data["updated_at"] ?? "",
        "admin_location": data["admin_location"] ? {
          "id": data["admin_location"]["admin_location_id"],
          "admin_location": data["admin_location"]["admin_location"],
          "admin_desc": data["admin_location"]["admin_desc"],
          "user_type": data["admin_location"]["user_type"],
        } : null,
        "is_employee": data["is_employee"],
        "employee_no": data["employee_no"],
        "avatar_src": data["avatar_src"],
        "other_details": data["other_details"],
        "divisions": data["divisions"],
        "branchs": data["branchs"],
        "hrs": data["hrs"],
        "gender": data["gender"],
      };
    },
    cacheTime: 1000 * 60 * 60, // 1 hour
    staleTime: 1000 * 60 * 60, // 1 hour
  })

  const permissions = useMemo(
    () => {
      if (!user) {
        return {
          super: false,
          superRead: false,
          teacher: false,
          non_teacher: false,
          topLevel: false,
          normal_user: false,
          report_teacher: false,
          report_non_teacher: false,
          paydocking_user: false,
          branch_head_user: false,
          division_head_user: false,
          agency_head_delegate_user: false,
        }
      }

      const salary_officer_user = (() => {
        const hrs = (user.hrs || []).filter(h => h.hr_type == 'Salary Officer' && h.admin_location_id == admin_location_id);
        return hrs.length > 0 || user.access_privilege == SALARY_OFFICER_USER;
      })(),
        agency_head_delegate_user = (() => {
          const hrs = (user.hrs || []).filter(h => h.hr_type == 'Agency Head' && h.admin_location_id == admin_location_id);
          return hrs.length > 0 || user.access_privilege == AGENCY_HEAD_DELEGATE_USER;
        })(),
        hr_manager_user = (() => {
          const hrs = (user.hrs || []).filter(h => h.hr_type == 'HR Manager' && h.admin_location_id == admin_location_id);
          return hrs.length > 0 || user.access_privilege == HR_MANAGER_USER;
        })(),
        hr_delegate_user = (() => {
          const hrs = (user.hrs || []).filter(h => h.hr_type == 'HR Delegate' && h.admin_location_id == admin_location_id);
          return hrs.length > 0 || user.access_privilege == HR_DELEGATE_USER;
        })()

      return {
        super: user.access_privilege === SUPER_USER,
        superRead: [SUPER_USER, BUREAUCRAT_USER].includes(user.access_privilege),
        teacher:
          [SUPER_USER, TEACHER_USER, BUREAUCRAT_USER].includes(
            user.access_privilege
          ) || user.admin_location?.user_type === TEACHER,
        non_teacher:
          [SUPER_USER, NON_TEACHER_USER, BUREAUCRAT_USER].includes(
            user.access_privilege
          ) || user.admin_location?.user_type === NON_TEACHER,
        topLevel: !!user.access_privilege,
        normal_user: user.access_privilege === "" && user.admin_location != null,
        report_teacher: [TEACHER_USER, SUPER_USER, BUREAUCRAT_USER].includes(user.access_privilege),
        report_non_teacher: [NON_TEACHER_USER, SUPER_USER, BUREAUCRAT_USER].includes(user.access_privilege),
        employee_user: (() => {
          const divisions = (user.divisions || []).filter(h => h.admin_location_id == admin_location_id);
          const hrs = (user.hrs || []).filter(h => h.admin_location_id == admin_location_id);
          const branchs = (user.branchs || []).filter(h => h.admin_location_id == admin_location_id);
          return !(divisions.length > 0 || hrs.length > 0 || branchs.length > 0 || user.access_privilege2 != EMPLOYEE_USER);
        })(),
        branch_head_user: (() => {
          const branchs = (user.branchs || []).filter(h => h.admin_location_id == admin_location_id);
          return branchs.length > 0 || user.access_privilege == BRANCH_HEAD_USER;
        })(),
        division_head_user: user.access_privilege == DIVISION_HEAD_USER,
        salary_officer_user,
        agency_head_delegate_user,
        hr_manager_user,
        hr_delegate_user,
        // branch_head_user: user.access_privilege == BRANCH_HEAD_USER,
        // division_head_user: user.access_privilege == DIVISION_HEAD_USER,
        // salary_officer_user: user.access_privilege == SALARY_OFFICER_USER,
        // agency_head_delegate_user: user.access_privilege == AGENCY_HEAD_DELEGATE_USER,
        paydocking_user: salary_officer_user || agency_head_delegate_user
          || hr_manager_user || hr_delegate_user,
      }
    },
    [user]
  );

  return {
    permissions,
    user,
    isError,
    isLoading,
  };
}
