import { getAccessToken } from "../utils/helper";

export function EmployeeReportLink({ url, filename, disabled, children, className }) {
  const handleEmployAction = async () => {
    const result = await fetch(url, {
      headers: {
        Authorization: getAccessToken(),
      }
    })

    const blob = await result.blob();
    let link = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = link;
    a.download = `${filename}.pdf`;
    a.click();
  }

  if (disabled) {
    return (
      <>
        <a className={"viewButton disabled text-decoration-none" + ` ${className || ''}`} role='button'>{children}</a>
      </>
    )
  }

  return (
    <>
      <a className={"viewButton text-decoration-none" + ` ${className || ''}`} role='button'
        onClick={handleEmployAction}>{children}</a>
    </>
  )
}