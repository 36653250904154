import React, { useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UploadFile } from "../components/UploadFile";
import { useMutation } from "react-query";
import { Stack, Switch } from "@mui/material";
import { toast } from "react-toastify";
import CircularProgress from '@mui/material/CircularProgress';
import { PieChart } from "@mui/x-charts/PieChart";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { useDeleteEmployee } from "../hooks/useDeleteEmployee";
import { useArchiveEmployee } from "../hooks/useArchiveEmployee";
import API from "../APIs/API";
import EmployeeTransfer from "./EmployeeTransferModal";
import { ACCESS_PRIVILEGES } from "../constants";
import { UserResetPasswordAdmin } from "../components/UserResetPasswordAdmin";
import {FieldShowAccountNoDesc} from "../components/fields/FieldShowAccountNoDesc";
import {FieldShowPaypointDesc} from "../components/fields/FieldShowPaypointDesc";
import {ADMIN_LOCATION_LEVEL_USER_OPTIONS} from "../constants";

import Config from "../config";
import sample from "../profileImage/sample.png";
import Sidebar from "../components/Sidebar";
import { useMe } from "../hooks/useMe";

import OtherDetails from "./OtherDetails";

import "../styles/profile.scss";

const TOTAL_FIELDS = 42;
const filter = createFilterOptions();


const fields = [
  'award award_desc', 'classification class_desc',
  'account_no account_no_desc', 'paypoint paypoint_desc'
]
const fields2 = {};
for (let idx = 0; idx < fields.length; idx++) {
  const field = fields[idx].split(' ');
  fields2[field[0]] = [field[1], field.join('_')];
  fields2[field[1]] = [field[0], field.join('_')];
}

const EmployeeView = ({ type }) => {
  const navigate = useNavigate();
  const { admin_location_id, employee_no, user_id } = useParams();
  const { user: loggedUser, permissions } = useMe(admin_location_id);
  const pageUserType = type === "Non-Teacher" ? "non-teachers" : "teachers";
  const [employee, setEmployee] = useState();
  const [needSave, setNeedSave] = useState(false);
  const [editable, setEditable] = useState(false);
  const [percent, setPercent] = useState(0);

  const [otherDetails, setOtherDetails] = useState(null);
  const [unattachedPositions, setUnattachedPositions] = useState([{ id: 'unattached', occup_pos_title: 'Unattached' }]);
  const [aPositions, setAPositions] = useState([]);
  const backUrl = admin_location_id ? `/${pageUserType}/${admin_location_id}` : '/dashboard';
  const { handleDeleteEmployee } = useDeleteEmployee({ redirectUrl: backUrl })
  const { handleArchiveEmployee } = useArchiveEmployee({ redirectUrl: backUrl })
  const [dropdowns, setDropdowns] = useState(null);

  const isUser = (!!user_id);
  console.log({ loggedUser });

  const employeeUpdate = useMutation((body) => new API().updateEmployee(body), {
    onSuccess: (data) => {
      if (data && data.ok) {
        toast.success("Data Updated Successfully!");
        navigate(backUrl);
      } else {
        toast.error(data?.error || "Data Update Failed!");
      }
    },
  });

  const getEmplyeeData = async () => {
    new API().getEmployee({ employee_no, user_id }).then((data) => {
      if (data != null) {
        setEmployee({ ...data["data"] });
        setUnattachedPositions(prev => [...prev, ...(data["data"]["position"] ? [data["data"]["position"]] : [])])
      } else {
        toast.error("Invalid Request!");
        navigate(backUrl);
      }
    });

    new API().getEmployeeDropdowns({ admin_location_id }).then((data) => {
      setDropdowns(data.data);
    });
  }

  const getAllUnattachedPositionData = async () => {
    new API().getAllUnatachechedPositions(admin_location_id).then((data) => {
      if (data != null) {
        setUnattachedPositions((prev) => [...prev, ...data["rows"]]);
      } else {
        toast.error("Invalid Request!");
        navigate(backUrl);
      }
    });
  }

  const getAPositions = async () => {
    new API().getAPositions(admin_location_id).then((data) => {
      if (data != null) {
        setAPositions((prev) => [...prev, ...data["rows"]]);
      } else {
        toast.error("Invalid Request!");
        navigate(backUrl);
      }
    });
  }

  const getData = async () => {
    await getEmplyeeData()
    if (admin_location_id) {
      await getAllUnattachedPositionData();
      await getAPositions();
    }
  }

  useEffect(() => {
    if (employee && employee.is_employee) {
      if (employee.other_details) {
        setOtherDetails(JSON.parse(employee.other_details));
      } else {
        setOtherDetails({
          num_of_beneficiaries: 1
        });
      }
    } else {
      setOtherDetails(null);
    }
  }, [employee]);

  useEffect(() => {
    if (employee_no || user_id) {
      void getData()
    }
  }, [employee_no, user_id]);

  useEffect(() => {
    if (otherDetails) {
      const total = Object.keys(otherDetails).filter(k => k && k != 'num_of_beneficiaries' && !k.includes('_beneficiary_')).length;
      const p = Math.min(Math.floor((total / TOTAL_FIELDS) * 100), 100);
      setPercent(p);
    }
  }, [otherDetails]);

  const handleChange = (e) => {
    if (!needSave) {
      setNeedSave(true);
    }
    if (e.target.name === 'occup_pos_title') {
      setEmployee({ ...employee, position: e.target.value === 'unattached' ? null : unattachedPositions.find(item => item?.id === e.target.value) });
      return
    }
    setEmployee({ ...employee, [e.target.name]: e.target.value });
  };

  
  const handleChangeAutocomplete = (name, value) => {
    if (name === "account_no_desc") {
      setEmployee((prev) => ({...prev, paypoint_desc2: ""}));
    }

    setEmployee((prev) => ({...prev, [name]: value}));
  };

  const createA = (field, value, options) => {
    return <Autocomplete
      className="autocomplete"
      value={value}
      onChange={(event, newValue) => {
        let newVal = ''
        if (newValue && newValue.inputValue) {
          newVal = newValue.inputValue;
        } else {
          newVal = newValue?.title || '';
        }

        if (!needSave) {
          setNeedSave(true);
        }

        console.log(field, fields2);

        if (field == 'occup_type') {
          if (newVal != 'HDA' && employee.occup_type == 'HDA') {
            const pos = unattachedPositions.find(p => p.occup_pos_title == employee.occup_pos_title2);

            if (!pos) {
              setEmployee({ ...employee, [field]: newVal });
              return;
            }

            setEmployee({
              ...employee,
              class_desc: pos.class_desc || '',
              award_desc: pos.award_desc || '',
              position_no: pos.position_no || '',
              account_no_desc: pos.account_no_desc || '',
              paypoint: pos.paypoint || '',
              paypoint_desc: pos.paypoint_desc || '',
              award: pos.award || '',
              classification: pos.classification || '',
              account_no: pos.account_no || '',
              occup_pos_title: employee.occup_pos_title2,
              acting_position: '',
              occup_pos_title2: '',
              [field]: newVal
            });

            return;
          } else {
            setEmployee({ ...employee, [field]: newVal });
          }
        } else if (fields2[field]) {
          let n = '';
          const f = (dropdowns[fields2[field][1]] || []).find(d => d[field] == newVal);
          if (f) {
            n = f[fields2[field][0]] || '';
          }

          console.log({ [fields2[field][0]]: n, [field]: newVal })
          setEmployee({ ...employee, [fields2[field][0]]: n, [field]: newVal });
        } else {
          setEmployee({ ...employee, [field]: newVal });
        }
      }}
      disabled={!editable}
      loading={options == null}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.title);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            {option.title}
          </li>
        );
      }}
      // sx={{ width: 300 }}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} label="" />
      )}
    />
  }

  const handleChange2 = (e) => {
    if (!needSave) {
      setNeedSave(true);
    }

    setOtherDetails({ ...otherDetails, [e.target.name]: e.target.value });
  };

  const setOtherDetails2 = (data) => {
    if (!needSave) {
      setNeedSave(true);
    }

    setOtherDetails(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    employeeUpdate.mutate({ ...employee, other_details: otherDetails });
  };

  const stringToDayjs = val => {
    if (!val) return val;

    if (typeof val == 'string') {
      return dayjs(val);
    }

    return val;
  }

  return (
    <div className="wrapper">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="content">
        <div className="profile">
          <div className="profileContainer">
            <div className="profileSection">
              <Link
                to={backUrl}
                className="backButtonSection"
              >
                <KeyboardBackspaceIcon className="backButton" />
              </Link>
              <br />
              <br />
              {(employee == null || dropdowns == null) && <div className="container">
                <div className="row">
                  <div className="col col-sm">
                    <h1>{isUser ? 'View User Details' : 'View Employee Details'}</h1>
                  </div>
                </div>
                <hr />
                <div style={{ padding: 20, textAlign: 'center' }}>
                  <CircularProgress size={40} color="inherit" />
                </div>
              </div>}
              {employee != null && dropdowns != null && <form>
                <div className="row">
                  <div className="col col-sm">
                    <h1>{isUser ? 'View User Details' : 'View Employee Details'}</h1>
                  </div>
                  <hr />
                  <div className="text-center" style={{ position: 'relative' }}>
                    <img
                      style={{
                        width: "200px",
                        height: "200px",
                        borderRadius: "50%",
                        objectFit: "contain",
                        border: '1px solid #f1f1f1'
                      }}
                      src={
                        employee.avatar_src
                          ? `${Config.api_url}/v1/admin/employee/profile_image/${employee.avatar_src}`
                          : sample
                      }
                      alt=""
                    />
                    {!isUser && <div className="employee-pie-chart">
                      <div className="label">Percent completed</div>
                      <PieChart
                        margin={{ left: 0, right: 0 }}
                        tooltip={{ trigger: 'none' }}
                        series={[
                          {
                            cornerRadius: 100,
                            innerRadius: "75%",
                            data: [{ id: 0, value: 100, color: "lightblue" }],
                          },
                          {
                            cornerRadius: 100,
                            innerRadius: "75%",
                            startAngle: 0,
                            endAngle: 360 * (percent / 100),
                            data: [{ id: 0, value: percent, color: "blue" }],
                          },
                        ]}
                      >
                        <text
                          x={"50%"}
                          y={"50%"}
                          textAnchor="middle"
                          dominantBaseline="central"
                          fontSize={18}
                        >
                          {percent}%
                        </text>
                      </PieChart>
                    </div>}
                  </div>
                  {(permissions.super && !employee.email) && (
                    <UploadFile
                      invalidateQueries="employees"
                      url={`${Config.api_url}/v1/admin/employee/employee_avatar?employee_no=${employee_no || ''}&user_id=${user_id || ''}`}
                      name="avatar_file"
                    />
                  )}
                  <hr />

                  <div className="row">
                    <div className="col col-sm">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="user_type">
                          <b>User type</b>
                        </label>
                        <br />
                        <input
                          className="form-control"
                          type="text"
                          name="user_type"
                          id="user_type"
                          value={employee.user_type}
                          disabled
                        />
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="employee_no">
                          <b>Employee no</b>
                        </label>
                        <br />
                        <input
                          className="form-control"
                          type="text"
                          name="employee_no"
                          id="employee_no"
                          value={employee.employee_no}
                          disabled
                        />
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="name_report">
                          <b>Name report</b>
                        </label>
                        <br />
                        <input
                          className="form-control"
                          type="text"
                          name="name_report"
                          id="name_report"
                          value={employee.name_report}
                          disabled
                        />
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="admin_location">
                          <b>Admin location</b>
                        </label>
                        <br />
                        <input
                          className="form-control"
                          type="text"
                          name="admin_location"
                          id="admin_location"
                          value={employee.admin_location}
                          disabled
                        />
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="admin_desc">
                          <b>Admin desc</b>
                        </label>
                        <br />
                        <input
                          className="form-control"
                          type="text"
                          name="admin_desc"
                          id="admin_desc"
                          value={employee.admin_desc}
                          disabled
                        />
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="position_no">
                          <b>Position no</b>
                        </label>
                        <br />
                        <input
                          className="form-control"
                          type="text"
                          name="position_no"
                          id="position_no"
                          value={employee.position_no}
                          disabled
                          onChange={(e) => handleChange(e)}
                          required
                        />
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="occup_pos_title">
                          <b>Occup pos title</b>
                        </label>
                        <br />
                        <select
                          className="form-control"
                          name="occup_pos_title"
                          id="occup_pos_title"
                          value={employee.occup_pos_title || 'unattached'}
                          disabled={!editable}
                          onChange={(e) => {
                            console.log(e.target.value)
                            const pos = unattachedPositions.find(p => p.occup_pos_title == e.target.value);
                            if (!needSave) {
                              setNeedSave(true);
                            }
                            if (pos) {
                              setEmployee({
                                ...employee,
                                class_desc: pos.class_desc || '',
                                award_desc: pos.award_desc || '',
                                position_no: pos.position_no || '',
                                occup_type: pos.occup_type || '',
                                account_no_desc: pos.account_no_desc || '',
                                paypoint: pos.paypoint || '',
                                paypoint_desc: pos.paypoint_desc || '',
                                award: pos.award || '',
                                classification: pos.classification || '',
                                account_no: pos.account_no || '',
                                [e.target.name]: e.target.value
                              });
                            } else {
                              setEmployee({ ...employee, [e.target.name]: e.target.value });
                            }
                          }}
                          required
                        >
                          {unattachedPositions.filter(u => u).map((item, idx) => (
                            <option key={idx} value={item.occup_pos_title}>
                              {item.occup_pos_title}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="award">
                          <b>Award</b>
                        </label>
                        <br />
                        {createA('award', employee.award, dropdowns?.award)}
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="award_desc">
                          <b>Award desc</b>
                        </label>
                        <br />
                        {createA('award_desc', employee.award_desc, dropdowns?.award_desc)}
                      </div>
                      {<div className="form-outline">
                        <label className="form-label" htmlFor="email">
                          <b>Email address</b>
                        </label>
                        <br />
                        <input
                          className="form-control"
                          type="text"
                          name="email"
                          id="email"
                          value={employee.email}
                          onChange={(e) => handleChange(e)}
                          disabled={!editable}
                        />
                      </div>}
                      {/* <div className="form-outline">
                        <label className="form-label" htmlFor="acting_position">
                          <b>Acting position</b>
                        </label>
                        <br />
                        <select
                          className="form-control"
                          name="acting_position"
                          id="acting_position"
                          disabled={!editable}
                          value={employee.acting_position}
                          onChange={(e) => {
                            const val = e.target.value;
                            if (!needSave) {
                              setNeedSave(true);
                            }
                            console.log('val', val)
                            let acting_position_detail = employee.acting_position_detail;
                            let occup_type = employee.occup_type;
                            if (val == 'Yes') {
                              occup_type = 'HDA';
                            }

                            if (val == 'No') {
                              occup_type = 'SUB';
                              acting_position_detail = '';
                            }
                            setEmployee({
                              ...employee,
                              [e.target.name]: val,
                              acting_position_detail,
                              occup_type
                            });
                          }}
                        >
                          <option key={'No'} value={'No'}>{'No'}</option>
                          <option key={'Yes'} value={'Yes'}>{'Yes'}</option>
                        </select>
                      </div> */}
                    </div>
                    <div className="col col-sm">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="classification">
                          <b>Classification</b>
                        </label>
                        <br />
                        {createA('classification', employee.classification, dropdowns?.classification)}
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="class_desc">
                          <b>Class desc</b>
                        </label>
                        <br />
                        {createA('class_desc', employee.class_desc, dropdowns?.class_desc)}
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="step_no">
                          <b>Step no</b>
                        </label>
                        <br />
                        {createA('step_no', employee.step_no, dropdowns?.step_no)}
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="occup_type">
                          <b>Occup type</b>
                        </label>
                        <br />
                        {createA('occup_type', employee.occup_type, dropdowns?.occup_type)}
                      </div>
                      {employee?.occup_type == 'HDA' && <div className="form-outline">
                        <label className="form-label" htmlFor="acting_position">
                          <b>Acting position</b>
                        </label>
                        <br />
                        <select
                          className="form-control"
                          name="acting_position"
                          id="acting_position"
                          value={employee.acting_position}
                          disabled={!editable}
                          onChange={(e) => {
                            console.log(e.target.value)
                            const pos = unattachedPositions.find(p => p.occup_pos_title == e.target.value);
                            if (!needSave) {
                              setNeedSave(true);
                            }
                            if (pos) {
                              setEmployee({
                                ...employee,
                                class_desc: pos.class_desc || '',
                                award_desc: pos.award_desc || '',
                                // position_no: pos.position_no || '',
                                account_no_desc: pos.account_no_desc || '',
                                paypoint: pos.paypoint || '',
                                paypoint_desc: pos.paypoint_desc || '',
                                award: pos.award || '',
                                classification: pos.classification || '',
                                account_no: pos.account_no || '',
                                occup_pos_title2: employee.occup_pos_title,
                                // occup_pos_title: e.target.value,
                                [e.target.name]: e.target.value
                              });
                            } else {
                              setEmployee({ ...employee, [e.target.name]: e.target.value });
                            }
                          }}
                          required
                        >
                          <option key={'none'} value={''}>{''}</option>
                          {aPositions.map((item, idx) => (
                            <option key={idx} value={item.occup_pos_title}>
                              {item.occup_pos_title}
                            </option>
                          ))}
                        </select>
                      </div>}
                      {employee?.occup_type == 'HDA' && <div className="form-outline">
                        <label className="form-label" htmlFor="acting_position_no">
                          <b>Acting position no</b>
                        </label>
                        <br />
                        <input
                          className="form-control"
                          type="text"
                          name="acting_position_no"
                          id="acting_position_no"
                          value={unattachedPositions?.find(p => p.occup_pos_title == employee.acting_position)?.position_no || ''}
                          disabled
                        />
                      </div>}
                      <div className="form-outline">
                        <label className="form-label" htmlFor="gender">
                          <b>Gender</b>
                        </label>
                        <br />
                        <select
                          className="form-select"
                          name="gender"
                          id="gender"
                          value={employee.gender}
                          onChange={handleChange}
                          disabled={!editable}
                          required
                        >
                          <option value={"M"}>Male</option>
                          <option value={"F"}>Female</option>
                        </select>
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="first_commence">
                          <b>First commence</b>
                        </label>
                        <br />
                        <DatePicker className="date-input"
                          value={stringToDayjs(employee?.first_commence)}
                          onAccept={value => {
                            if (!needSave) {
                              setNeedSave(true);
                            }
                            setEmployee({ ...employee, first_commence: value });
                          }}
                        />
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="account_no">
                          <b>Account no</b>
                        </label>
                        <br />
                        {createA('account_no', employee.account_no, dropdowns?.account_no)}
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="account_no_desc">
                          <b>Account no desc</b>
                        </label>
                        <br />
                        {createA('account_no_desc', employee.account_no_desc, dropdowns?.account_no_desc)}
                      </div>
                      {<div className="form-outline">
                        <label className="form-label" htmlFor="access_privilege">
                          <b>Access privilege</b>
                        </label>
                        <br />
                        <select
                          className="form-control"
                          name="access_privilege"
                          id="access_privilege"
                          value={employee.access_privilege}
                          disabled={!editable}
                          onChange={(e) => handleChange(e)}
                          required
                        >
                          {ACCESS_PRIVILEGES.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </div>}
                      {[
                        ADMIN_LOCATION_LEVEL_USER_OPTIONS.ACCOUNT_NO_DESC_USER,
                        ADMIN_LOCATION_LEVEL_USER_OPTIONS.PAYPOINT_DESC_USER,
                      ].includes(employee.access_privilege) && (
                          <FieldShowAccountNoDesc
                            name="account_no_desc2"
                            value={employee.account_no_desc2}
                            onChange={handleChangeAutocomplete}
                            adminLocationId={admin_location_id}
                            disabled={!editable}
                          />
                        )}
                      {employee.access_privilege ===
                        ADMIN_LOCATION_LEVEL_USER_OPTIONS.PAYPOINT_DESC_USER &&
                        !!employee.account_no_desc2 && (
                          <FieldShowPaypointDesc
                            name="paypoint_desc2"
                            value={employee.paypoint_desc2}
                            onChange={handleChangeAutocomplete}
                            adminLocationId={admin_location_id}
                            accountNoDesc={employee.account_no_desc2}
                            disabled={!editable}
                          />
                        )}
                      {/* {employee.acting_position == 'Yes' && <div className="form-outline">
                        <label className="form-label" htmlFor="acting_position_detail">
                          <b>Select acting position</b>
                        </label>
                        <br />
                        <select
                          className="form-control"
                          name="acting_position_detail"
                          id="acting_position_detail"
                          value={employee.acting_position_detail}
                          disabled={!editable}
                          onChange={(e) => handleChange(e)}
                          required
                        >
                          <option key={'none'} value={''}>{''}</option>
                          {aPositions.map((item, idx) => (
                            <option key={idx} value={item.occup_pos_title}>
                              {item.occup_pos_title}
                            </option>
                          ))}
                        </select>
                      </div>} */}
                    </div>
                    <div className="col col-sm">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="paypoint">
                          <b>Paypoint</b>
                        </label>
                        <br />
                        {createA('paypoint', employee.paypoint, dropdowns?.paypoint)}
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="paypoint_desc">
                          <b>Paypoint desc</b>
                        </label>
                        <br />
                        {createA('paypoint_desc', employee.paypoint_desc, dropdowns?.paypoint_desc)}
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="emp_status">
                          <b>Emp status</b>
                        </label>
                        <br />
                        {createA('emp_status', employee.emp_status, dropdowns?.emp_status)}
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="date_of_birth">
                          <b>Date of birth</b>
                        </label>
                        <br />
                        <DatePicker className="date-input"
                          value={stringToDayjs(employee?.date_of_birth)}
                          onAccept={value => {
                            if (!needSave) {
                              setNeedSave(true);
                            }
                            setEmployee({ ...employee, date_of_birth: value });
                          }}
                        />
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="age">
                          <b>Age</b>
                        </label>
                        <br />
                        <input
                          className="form-control"
                          type="number"
                          name="age"
                          id="age"
                          value={employee.age}
                          disabled
                          required
                        />
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="occup_pos_cat">
                          <b>Occup pos cat</b>
                        </label>
                        <br />
                        <select
                          className="form-control"
                          name="occup_pos_cat"
                          id="occup_pos_cat"
                          value={employee?.occup_pos_cat}
                          onChange={(e) => handleChange(e)}
                          disabled={!editable}
                          required
                        >
                          <option key={'-1'} value={''}>{''}</option>
                          <option key={'AD'} value={'AD'}>{'AD'}</option>
                          <option key={'JS'} value={'JS'}>{'JS'}</option>
                          <option key={'MN'} value={'MN'}>{'MN'}</option>
                          <option key={'SP'} value={'SP'}>{'SP'}</option>
                          <option key={'TC'} value={'TC'}>{'TC'}</option>
                          <option key={'XX'} value={'XX'}>{'XX'}</option>
                        </select>
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="status">
                          <b>Status</b>
                        </label>
                        <br />
                        <input
                          className="form-control"
                          type="text"
                          name="status"
                          id="status"
                          value={employee.status ? "Active" : "Inactive"}
                          disabled
                        />
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="created_by">
                          <b>Created by</b>
                        </label>
                        <br />
                        <input
                          className="form-control"
                          type="text"
                          name="created_by"
                          id="created_by"
                          value={employee.creator}
                          disabled
                        />
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="created_at">
                          <b>Created at</b>
                        </label>
                        <br />
                        <input
                          className="form-control"
                          type="text"
                          name="created_at"
                          id="created_at"
                          value={new Date(employee.created_at).toDateString()}
                          disabled
                        />
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="updated_at">
                          <b>Updated at</b>
                        </label>
                        <br />
                        <input
                          className="form-control"
                          type="text"
                          name="updated_at"
                          id="updated_at"
                          value={new Date(employee.updated_at).toDateString()}
                          disabled
                        />
                      </div>
                      {isUser && <div className="form-outline">
                        <label className="form-label" htmlFor="status">
                          <b>Account status</b>
                        </label>
                        <br />
                        <Switch
                          checked={employee.account_status}
                          disabled={!editable}
                          onChange={() => {
                            if (!needSave) {
                              setNeedSave(true);
                            }

                            setEmployee({ ...employee, account_status: !employee.account_status });
                          }
                          }
                        />
                      </div>}
                    </div>
                  </div>
                  <hr />
                  {otherDetails != null && <OtherDetails
                    otherDetails={otherDetails} handleChange2={handleChange2}
                    setOtherDetails={setOtherDetails2} editable={editable}
                  />}
                  <Stack direction="row" style={{ padding: '0px' }}>
                    {permissions.super && (
                      <Link className="app-button app-button-gray"
                        to={`/${pageUserType}/${admin_location_id}`}
                        style={{ marginRight: 10, marginLeft: 10 }}>
                        Back
                      </Link>
                    )}
                    {permissions.super && (
                      <button
                        className="app-button app-button-primary"
                        style={{ marginRight: 10 }}
                        disabled={editable}
                        onClick={(e) => setEditable(!editable)}
                      >
                        Edit
                      </button>
                    )}
                    {permissions.super && (
                      <button
                        className="app-button app-button-primary"
                        style={{ marginRight: 10 }}
                        disabled={!editable}
                        onClick={(e) => handleSubmit(e)}
                      >
                        Save
                      </button>
                    )}
                    {permissions.super && (
                      <button
                        className="app-button app-button-primary"
                        style={{ marginRight: 10 }}
                        onClick={(e) => handleDeleteEmployee(e, { employee_no, user_id })}
                      >
                        Delete
                      </button>
                    )}
                    {permissions.super && (
                      <button
                        className="app-button app-button-primary"
                        style={{ marginRight: 10 }}
                        onClick={(e) => handleArchiveEmployee(e, { employee_no, user_id })}
                      >
                        Archive
                      </button>
                    )}
                    {permissions.super && (
                      <EmployeeTransfer refetchData={() => {
                        navigate(backUrl);
                      }} employee={employee} type={type} />
                    )}
                  </Stack>
                </div>
              </form>}
              {permissions.super && isUser && (
                <UserResetPasswordAdmin user_id={user_id} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeView;
