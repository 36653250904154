import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";
import CircularProgress from '@mui/material/CircularProgress';

import { FieldText } from "../components/fields/FieldText";
import { UserResetPassword } from "../components/UserResetPassword";
import { useMe } from "../hooks/useMe";
import API from "../APIs/API";
import Sidebar from "../components/Sidebar";
import { UploadFile } from "../components/UploadFile";
import Config from "../config";
import sample from "../profileImage/sample.png";
import OtherDetails from "./OtherDetails";

import "../styles/profile.scss";

const initialUser = {
  id: 0,
  surname: "",
  given_name: "",
  job_title: "",
  access_privilege: "",
  admin_location_level_user: "",
  admin_desc: "",
  office_phone: "",
  mobile_number: "",
  email: "",
  creator: "",
  created_at: "",
  updated_at: "",
  employee_no: '',
  avatar_src: '',
  is_employee: false
};

const UserProfile = () => {
  const { user: loggedUser, permissions } = useMe();
  const queryClient = useQueryClient();
  const [user, setUser] = useState(initialUser);
  const [hasPermission, setHasPermission] = useState(false);

  const [otherDetails, setOtherDetails] = useState(null);

  const updateProfile = useMutation((body) => new API().updateProfile(body), {
    onSuccess: (data) => {
      if (data) {
        toast.success("Profile Updated Successfully !");
        queryClient.invalidateQueries("/users/me");
      } else {
        toast.error("Profile Update Failed !");
      }
    }
  })

  useEffect(() => {
    if (loggedUser) {
      setUser({
        id: loggedUser.id,
        surname: loggedUser.surname,
        given_name: loggedUser.given_name,
        job_title: loggedUser.job_title,
        access_privilege: loggedUser.access_privilege,
        admin_location_level_user: loggedUser.admin_location_level_user,
        admin_desc: loggedUser.admin_location?.admin_desc,
        office_phone: loggedUser.office_phone,
        mobile_number: loggedUser.mobile_number,
        email: loggedUser.email,
        creator: loggedUser.creator,
        created_at: loggedUser.created_at,
        updated_at: loggedUser.updated_at,
        employee_no: loggedUser.employee_no
      });
      setHasPermission(permissions.super || loggedUser.admin_location_level_user === "Admin Location User");
      if (loggedUser.is_employee) {
        if (loggedUser.other_details) {
          const otherDetails = JSON.parse(loggedUser.other_details)
          setOtherDetails(otherDetails);
        } else {
          setOtherDetails({
            num_of_beneficiaries: 1
          });
        }
      }
    }
  }, [loggedUser]);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleChange2 = (e) => {
    setOtherDetails({ ...otherDetails, [e.target.name]: e.target.value });
  };

  const setOtherDetails2 = (data) => {
    setOtherDetails(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateProfile.mutate({ ...user, other_details: otherDetails });
  };

  if (!loggedUser) {
    return (<div style={{ padding: 20, textAlign: 'center' }}><CircularProgress color="inherit" /></div>)
  }

  return (
    <div className="wrapper">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="content">
        <div className="profile">
          <div className="profileContainer">
            <div className="profileSection">
              <form>
                <div className="container">
                  <div className="row">
                    <div className="col col-sm">
                      <h1>My Profile</h1>
                    </div>
                    <div className="col col-sm">
                      {(hasPermission || loggedUser?.is_employee) && (<button
                        type="submit"
                        className="saveBtn btn btn-primary btn-block mb-3"
                        onClick={(e) => handleSubmit(e)}
                      >
                        Save changes
                      </button>)}
                    </div>
                  </div>
                  <hr />
                  {loggedUser?.is_employee && <div className="text-center">
                    <img
                      style={{
                        width: "200px",
                        height: "200px",
                        borderRadius: "50%",
                        objectFit: "contain",
                        border: '1px solid #f1f1f1'
                      }}
                      src={
                        loggedUser?.avatar_src
                          ? `${Config.api_url}/v1/admin/employee/profile_image/${loggedUser?.avatar_src}`
                          : sample
                      }
                      alt=""
                    />
                  </div>}
                  {(loggedUser?.is_employee) && (
                    <>
                      <UploadFile
                        invalidateQueries="employees"
                        url={`${Config.api_url}/v1/admin/employee/employee_avatar?employee_no=${loggedUser?.employee_no}`}
                        name="avatar_file"
                      />
                      <hr />
                    </>
                  )}
                  <div className="row">
                    <div className="col col-sm">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="surname">
                          <b>Surname</b>
                        </label>
                        <br />
                        <input
                          className="form-control"
                          type="text"
                          name="surname"
                          id="surname"
                          value={user?.surname}
                          onChange={(e) => handleChange(e)}
                          required
                          disabled={!hasPermission}
                        />
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="given_name">
                          <b>Given name</b>
                        </label>
                        <br />
                        <input
                          className="form-control"
                          type="text"
                          name="given_name"
                          id="given_name"
                          value={user?.given_name}
                          onChange={(e) => handleChange(e)}
                          required
                          disabled={!hasPermission}
                        />
                      </div>
                      {loggedUser?.is_employee && <div className="form-outline">
                        <label className="form-label" htmlFor="employee_no">
                          <b>Employee no</b>
                        </label>
                        <br />
                        <input
                          className="form-control"
                          type="text"
                          name="employee_no"
                          id="employee_no"
                          value={user?.employee_no}
                          disabled
                        />
                      </div>}
                      <div className="form-outline">
                        <label className="form-label" htmlFor="job_title">
                          <b>Occup Pos Title</b>
                        </label>
                        <br />
                        <input
                          className="form-control"
                          type="text"
                          name="job_title"
                          id="job_title"
                          value={user?.job_title || 'Unattached'}
                          onChange={(e) => handleChange(e)}
                          required
                          disabled={!hasPermission}
                        />
                      </div>
                      {user.access_privilege && (
                        <FieldText
                          label="Access privilege"
                          name="access_privilege"
                          value={user.access_privilege}
                          readOnly
                        />
                      )}
                      {!!user.admin_location && (
                        <FieldText
                          label="Admin Location Level User"
                          name="admin_location_level_user"
                          value={`${user.admin_location.admin_location} - ${user.admin_location.admin_desc}`}
                          readOnly
                        />
                      )}
                      {user.account_no_desc && (
                        <FieldText
                          label="Account No Desc"
                          name="account_no_desc"
                          value={user.account_no_desc}
                          readOnly
                        />
                      )}
                      {user.paypoint_desc && (
                        <FieldText
                          label="Paypoint desc"
                          name="paypoint_desc"
                          value={user.paypoint_desc}
                          readOnly
                        />
                      )}
                      <div className="form-outline">
                        <label className="form-label" htmlFor="office_phone">
                          <b>Office phone</b>
                        </label>
                        <br />
                        <input
                          className="form-control"
                          type="text"
                          name="office_phone"
                          id="office_phone"
                          value={user?.office_phone}
                          onChange={(e) => handleChange(e)}
                          required
                          disabled={!hasPermission && !loggedUser?.is_employee}
                        />
                      </div>
                    </div>
                    <div className="col col-sm">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="mobile_number">
                          <b>Mobile number</b>
                        </label>
                        <br />
                        <input
                          className="form-control"
                          type="text"
                          name="mobile_number"
                          id="mobile_number"
                          value={user?.mobile_number}
                          onChange={(e) => handleChange(e)}
                          required
                          disabled={!hasPermission && !loggedUser?.is_employee}
                        />
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="email">
                          <b>Email</b>
                        </label>
                        <br />
                        <input
                          className="form-control"
                          type="text"
                          name="email"
                          id="email"
                          value={user?.email}
                          onChange={(e) => handleChange(e)}
                          readOnly
                          disabled
                        />
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="created_by">
                          <b>Created by</b>
                        </label>
                        <br />
                        <input
                          className="form-control"
                          type="text"
                          name="created_by"
                          id="created_by"
                          value={user?.creator}
                          onChange={(e) => handleChange(e)}
                          readOnly
                          disabled
                        />
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="created_at">
                          <b>Created at</b>
                        </label>
                        <br />
                        <input
                          className="form-control"
                          type="text"
                          name="created_at"
                          id="created_at"
                          value={new Date(user?.created_at).toDateString()}
                          onChange={(e) => handleChange(e)}
                          readOnly
                          disabled
                        />
                      </div>
                      <div className="form-outline">
                        <label className="form-label" htmlFor="updated_at">
                          <b>Updated at</b>
                        </label>
                        <br />
                        <input
                          className="form-control"
                          type="text"
                          name="updated_at"
                          id="updated_at"
                          value={new Date(user?.updated_at).toDateString()}
                          onChange={(e) => handleChange(e)}
                          readOnly
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  {loggedUser?.is_employee && otherDetails != null && <OtherDetails
                    otherDetails={otherDetails} handleChange2={handleChange2}
                    setOtherDetails={setOtherDetails2} editable={true}
                  />}
                </div>
              </form>
              <hr />
              <div className="container">
                <div className="row">
                  <div className="col col-sm" style={{ display: 'flex' }}>
                    <div><UserResetPassword /></div>
                    {/* {permissions.superRead && <div style={{ margin: '8px 15px' }} className="upload-sm">
                      <UploadFile
                        invalidateQueries="employees"
                        url={`${Config.api_url}/v1/admin/employee/email_template`}
                        name="file"
                        label="Upload Email List"
                        callback={src => {

                        }}
                      />
                    </div>} */}
                  </div>
                  <div className="col col-sm">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
