import { Link } from "react-router-dom";
import {NON_TEACHER} from "../../constants";

export function EmployeeAction({ row }) {
  const pageUserType = row.user_type === NON_TEACHER ? "non-teachers" : "teachers";
  let link = `/${pageUserType}/${row.admin_location_id}/${row.employee_no}`;

  // if (row.user_id) {
  //   link = `/employees/${row.user_id}`;
  // }

  return (
    <div className="cellAction">
      <Link
        to={link}
        style={{ textDecoration: "none" }}
      >
        <div className="viewButton">View</div>
      </Link>
    </div>
  );
}
