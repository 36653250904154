import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {DataGrid} from "@mui/x-data-grid";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {useQuery} from "react-query";
import moment from "moment/moment";
import Chip from '@mui/material/Chip';

import {usePaginationParams} from "../hooks/usePaginationParams";
import {makeQueryString} from "../APIs/request";
import {useMe} from "../hooks/useMe";
import Sidebar from "../components/Sidebar";
import API from "../APIs/API";

import "../styles/list.scss";

const Divisions = ({type, check_admin_loc_id}) => {
  const {permissions} = useMe();
  let pageUserType = type === "Non-Teacher" ? "non-teachers" : "teachers";
  let pathname = window.location.pathname;
  let admin_location_id = check_admin_loc_id ? pathname.split("/")[2] : null;
  const [eventLoc, setEventLoc] = useState();

  const {gridParams, queryParams} = usePaginationParams();

  const {data: devices = [], isFetching} = useQuery([
    "divisions",
    makeQueryString(queryParams, {admin_location_id, user_type: (type || '').toLowerCase()}),
  ], {
    queryFn: async () => {
      const queryString = makeQueryString(queryParams, {
        admin_location_id, user_type: (type || '').toLowerCase()
      });
      return await new API().getAllDivisions(queryString);
    },
    enabled: !!admin_location_id || type != null,
  });

  useEffect(() => {
    if (admin_location_id) {
      new API().getAdminLocationById(admin_location_id).then((data) => {
        if (data != null) {
          setEventLoc(`for ${data["data"].admin_desc || ''}`);
        }
      });
    } else {
      let el = (type && type != 'All') ? `for ${type}` : '';
      setEventLoc(el);
    }
  }, [admin_location_id]);

  const deviceColumns = [
    {
      field: "division_name",
      headerName: "Division",
      width: 200,
    },
    {
      field: "account_no_desc",
      headerName: "Account No Desc",
      width: 330,
      renderCell: ({row}) => {
        if (!row.account_no_desc || row.account_no_desc.length == 0) {
          return '';
        }

        return (
          <div style={{ columnGap: '7px', rowGap: '3px', display: 'flex', flexWrap: 'wrap', padding: '2px 0px' }}>
            {row.account_no_desc.map(a => {
              return <Chip key={a} label={a} size="small" />
            })}
          </div>
        );
      },
    },
    {
      field: "division_head_users",
      headerName: "Division Head Users",
      width: 330,
      renderCell: ({row}) => {
        if (!row.division_head_users || row.division_head_users.length == 0) {
          return '';
        }

        return (
          <div style={{ columnGap: '7px', rowGap: '3px', display: 'flex', flexWrap: 'wrap', padding: '2px 0px' }}>
            {row.division_head_users.map(a => {
              return <Chip key={a.email} label={a.email} size="small" />
            })}
          </div>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created at",
      width: 200,
      renderCell: ({row}) => {
        return (
          <>
            <p>{moment(row.created_at).format("YYYY-MM-DD HH:mm:ss")}</p>
          </>
        );
      },
    },
    {
      field: "updated_at",
      headerName: "Updated at",
      width: 200,
      renderCell: ({row}) => {
        return (
          <>
            <p>{moment(row.updated_at).format("YYYY-MM-DD HH:mm:ss")}</p>
          </>
        );
      },
    },
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 80,
      renderCell: (params) => {
        let linkTo = `/${pageUserType}/${admin_location_id}/divisions/view/${params.row.id}`;

        if (!admin_location_id) {
          linkTo = `view/${params.row.id}`;
        }

        return (
          <div className="cellAction">
            <Link
              to={linkTo}
              style={{textDecoration: "none"}}
            >
              <div className="viewButton">View</div>
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <div className="wrapper">
      <div className="sidebar">
        <Sidebar/>
      </div>
      <div className="content">
        <div className="list">
          <div className="listContainer">
            <div className="datatable">
              {admin_location_id != null && <>
                <Link to={`/${pageUserType}`} className="backButtonSection">
                  <KeyboardBackspaceIcon className="backButton"/>
                </Link>
                <br/>
                <br/>
              </>}

              {!permissions.employee_user && <div className="datatableTitle">
                Divisions {eventLoc}
                {admin_location_id != null && <Link
                  to={`/${pageUserType}/${admin_location_id}/divisions/add`}
                  className="link"
                >
                  <button className="addBtn">Add new</button>
                </Link>}
                {admin_location_id == null && <Link
                  to={`/divisions${type == 'All' ? '' : `/${pageUserType}`}/add`}
                  className="link"
                >
                  <button className="addBtn">Add new</button>
                </Link>}
              </div>}
              <DataGrid
                className="divisions-grid"
                rows={devices?.rows || []}
                columns={actionColumn.concat(deviceColumns)}
                rowCount={devices?.count ?? 0}
                loading={isFetching}
                {...gridParams}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Divisions;
