import Highlands from './ward/Wards-Highlands Region.json';
import Islands from './ward/Wards-Islands Region.json';
import Momase from './ward/Wards-Momase Region.json';
import Southern from './ward/Wards-Southern Region.json';

export const BUREAUCRAT_USER = 'Bureaucrat User';
export const SUPER_USER = "Super User";
export const NON_TEACHER_USER = "Non-Teacher User";
export const TEACHER_USER = "Teacher User";

export const TEACHER = "Teacher";

export const NON_TEACHER = "Non-Teacher";

export const ADMIN_DESC_USER = "Admin Desc User";

export const ACCOUNT_NO_DESC_USER = "Account No Desc User";

export const PAYPOINT_DESC_USER = "Paypoint Desc User";

export const EMPLOYEE_USER = "Employee User";

export const AGENCY_HEAD_DELEGATE_USER = "Agency Head Delegate User";

export const HR_DELEGATE_USER = "HR Delegate User";

export const SALARY_OFFICER_USER = "Salary Officer User";

export const HR_MANAGER_USER = "HR Manager User";

export const DIVISION_HEAD_USER = "Division Head User";

export const BRANCH_HEAD_USER = "Branch Head User";

export const USER_TYPE_FROM_KEY = {
  teachers: "Teacher",
  teacher: "Teacher",
  "non-teachers": "Non-Teacher",
  "non-teacher": "Non-Teacher",
};

export const ADMIN_LOCATION_LEVEL_USER_OPTIONS = {
  ADMIN_LOCATION_USER: "Admin Location User",
  ACCOUNT_NO_DESC_USER,
  PAYPOINT_DESC_USER,
};

export const ACCESS_PRIVILEGES = [
  BUREAUCRAT_USER,
  SUPER_USER, NON_TEACHER_USER, TEACHER_USER, ADMIN_DESC_USER,
  ACCOUNT_NO_DESC_USER, PAYPOINT_DESC_USER, 
  EMPLOYEE_USER
  // SALARY_OFFICER_USER,
  // AGENCY_HEAD_DELEGATE_USER, HR_MANAGER_USER, HR_DELEGATE_USER,
  // DIVISION_HEAD_USER, BRANCH_HEAD_USER
]

const data = { ...Highlands, ...Islands, ...Momase, ...Southern };
const wardresults = [];

Object.keys(data).map(key => {
  wardresults.push(...data[key]);
})

const removeNum = (str) => {
  const idx = str.indexOf('.');

  if (idx > -1) {
    return str.substring(idx + 1).trim();
  }

  return str;
}

export const allProvinces = wardresults.map(w => w.Province).filter((value, index, array) => {
  return array.indexOf(value) === index;
}).map(p => removeNum(p || '')).sort();

export const getDistricts = (province) => {
  return wardresults.filter(w => removeNum(w.Province) == province).map(w => w.District)
    .filter((value, index, array) => {
      return array.indexOf(value) === index;
    }).map(p => removeNum(p || '')).sort();
}

export const getLLG = (province, district) => {
  return wardresults.filter(w => removeNum(w.Province) == province && removeNum(w.District) == district)
    .map(w => w.LLG).filter((value, index, array) => {
      return array.indexOf(value) === index;
    }).map(p => removeNum(p || '')).sort();
}

export const getWards = (province, district, llg) => {
  return wardresults.filter(w => removeNum(w.Province) == province && removeNum(w.District) == district && removeNum(w.LLG) == llg)
    .map(w => w.Ward).filter((value, index, array) => {
      return array.indexOf(value) === index;
    }).map(p => removeNum(p || '')).sort();
}
export const TYPE_OF_LEAVE = [
  'Recreation Leave',
  'Sick Leave',
  'Furlough Leave',
  'Compassionate Leave',
  'Study Leave',
  'Maternity Leave',
  'Paternity Leave',
  'LWOP',
  'Other'
]

export const TYPE_OF_LEAVE_M = [
  'Recreation Leave',
  'Sick Leave',
  'Furlough Leave',
  'Compassionate Leave',
  'Study Leave',
  'Paternity Leave',
  'LWOP',
  'Other'
]

export const TYPE_OF_LEAVE_F = [
  'Recreation Leave',
  'Sick Leave',
  'Furlough Leave',
  'Compassionate Leave',
  'Study Leave',
  'Maternity Leave',
  'LWOP',
  'Other'
]

export const AIRPORTS_LIST = [
  'Aitape Airstrip (Aitape)', 'Aiyura Airport (Kainantu)', 'Ambunti Airstrip (Ambunti)', 
  'Aropa Airport (Kieta)', 'Buka Airport (Buka)', 'Bulolo Airport (Bulolo)', 
  'Chimbu Airport (Kundiawa)', 'Daru Airport (Daru)', 'Finschhafen Airstrip (Finschhafen)', 
  'Goroka Airport (Goroka)', 'Green River Airstrip (Green River)', 'Gurney Airport (Alotau)', 
  'Hoskins Airport (Kimbe)', 'Ihu Airstrip (Ihu)', 'Itokama Airstrip (Itokama)', 
  'Jacksons International Airport (Port Moresby)', 'Kagi Airstrip (Kagi)', 
  'Kandep Airstrip (Kandep)', 'Kavieng Airport (Kavieng)', 'Kerema Airport (Kerema)', 
  'Kiunga Airport (Kiunga)', 'Kokoda Airstrip (Kokoda)', 'Kopiago Airstrip (Kopiago)', 
  'Kundiawa Airstrip (Kundiawa)', 'Losuia Airport (Losuia, Trobriand Islands)', 'Madang Airport (Madang)', 
  'Mendi Airport (Mendi)', 'Misima Airport (Misima Island)', 'Morehead Airstrip (Morehead)', 
  'Moro Airport (Moro)', 'Mount Hagen Airport (Mount Hagen)', 'Nadzab Airport (Lae)', 
  'Nissan Island Airport (Nissan Island)', 'Oksapmin Airstrip (Oksapmin)', 
  'Popondetta Airport (Popondetta)', 'Porgera Airstrip (Porgera)', 'Rumginae Airstrip (Rumginae)', 
  'Samarai Airstrip (Samarai)', 'Tabubil Airport (Tabubil)', 'Tapini Airstrip (Tapini)', 
  'Tari Airport (Tari)', 'Tokua Airport (Rabaul/Kokopo)', 'Tsewi Airstrip (Tsewi)', 
  'Tufi Airport (Tufi)', 'Vanimo Airport (Vanimo)', 'Wewak Airport (Wewak)', 
  'Woitape Airstrip (Woitape)', 'Yalumet Airstrip (Yalumet)', 'Wapenamanda Airport (Wapenamanda)'
].sort()

