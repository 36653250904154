import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useMutation } from "react-query";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from '@mui/material/CircularProgress';
import {Modal} from "antd";

import API from "../APIs/API";
import Sidebar from "../components/Sidebar";
import { useMe } from "../hooks/useMe";

import "../styles/profile.scss";

const DivisionAdd = ({ type, viewMode }) => {
  const { user: loggedUser } = useMe();

  const pageUserType = type === "Non-Teacher" ? "non-teachers" : "teachers";
  const { admin_location_id, id } = useParams();
  const navigate = useNavigate();
  const divisionCreate = useMutation((body) => new API().addDivision(body), {
    onSuccess(data) {
      if (data) {
        toast.success("Division created Successfully!");
        let linkBack = '';
        if (admin_location_id) {
          linkBack = `/${pageUserType}/${admin_location_id}/divisions`;
        } else {
          linkBack = `/divisions${type == 'All' ? '' : `/${pageUserType}`}`;
        }
        navigate(linkBack);
      } else {
        toast.error("Division creation Failed!");
      }
    },
  });

  const divisionUpdate = useMutation((body) => new API().updateDivision(body), {
    onSuccess(data) {
      if (data) {
        toast.success("Division updated Successfully!");
        let linkBack = '';
        if (admin_location_id) {
          linkBack = `/${pageUserType}/${admin_location_id}/divisions`;
        } else {
          linkBack = `/divisions${type == 'All' ? '' : `/${pageUserType}`}`;
        }
        navigate(linkBack);
      } else {
        toast.error("Division update Failed!");
      }
    },
  });

  const deleteDivison = useMutation({
    mutationFn: () => new API().deleteDivision(id),
    onSuccess: (data) => {
      if (data) {
        toast.success("Division deleted successfully");
        let linkBack = '';
        if (admin_location_id) {
          linkBack = `/${pageUserType}/${admin_location_id}/divisions`;
        } else {
          linkBack = `/divisions${type == 'All' ? '' : `/${pageUserType}`}`;
        }
        navigate(linkBack);
      } else {
        toast.error("Failed to delete Division!");
      }
    },
  });

  const loadData = () => {
    new API().getDivisionById(id).then((data) => {
      const divisionData = data["data"]["division"];
      set_division(divisionData);
    });
  }

  const { data: accountNoDescList = [], isFetching: isAccountsFetching } = useQuery(["account_no_desc_list"], {
    queryFn: async () => {
      const result = await new API().getAccountNoDescList(admin_location_id);
      return [...result["data"]];
    },
  });

  const { data: users = [], isFetching: isUsersFetching } = useQuery(["users"], {
    queryFn: async () => {
      return await new API().getAllUsers('?is_all=1');
    },
  });

  let initialData = {
    admin_location_id: admin_location_id,
    division_name: '',
    user_type: ((type && type != 'All') ? type : ''),
    created_by: loggedUser?.email,
    division_head_users: [],
    account_no_desc: []
  };

  const [event, setEvent] = useState(null);
  const [division, set_division] = useState(null);

  useEffect(() => {
    if (id && viewMode) {
      loadData();
    } else {
      setEvent(initialData);
    }
  }, [id])

  useEffect(() => {
    if (division) {
      const division_head_users = division?.division_head_users || [];
      const users2 = users?.rows || [];
      if (users2.length > 0 && division_head_users.length > 0) {
        setEvent({ ...division, division_head_users: users2.filter(u => division_head_users.includes(u.id)) });
      }
    }
  }, [users, division])

  const handleChange = (e) => {
    setEvent({ ...event, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!event.division_head_users || event.division_head_users.length == 0) {
      toast.error("Please select division head users!");
      return;
    }
    if (!event.account_no_desc || event.account_no_desc.length == 0) {
      toast.error("Please select account no desc!");
      return;
    }

    if (viewMode) {
      divisionUpdate.mutate({
        ...event,
        division_head_users: event.division_head_users.map(u => u.id)
      });
    } else {
      divisionCreate.mutate({
        ...event,
        division_head_users: event.division_head_users.map(u => u.id)
      });
    }
  };

  let linkBack = '';
  if (admin_location_id) {
    linkBack = `/${pageUserType}/${admin_location_id}/divisions`;
  } else {
    linkBack = `/divisions${type == 'All' ? '' : `/${pageUserType}`}`;
  }

  const handleDeleteDivision = (e) => {
    e.preventDefault();
    e.stopPropagation();

    Modal.confirm({
      title: "Are you sure?",
      content: "Are you sure you want to delete this division?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        deleteDivison.mutate();
      },
    });
  };

  return (
    <div className="wrapper">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="content">
        <div className="profile">
          <div className="profileContainer">
            <div className="profileSection">
              <Link
                to={linkBack}
                className="backButtonSection"
              >
                <KeyboardBackspaceIcon className="backButton" />
              </Link>
              <br />
              <br />
              {(event == null || accountNoDescList == null || accountNoDescList.length == 0) && <div>
                <div style={{ padding: 20, textAlign: 'center' }}>
                  <CircularProgress size={40} color="inherit" />
                </div>
              </div>}
              {event != null && accountNoDescList && accountNoDescList.length > 0 && <form onSubmit={(e) => handleSubmit(e)}>
                <div className="container">
                  <div className="row">
                    <div className="row">
                      <div className="col col-sm">
                        <h1>{viewMode ? 'Division Details' : 'Create New Division'}</h1>
                      </div>
                      <div className="col col-sm" style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                        {viewMode && <button type="button"
                          className="saveBtn btn btn-primary btn-block mb-3"
                          onClick={handleDeleteDivision}
                        >
                          Delete
                        </button>}
                        <button
                          type="submit"
                          className="saveBtn btn btn-primary btn-block mb-3"
                        >
                          {viewMode ? 'Save Changes' : 'Create'}
                        </button>
                      </div>
                    </div>
                    <hr />

                    <div className="row">
                      <div className="col col-8">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="division_name">
                            <b>Division name</b>
                          </label>
                          <br />
                          <input
                            className="form-control"
                            type="text"
                            name="division_name"
                            id="division_name"
                            value={event?.division_name}
                            onChange={(e) => handleChange(e)}
                            required
                          />
                        </div>
                        <div className="form-outline2">
                          <label className="form-label">
                            <b>Account No Desc</b>
                          </label>
                          <br />
                          <Autocomplete
                            multiple
                            id="combo-box"
                            options={accountNoDescList || []}
                            fullWidth
                            loading={isAccountsFetching}
                            disabled={false}
                            renderInput={(params) => (
                              <TextField
                                sx={{ "& input": { width: "100%" } }}
                                {...params}
                                placeholder="Select account no desc"
                              />
                            )}
                            onChange={(e, value) => {
                              setEvent({ ...event, account_no_desc: value });
                            }}
                            filterSelectedOptions
                            value={event?.account_no_desc}
                          />
                        </div>
                        <div className="form-outline2" style={{ paddingTop: '5px' }}>
                          <label className="form-label">
                            <b>Division Head Users</b>
                          </label>
                          <br />
                          <Autocomplete
                            multiple
                            id="combo-box"
                            options={(users?.rows || []).filter(u => u.email)}
                            fullWidth
                            getOptionLabel={(option) => `${option.given_name || ''} ${option.surname || ''}(${option.email})`}
                            loading={isUsersFetching}
                            disabled={false}
                            renderInput={(params) => (
                              <TextField
                                sx={{ "& input": { width: "100%" } }}
                                {...params}
                                placeholder="Select division head user"
                              />
                            )}
                            onChange={(e, value) => {
                              setEvent({ ...event, division_head_users: value });
                            }}
                            filterSelectedOptions
                            value={event?.division_head_users || []}
                          />
                        </div>
                      </div>
                      <div className="col col-4">

                      </div>
                    </div>
                  </div>
                </div>
              </form>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DivisionAdd;
