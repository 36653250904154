import React, { useEffect, useState } from "react";
import { Stack, Button } from "@mui/material";
import { Link, useParams } from "react-router-dom";
// import { useQuery } from "react-query";

import Sidebar from "../components/Sidebar";
import { useMe } from "../hooks/useMe";
import API from "../APIs/API";
// import { makeQueryString } from "../APIs/request";
// import { usePaginationParams } from "../hooks/usePaginationParams";

import "../styles/reports.scss";

const ReportPart = ({ name = "", label, variant = "chart" }) => {
  return (
    <Stack key={name} spacing={2}>
      <div className="reportsTitle">{label}</div>
      <Button
        component={Link}
        to={`/reports/table/daily/${name}`}
        variant="outlined"
      >
        Daily Attendance Reports
      </Button>
      <Button
        component={Link}
        to={`/reports/${variant}/weekly/${name}`}
        variant="outlined"
      >
        Weekly Attendance Reports
      </Button>
      <Button
        component={Link}
        to={`/reports/${variant}/fortnightly/${name}`}
        variant="outlined"
      >
        Fortnightly Attendance Reports
      </Button>
      <Button
        component={Link}
        to={`/reports/${variant}/monthly/${name}`}
        variant="outlined"
      >
        Monthly Attendance Reports
      </Button>
    </Stack>
  );
};

const ReportPartTopLevel = ({ name = "", label }) => {
  const { admin_location_id } = useParams();
  const { permissions } = useMe(admin_location_id);

  // const { admin_location_id, usertype } = useParams();

  // const { queryParams } = usePaginationParams({
  //   initialSortModel: [{ field: "name_report", sort: "asc" }],
  //   initialPageSize: 1
  // });
  // const { data: attendance, isFetching } = useQuery(
  //   [
  //     "attendance_fortnightly",
  //     makeQueryString(queryParams, {
  //       admin_location_id: admin_location_id,
  //       pay_no: '-1',
  //     }),
  //   ],
  //   {
  //     queryFn: async () => {
  //       const queryString = makeQueryString(queryParams, {
  //         admin_location_id: admin_location_id,
  //         pay_no: '-1',
  //         user_type: usertype,
  //       });
  //       const result = await new API().getAttendanceFortnightly(queryString);
  //       if (result != null) {
  //         return result;
  //       }
  //       return [];
  //     },
  //     cacheTime: 1000 * 60 * 60, // 1 hour
  //     staleTime: 1000 * 60 * 60, // 1 hour
  //   },
  // );

  return (
    <Stack key={name} spacing={2}>
      <div className="reportsTitle">{label}</div>
      <Button
        component={Link}
        to={`/reports/daily/${name}`}
        variant="outlined"
      >
        Daily Attendance Reports
      </Button>
      <Button
        component={Link}
        to={`/reports/weekly/${name}`}
        variant="outlined"
      >
        Weekly Attendance Reports
      </Button>
      <Button
        component={Link}
        to={`/reports/fortnightly/${name}`}
        variant="outlined"
      >
        Fortnightly Attendance Reports
      </Button>
      <Button
        component={Link}
        to={`/reports/monthly/${name}`}
        variant="outlined"
      >
        Monthly Attendance Reports
      </Button>
      {(permissions.superRead || permissions.non_teacher || permissions.teacher || permissions.paydocking_user) && <Button
        component={Link}
        to={`/reports/pay-docking/${name}`}
        variant="outlined"
      >
        Approve Pay Docking
      </Button>}
    </Stack>
  );
};

const Reports = () => {
  const { usertype, admin_location_id } = useParams();
  const { permissions } = useMe(admin_location_id);

  const params = [usertype, admin_location_id].filter(Boolean).join("/");

  const [adminLocation, setAdminLocation] = useState({
    admin_location: "",
    admin_desc: "",
  });

  useEffect(() => {
    new API().getAdminLocationById(admin_location_id).then((data) => {
      if (data != null) {
        setAdminLocation({ ...data["data"] });
      }
    });
  }, [admin_location_id]);

  return (
    <div className="wrapper">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="content reports">
        {permissions.superRead && !params && (
          <Stack direction="column" spacing={2}>
            <Stack spacing={2}>
              <div className="reportsTitle">
                Master Reports
              </div>
              <Button
                component={Link}
                to="/reports/chart/daily"
                variant="outlined"
              >
                Daily Attendance Reports
              </Button>
              <Button
                component={Link}
                to="/reports/chart/weekly"
                variant="outlined"
              >
                Weekly Attendance Reports
              </Button>
              <Button
                component={Link}
                to="/reports/chart/fortnightly"
                variant="outlined"
              >
                Fortnightly Attendance Reports
              </Button>
              <Button
                component={Link}
                to="/reports/chart/monthly"
                variant="outlined"
              >
                Monthly Attendance Reports
              </Button>
            </Stack>
            <Stack spacing={2}>
              <div className="reportsTitle">Groups Reports</div>
              <Stack direction="row" spacing={2}>
                {[
                  ["Teachers", "teachers"],
                  ["Non-Teachers", "non-teachers"],
                ].map(([label, name]) => (
                  <ReportPart key={name} name={name} label={label} />
                ))}
              </Stack>
            </Stack>
          </Stack>
        )}
        {params && (
          <Stack spacing={2}>
            <ReportPartTopLevel
              name={params}
              label={"Attendance report for " + (adminLocation?.admin_desc || '...')}
            />
          </Stack>
        )}
      </div>
    </div>
  );
};

export default Reports;
