import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useQuery } from "react-query";
import moment from "moment/moment";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import { usePaginationParams } from "../hooks/usePaginationParams";
import { makeQueryString } from "../APIs/request";
import API from "../APIs/API";
import Sidebar from "../components/Sidebar";
import { useMe, normalizeReportName } from "../hooks/useMe";

import "../styles/list.scss";

const Leave = ({ type, check_admin_loc_id }) => {
  let pageUserType = type === "Non-Teacher" ? "non-teachers" : "teachers";
  let pathname = window.location.pathname;
  let admin_location_id = check_admin_loc_id ? pathname.split("/")[2] : null;
  const [eventLoc, setEventLoc] = useState();
  const [tab, setTab] = useState(1);
  const { user: loggedUser, permissions } = useMe(admin_location_id);

  const { gridParams, queryParams } = usePaginationParams();
  const { gridParams: gridParams2, queryParams: queryParams2 } = usePaginationParams();

  const { data: applyForLeave = [], isFetching } = useQuery([
    "leave",
    makeQueryString(queryParams, { admin_location_id, user_type: (type || '').toLowerCase() }),
  ], {
    queryFn: async () => {
      const queryString = makeQueryString(queryParams, {
        admin_location_id, user_type: (type || '').toLowerCase()
      });
      return await new API().getAllLeave(queryString);
    },
    enabled: !!admin_location_id || type != null,
  });

  const { data: resumeWork = [], isResumeWorkFetching } = useQuery([
    "resumeWork",
    makeQueryString(queryParams2, { admin_location_id, user_type: (type || '').toLowerCase() }),
  ], {
    queryFn: async () => {
      const queryString = makeQueryString(queryParams2, {
        admin_location_id, user_type: (type || '').toLowerCase()
      });
      return await new API().getAllLeaveReturn(queryString);
    },
    enabled: !!admin_location_id || type != null,
  });

  useEffect(() => {
    if (admin_location_id) {
      new API().getAdminLocationById(admin_location_id).then((data) => {
        if (data != null) {
          setEventLoc(`for ${data["data"].admin_desc || ''}`);
        }
      });
    } else {
      let el = (type && type != 'All') ? `for ${type}` : '';
      setEventLoc(el);
    }
  }, [admin_location_id]);

  const applyForLeaveColumns = [
    {
      field: "employee_no",
      headerName: "Employee No",
      width: 180,
      renderCell: ({ row }) => {
        return row.employee_no?.employee_no || '';
      },
    },
    {
      field: "name_report",
      headerName: "Name Report",
      width: 220,
      renderCell: ({ row }) => {
        return normalizeReportName(row.employee_no?.name_report || '');
      },
    },
    {
      field: "occup_pos_title",
      headerName: "Occup Pos Title",
      width: 300,
      renderCell: ({ row }) => {
        return row.employee_no?.occup_pos_title || '';
      },
    },
    {
      field: "type_of_leave",
      headerName: "Leave Type",
      width: 200,
    },
    {
      field: "start_date",
      headerName: "Leave Start Date",
      width: 180,
      renderCell: ({ row }) => {
        return (
          <>
            <p>{moment(row.start_date).format("D MMM YYYY")}</p>
          </>
        );
      },
    },
    {
      field: "end_date",
      headerName: "Leave End Date",
      width: 180,
      renderCell: ({ row }) => {
        return (
          <>
            <p>{moment(row.end_date).format("D MMM YYYY")}</p>
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 180
    },
    {
      field: "branch_head_status",
      headerName: "Branch Head",
      width: 180,
    },
    {
      field: "division_head_status",
      headerName: "Division Head",
      width: 180,
    },
    {
      field: "authorized_officer_status",
      headerName: "Authorized Officer",
      width: 180,
    },
    {
      field: "created_at",
      headerName: "Created at",
      width: 200,
      renderCell: ({ row }) => {
        return (
          <>
            <p>{moment(row.created_at).format("YYYY-MM-DD HH:mm:ss")}</p>
          </>
        );
      },
    },
    {
      field: "updated_at",
      headerName: "Updated at",
      width: 200,
      renderCell: ({ row }) => {
        return (
          <>
            <p>{moment(row.updated_at).format("YYYY-MM-DD HH:mm:ss")}</p>
          </>
        );
      },
    },
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 80,
      renderCell: (params) => {
        let linkTo = `/${pageUserType}/${admin_location_id}/leave/view/${params.row.id}`;

        if (!admin_location_id) {
          linkTo = `view/${params.row.id}`;
        }

        return (
          <div className="cellAction">
            <Link
              to={linkTo}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
          </div>
        );
      },
    },
  ];

  const resumeWorkColumns = [
    {
      field: "employee_no",
      headerName: "Employee No",
      width: 180,
      renderCell: ({ row }) => {
        return row.employee_no?.employee_no || '';
      },
    },
    {
      field: "name_report",
      headerName: "Name Report",
      width: 220,
      renderCell: ({ row }) => {
        return row.employee_no?.name_report || '';
      },
    },
    {
      field: "occup_pos_title",
      headerName: "Occup Pos Title",
      width: 300,
      renderCell: ({ row }) => {
        return row.employee_no?.occup_pos_title || '';
      },
    },
    {
      field: "type_of_leave",
      headerName: "Leave Type",
      width: 200,
      renderCell: ({ row }) => {
        return row.leave_info?.type_of_leave
      },
    },
    {
      field: "start_date",
      headerName: "Leave Start Date",
      width: 180,
      renderCell: ({ row }) => {
        return (
          <>
            <p>{moment(row.start_date).format("D MMM YYYY")}</p>
          </>
        );
      },
    },
    {
      field: "end_date",
      headerName: "Leave End Date",
      width: 180,
      renderCell: ({ row }) => {
        return (
          <>
            <p>{moment(row.end_date).format("D MMM YYYY")}</p>
          </>
        );
      },
    },
    {
      field: "end_actual_return_datedate",
      headerName: "Actual Resume Date",
      width: 180,
      renderCell: ({ row }) => {
        return (
          <>
            <p>{moment(row.actual_return_date).format("D MMM YYYY")}</p>
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 180
    },
    {
      field: "branch_head_status",
      headerName: "Branch Head",
      width: 180,
    },
    // {
    //   field: "division_head_status",
    //   headerName: "Division Head",
    //   width: 180,
    // },
    // {
    //   field: "authorized_officer_status",
    //   headerName: "Authorized Officer",
    //   width: 180,
    // },
    {
      field: "created_at",
      headerName: "Created at",
      width: 200,
      renderCell: ({ row }) => {
        return (
          <>
            <p>{moment(row.created_at).format("YYYY-MM-DD HH:mm:ss")}</p>
          </>
        );
      },
    },
    {
      field: "updated_at",
      headerName: "Updated at",
      width: 200,
      renderCell: ({ row }) => {
        return (
          <>
            <p>{moment(row.updated_at).format("YYYY-MM-DD HH:mm:ss")}</p>
          </>
        );
      },
    },
  ];

  const actionColumn2 = [
    {
      field: "action",
      headerName: "Action",
      width: 80,
      renderCell: (params) => {
        let linkTo = `/${pageUserType}/${admin_location_id}/leave/return/view/${params.row.id}`;

        if (!admin_location_id) {
          linkTo = `view/${params.row.id}`;
        }

        return (
          <div className="cellAction">
            <Link
              to={linkTo}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <div className="wrapper">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="content">
        <div className="list">
          <div className="listContainer">
            <div className="datatable">
              {admin_location_id != null && <>
                <Link to={`/${pageUserType}`} className="backButtonSection">
                  <KeyboardBackspaceIcon className="backButton" />
                </Link>
                <br />
                <br />
              </>}

              <div className="datatableTitle">
                Leave {eventLoc}
                {admin_location_id != null && <div 
                  style={{ display: 'flex', gap: '7px', minWidth: '450px', justifyContent: 'flex-end' }}>
                  {permissions.superRead && <Link
                    to={`/${pageUserType}/${admin_location_id}/leave/credits`}
                    className="link"
                  >
                    <button className="addBtn" style={{ float: 'unset' }}>Leave Credits</button>
                  </Link>}
                  <Link
                    to={`/${pageUserType}/${admin_location_id}/leave/apply`}
                    className="link"
                  >
                    <button className="addBtn" style={{ float: 'unset' }}>Apply for Leave</button>
                  </Link>
                  <Link
                    to={`/${pageUserType}/${admin_location_id}/leave/return`}
                    className="link"
                  >
                    <button className="addBtn" style={{ float: 'unset' }}>Resume Work</button>
                  </Link>
                </div>}
                {/* {admin_location_id == null && <Link
                  to={`/leave${type == 'All' ? '' : `/${pageUserType}`}/add`}
                  className="link"
                >
                  <button className="addBtn">Add new</button>
                </Link>} */}
              </div>
              <div style={{ paddingBottom: '10px' }}>
                <ButtonGroup variant="outlined" size="small" className="leave-btn-groups">
                  <Button className={tab == 1 ? 'active' : ''} onClick={e => setTab(1)}>Apply for Leave</Button>
                  <Button className={tab == 2 ? 'active' : ''} onClick={e => setTab(2)}>Resume Work</Button>
                </ButtonGroup>
              </div>
              {tab == 1 && <DataGrid
                className="datagrid"
                rows={applyForLeave?.rows || []}
                columns={actionColumn.concat(applyForLeaveColumns)}
                rowCount={applyForLeave?.count ?? 0}
                loading={isFetching}
                {...gridParams}
              />}
              {tab == 2 && <DataGrid
                className="datagrid"
                rows={resumeWork?.rows || []}
                columns={actionColumn2.concat(resumeWorkColumns)}
                rowCount={resumeWork?.count ?? 0}
                loading={isResumeWorkFetching}
                {...gridParams2}
              />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leave;
