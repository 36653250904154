import React, { useState } from "react";
import { Form, Input, AutoComplete } from "antd";
import CircularProgress from '@mui/material/CircularProgress';
import { useQuery, useMutation } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { toast } from "react-toastify";
import {Modal} from "antd";

import Sidebar from "../components/Sidebar";

import API from "../APIs/API";

const StaffForm = ({ type, viewMode }) => {
  const { admin_location_id, id } = useParams();
  const pageUserType = type === "Non-Teacher" ? "non-teachers" : "teachers";

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [data, setData] = useState({});

  const { data: row, isFetching: isPositionFetching } = useQuery(
    [
      "employee",
      id
    ],
    {
      queryFn: async () => {
        const result = await new API().getStaffById(id);
        if (result != null) {
          return result.data;
        }
        return null;
      },
      enabled: id != null
    }
  );

  const { data: adminLocation } = useQuery({
    queryFn: async () => {
      const result = await new API().getAdminLocationById(admin_location_id);
      if (result) {
        return result["data"];
      }
      return null;
    },
  });

  const onChange = (e) => {
    setData({ ...data, [e.target.name || e.target.id]: e.target.value });
  };

  const { data: staffPosition, isFetching } = useQuery([
    "staffPositions",
    admin_location_id
  ], {
    queryFn: async () => {
      return await new API().getStaffPositions(admin_location_id);
    },
    cacheTime: 1000 * 60 * 60, // 1 hour
  });

  const createStaff = useMutation((body) => new API().createStaff(body), {
    onSuccess: (data) => {
      if (data != null) {
        toast.success("Data Created Successfully!");
        navigate(`/${pageUserType}/${admin_location_id}/staff`);
      } else {
        toast.error("Data Creation Failed!");
      }
    },
  });

  const staffUpdate = useMutation((body) => new API().updateStaff(body, row.id), {
    onSuccess: (data) => {
      if (data != null) {
        toast.success("Data Updated Successfully!");
        navigate(`/${pageUserType}/${admin_location_id}/staff`);
      } else {
        toast.error("Data Update Failed!");
      }
    },
  });

  const fields = [
    {
      label: "Admin Desc",
      name: "admin_desc",
      disabled: true,
      rules: [{ required: false }],
    },
    {
      label: 'Possition No',
      name: 'position_no',
      onChange: (e) => onChange(e),
      rules: [{ required: true, message: 'Please input Possition No!' }]
    },
    {
      label: 'Occup Pos Title',
      name: 'occup_pos_title',
      onChange: (e) => onChange(e),
      options: staffPosition?.occup_pos_title.map(o => ({ label: o.occup_pos_title, value: o.occup_pos_title })),
      rules: [{ required: true, message: 'Please input Occup Pos Title!' }]
    },
    !viewMode ? null : {
      label: 'Attached',
      name: 'attached',
      onChange: (e) => onChange(e),
      // options: [{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }],
      disabled: true,
      // rules: [{ required: true, message: 'Please input the title!' }]
    },
    {
      label: 'Award Desc',
      name: 'award_desc',
      onChange: (e) => onChange(e),
      options: staffPosition?.award_desc.map(o => ({ label: o.award_desc, value: o.award_desc })),
    },
    {
      label: 'Class Desc',
      name: 'class_desc',
      onChange: (e) => onChange(e),
      options: staffPosition?.class_desc.map(o => ({ label: o.class_desc, value: o.class_desc })),
    },
    {
      label: 'Occup Type',
      name: 'occup_type',
      onChange: (e) => onChange(e),
      options: staffPosition?.occup_type.map(o => ({ label: o.occup_type, value: o.occup_type })),
    },
    {
      label: 'Account No Desc',
      name: 'account_no_desc',
      onChange: (e) => onChange(e),
      options: staffPosition?.account_no_desc.map(o => ({ label: o.account_no_desc, value: o.account_no_desc })),
    },
    {
      label: 'Paypoint Desc',
      name: 'paypoint_desc',
      onChange: (e) => onChange(e),
      options: staffPosition?.paypoint_desc.map(o => ({ label: o.paypoint_desc, value: o.paypoint_desc })),
    },
    {
      label: 'Occup Pos Cat',
      name: 'occup_pos_cat',
      onChange: (e) => onChange(e),
      options: staffPosition?.occup_pos_cat.map(o => ({ label: o.occup_pos_cat, value: o.occup_pos_cat })),
    },
  ].filter(f => f);

  const handleSubmit = () => {
    form
      .validateFields()
      .then(data => {
        if (viewMode) {
          staffUpdate.mutate({
            ...data
          });
          return;
        }

        createStaff.mutate({
          admin_location_id: admin_location_id,
          ...data
        });
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const deleteStaff = useMutation({
    mutationFn: (id) => new API().deleteStaff(id),
    onSuccess: (data) => {
      if (data != null) {
        toast.success("Deleted successfully");
        navigate(`/${pageUserType}/${admin_location_id}/staff`);
      } else {
        toast.error("Deleted Failed!");
      }
    },
  });

  const handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();

    Modal.confirm({
      title: "Are you sure?",
      content: "Do you want to delete this record?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        deleteStaff.mutate(id);
      },
    });
  }

  let linkBack = `/${pageUserType}/${admin_location_id}/staff`;

  return (
    <div className="wrapper">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="content">
        <div className="profile">
          <div className="profileContainer">
            {data == null && <div>
              <div style={{ padding: 20, textAlign: 'center' }}>
                <CircularProgress size={40} color="inherit" />
              </div>
            </div>}
            {data != null && <div className="profileSection">
              <Link to={linkBack} className="backButtonSection">
                <KeyboardBackspaceIcon className="backButton" />
              </Link>
              <br />
              <br />

              <div className="container">
                <div className="row">
                  <div className="row">
                    <div className="col col-4">
                      <h1>{viewMode ? 'Details' : 'Create New'}</h1>
                    </div>
                    <div className="col col-8">
                      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                        {viewMode && <button
                          type="button"
                          className="saveBtn btn btn-primary btn-block mb-3"
                          onClick={(e) => handleDelete(e)}
                        >
                          Delete
                        </button>}
                        <button
                          type="submit"
                          className="saveBtn btn btn-primary btn-block mb-3"
                          onClick={e => handleSubmit()}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div className="row">
                    {(isFetching || isPositionFetching) && <div style={{ padding: 20, textAlign: 'center' }}>
                      <CircularProgress size={40} color="inherit" />
                    </div>}
                    {!isFetching && !isPositionFetching && <Form form={form}
                      initialValues={{ ...(row || {}), admin_desc: adminLocation?.admin_desc }}
                      size="large" className="staff-add-new staff-form">
                      {fields.map(field => (
                        <Form.Item
                          key={field.name}
                          label={field.label}
                          name={field.name}
                          rules={field.rules}
                          layout="vertical"
                          className={field.options == null ? 'no-autocomplete' : ''}
                          style={{ paddingBottom: '15px' }}
                        >
                          {field.options == null && <Input
                            placeholder={field.placeholder}
                            value={field.value}
                            onChange={field.onChange}
                            disabled={field.disabled}
                          />}
                          {field.options != null && <AutoComplete
                            popupClassName="certain-category-search-dropdown"
                            options={field.options}
                            name={field.name}
                            size="large"
                            onChange={value => field.onChange({ target: { value, id: field.name } })}
                          >
                            <Input
                              placeholder={field.placeholder}
                              value={field.value}
                              disabled={field.disabled}
                            />
                          </AutoComplete>}
                        </Form.Item>
                      ))}
                    </Form>}
                  </div>
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffForm;
