import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Checkbox } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useMutation } from "react-query";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import dayjs from "dayjs";
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Modal } from "antd";

import Config from "../config";
import API from "../APIs/API";
import Sidebar from "../components/Sidebar";
import { useMe } from "../hooks/useMe";
import { UploadFile } from "../components/UploadFile";
import { EmployeeReportLink } from "../components/EmployeeReportLink";

import {
  TYPE_OF_LEAVE_F, TYPE_OF_LEAVE_M, AIRPORTS_LIST, allProvinces, getDistricts, getLLG, getWards
} from "../constants";

import "../styles/profile.scss";

const LeaveApply = ({ type, viewMode }) => {
  const { admin_location_id, id } = useParams();
  const { user: loggedUser, permissions } = useMe(admin_location_id);

  const pageUserType = type === "Non-Teacher" ? "non-teachers" : "teachers";
  const navigate = useNavigate();

  let initialData = {
    admin_location_id: admin_location_id,
    start_date: null,
    end_date: null,
    user_type: ((type && type != 'All') ? type : ''),
    created_by: loggedUser?.email
  };

  console.log({ loggedUser })

  const [event, setEvent] = useState(null);
  const [departureLeavePlaces, setDepartureLeavePlaces] = useState([{}]);
  const [returnLeavePlaces, setReturnLeavePlaces] = useState([{}]);
  const [leaveToBeSpent, setLeaveToBeSpent] = useState([]);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [editable, setEditable] = useState(!viewMode);
  const [isApproved, setIsApproved] = useState(false);
  const [isReturned, setIsReturned] = useState(false);
  const [isDivisionHead, setIsDivisionHead] = useState(false);
  const [leaveCredits, setLeaveCredits] = useState(null);
  const [showLeaveCredits, setShowLeaveCredits] = useState(false);
  const [employee, setEmployee] = useState(null);

  const [districts_from, setDistricts_from] = useState({});
  const [LLG_from, setLLG_from] = useState({});
  const [wards_from, setWards_from] = useState({});

  const [districts_to, setDistricts_to] = useState({});
  const [LLG_to, setLLG_to] = useState({});
  const [wards_to, setWards_to] = useState({});

  const processOtherDetails = other_details => {
    const o = JSON.parse(other_details || '{}');
    console.log(o);
    const v = [];
    const b = [];

    if (o) {
      // Village, Ward, LLG, District and Province
      if (o.village) {
        const village = `${o.village}, ${o.ward}, ${o.llg}, ${o.district}, ${o.province}`;
        v.push(village);
      }

      if (o.current_village) {
        const village = `${o.current_village}, ${o.current_ward}, ${o.current_llg}, ${o.current_district}, ${o.current_province}`;
        if (!v.includes(village)) {
          v.push(village);
        }
      }

      if (o.spouse_village) {
        const village = `${o.spouse_village}, ${o.spouse_ward}, ${o.spouse_llg}, ${o.spouse_district}, ${o.spouse_province}`;
        if (!v.includes(village)) {
          v.push(village);
        }
      }

      if (o.spouse_surname) {
        b.push({
          given_name: o.spouse_given_name || '',
          surname: o.spouse_surname,
          dob: o.spouse_date_of_birth ? moment(o.spouse_date_of_birth).format('D MMM YYYY') : '',
          age: o.spouse_date_of_birth ? new Date().getFullYear() - new Date(o.spouse_date_of_birth).getFullYear() : ''
        })
      }

      let k = 1;

      while (o[k + "_" + "beneficiary_surname"]) {
        const dob = o[k + "_" + "beneficiary_date_of_birth"];
        if (!dob || moment().diff(moment(dob), 'years') < 18) {
          b.push({
            given_name: o[k + "_" + "beneficiary_given_name"] || '',
            surname: o[k + "_" + "beneficiary_surname"],
            dob: dob ? moment(dob).format('D MMM YYYY') : '',
            age: dob ? new Date().getFullYear() - new Date(dob).getFullYear() : ''
          })
        }

        k++;
      }

      setBeneficiaries(b);
    }

    setLeaveToBeSpent(v);
  }

  const loadData = () => {
    new API().getLeaveById(id).then((data) => {
      const leaveData = data["data"]["leave"];
      processOtherDetails(leaveData.other_details);
      setEmployee(leaveData.employee);
      setIsApproved(leaveData.status == 'Approved');
      setIsReturned(leaveData.is_returned);
      setEvent({
        ...leaveData,
        start_date: leaveData.start_date ? dayjs(leaveData.start_date) : null,
        end_date: leaveData.end_date ? dayjs(leaveData.end_date) : null,
        resumption_date: leaveData.resumption_date ? dayjs(leaveData.resumption_date) : null,
      });
      const leave_place_info = leaveData.leave_place_info || [];
      setDepartureLeavePlaces(leave_place_info.filter(l => l.place_type == 'Departure'));
      setReturnLeavePlaces(leave_place_info.filter(l => l.place_type == 'Return'));
    });
  }

  const leaveCreate = useMutation((body) => new API().addLeave(body), {
    onSuccess(data) {
      if (data) {
        toast.success("Leave created Successfully!");
        let linkBack = '';
        if (admin_location_id) {
          linkBack = `/${pageUserType}/${admin_location_id}/leave/view/${data["data"]["id"]}`;
        } else {
          linkBack = `/leave${type == 'All' ? '' : `/${pageUserType}`}`;
        }
        navigate(linkBack);
        // setEvent(null);
        // loadData();
      } else {
        toast.error("Leave creation Failed!");
      }
    },
  });

  const leaveProcess = useMutation((body) => new API().processLeave(body), {
    onSuccess(data) {
      if (data) {
        toast.success("Leave updated Successfully!");
        setEvent(null);
        loadData();
      } else {
        toast.error("Leave update Failed!");
      }
    },
  });

  const leaveUpdate = useMutation((body) => new API().updateLeave(body), {
    onSuccess(data) {
      if (data) {
        toast.success("Leave updated Successfully!");
        setEvent(null);
        loadData();
      } else {
        toast.error("Leave update Failed!");
      }
    },
  });

  const deleteLeave = useMutation({
    mutationFn: () => new API().deleteLeave(id),
    onSuccess: (data) => {
      if (data) {
        toast.success("The leave application has been successfully deleted");
        navigate(`/${pageUserType}/${admin_location_id}/leave`);
      } else {
        toast.error("Failed to delete!");
      }
    },
  });

  const handleDeleteLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    Modal.confirm({
      title: "Are you sure?",
      content: "Are you sure you want to delete this leave application?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        deleteLeave.mutate();
      },
    });
  };

  useEffect(() => {
    if (loggedUser && !viewMode) {
      processOtherDetails(loggedUser?.other_details);
      setEmployee(loggedUser);
    }
    if (loggedUser) {
      new API().getLeaveCredits(loggedUser.employee_no).then((data) => {
        setLeaveCredits(data.data);
      });
    }
  }, [loggedUser])

  useEffect(() => {
    if (loggedUser && event && viewMode) {
      const account_no_desc = event.employee_info?.account_no_desc || '';
      if (loggedUser.divisions && loggedUser.divisions.length > 0) {
        for (let idx = 0; idx < loggedUser.divisions.length; idx++) {
          const division = loggedUser.divisions[idx];

          if (division.account_no_desc && division.account_no_desc.length > 0) {
            if (division.account_no_desc.includes(account_no_desc)) {
              setIsDivisionHead(true);
            }
          }
        }
      }
    }
  }, [loggedUser, event])

  useEffect(() => {
    if (id && viewMode) {
      loadData();
    } else {
      setEvent(initialData);
    }
  }, [id])

  const handleChange = (e) => {
    setEvent({ ...event, [e.target.name]: e.target.value });
  };

  const getPlaces = (arr, type) => {
    const handleChange = (p, e) => {
      p[e.target.name] = e.target.value;

      if (type == 'Departure') {
        setDepartureLeavePlaces([...departureLeavePlaces]);
      } else {
        setReturnLeavePlaces([...returnLeavePlaces]);
      }
    }
    return arr.map((p, idx) => <div key={idx} className={"mode_of_travel " + (editable ? 'has-remove' : '')}>
      <div>
        <div className="form-outline">
          <label className="form-label" htmlFor="mode_of_travel">
            <b>Mode of travel</b>
          </label>
          <br />
          <select
            className="form-control"
            name="mode_of_travel"
            id="mode_of_travel"
            value={p.mode_of_travel}
            onChange={(e) => handleChange(p, e)}
            disabled={!editable}
          >
            <option key={'none'} value={''}>{''}</option>
            <option key={'Air'} value={'Air'}>{'Air'}</option>
            <option key={'Sea'} value={'Sea'}>{'Sea'}</option>
            <option key={'Land'} value={'Land'}>{'Land'}</option>
          </select>
        </div>
      </div>
      <div>
        <div className="form-outline">
          <label className="form-label" htmlFor="from">
            <b>From</b>
          </label>
          <br />
          {(!p.mode_of_travel || p.mode_of_travel == 'Air') && <select
            className="form-control"
            name="from"
            id="from"
            value={p.from}
            onChange={(e) => handleChange(p, e)}
            disabled={!editable}
          >
            <option key={'none'} value={''}>{''}</option>
            {!p.mode_of_travel && <option key={'None'} value={'None'} disabled>Please select a mode of travel first</option>}
            {p.mode_of_travel == 'Air' && AIRPORTS_LIST.map((item) => (
              <option key={item} value={item}>{item}</option>
            ))}
          </select>}
          {p.mode_of_travel && p.mode_of_travel != 'Air' && <div className="province-groups">
            <div>
              <div>Province</div>
              <select
                className="form-control"
                name="from_province"
                id="from_province"
                value={p.from_province}
                disabled={!editable}
                onChange={(e) => {
                  districts_from[idx] = getDistricts(e.target.value);
                  setDistricts_from({ ...districts_from });
                  handleChange(p, e);
                }}
              >
                <option key={'none'} value={''}>{''}</option>
                {allProvinces.map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div>
              <div>District</div>
              <select
                className="form-control"
                name="from_district"
                id="from_district"
                value={p.from_district}
                disabled={!editable}
                onChange={(e) => {
                  LLG_from[idx] = getLLG(p.from_province, e.target.value);
                  setLLG_from({ ...LLG_from });
                  handleChange(p, e);
                }}
              >
                <option key={'none'} value={''}>{''}</option>
                {(districts_from[idx] || []).map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
                {viewMode && p.from_district &&
                  <option key={p.from_district} value={p.from_district}>{p.from_district}</option>
                }
              </select>
            </div>
            <div>
              <div>LLG</div>
              <select
                className="form-control"
                name="from_llg"
                id="from_llg"
                value={p.from_llg}
                disabled={!editable}
                onChange={(e) => {
                  wards_from[idx] = getWards(p.from_province, p.from_district, e.target.value);
                  setWards_from({ ...wards_from });
                  handleChange(p, e);
                }}
              >
                <option key={'none'} value={''}>{''}</option>
                {(LLG_from[idx] || []).map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
                {viewMode && p.from_llg &&
                  <option key={p.from_llg} value={p.from_llg}>{p.from_llg}</option>
                }
              </select>
            </div>
            <div>
              <div>Ward</div>
              <select
                className="form-control"
                name="from_ward"
                id="from_ward"
                value={p.from_ward}
                disabled={!editable}
                onChange={(e) => {
                  handleChange(p, e);
                }}
              >
                <option key={'none'} value={''}>{''}</option>
                {(wards_from[idx] || []).map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
                {viewMode && p.from_ward &&
                  <option key={p.from_ward} value={p.from_ward}>{p.from_ward}</option>
                }
              </select>
            </div>
            <div>
              <div>Town/Village</div>
              <input
                className="form-control"
                type="text"
                name="from_village"
                id="from_village"
                value={p.from_village}
                onChange={(e) => handleChange(p, e)}
                disabled={!editable}
              />
            </div>
          </div>}
        </div>
      </div>
      <div>
        <div className="form-outline">
          <label className="form-label" htmlFor="to">
            <b>To</b>
          </label>
          <br />
          {(!p.mode_of_travel || p.mode_of_travel == 'Air') && <select
            className="form-control"
            name="to"
            id="to"
            value={p.to}
            onChange={(e) => handleChange(p, e)}
            disabled={!editable}
          >
            <option key={'none'} value={''}>{''}</option>
            {!p.mode_of_travel && <option key={'None'} value={'None'} disabled>Please select a mode of travel first</option>}
            {p.mode_of_travel == 'Air' && AIRPORTS_LIST.map((item) => (
              <option key={item} value={item}>{item}</option>
            ))}
          </select>}
          {p.mode_of_travel && p.mode_of_travel != 'Air' && <div className="province-groups">
            <div>
              <div>Province</div>
              <select
                className="form-control"
                name="to_province"
                id="to_province"
                value={p.to_province}
                disabled={!editable}
                onChange={(e) => {
                  districts_to[idx] = getDistricts(e.target.value);
                  setDistricts_to({ ...districts_to });
                  handleChange(p, e);
                }}
              >
                <option key={'none'} value={''}>{''}</option>
                {allProvinces.map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div>
              <div>District</div>
              <select
                className="form-control"
                name="to_district"
                id="to_district"
                value={p.to_district}
                disabled={!editable}
                onChange={(e) => {
                  LLG_to[idx] = getLLG(p.to_province, e.target.value);
                  setLLG_to({ ...LLG_to });
                  handleChange(p, e);
                }}
              >
                <option key={'none'} value={''}>{''}</option>
                {(districts_to[idx] || []).map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
                {viewMode && p.to_district &&
                  <option key={p.to_district} value={p.to_district}>{p.to_district}</option>
                }
              </select>
            </div>
            <div>
              <div>LLG</div>
              <select
                className="form-control"
                name="to_llg"
                id="to_llg"
                value={p.to_llg}
                disabled={!editable}
                onChange={(e) => {
                  wards_to[idx] = getWards(p.to_province, p.to_district, e.target.value);
                  setWards_to({ ...wards_to });
                  handleChange(p, e);
                }}
              >
                <option key={'none'} value={''}>{''}</option>
                {(LLG_to[idx] || []).map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
                {viewMode && p.to_llg &&
                  <option key={p.to_llg} value={p.to_llg}>{p.to_llg}</option>
                }
              </select>
            </div>
            <div>
              <div>Ward</div>
              <select
                className="form-control"
                name="to_ward"
                id="to_ward"
                value={p.to_ward}
                disabled={!editable}
                onChange={(e) => {
                  handleChange(p, e);
                }}
              >
                <option key={'none'} value={''}>{''}</option>
                {(wards_to[idx] || []).map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
                {viewMode && p.to_ward &&
                  <option key={p.to_ward} value={p.to_ward}>{p.to_ward}</option>
                }
              </select>
            </div>
            <div>
              <div>Town/Village</div>
              <input
                className="form-control"
                type="text"
                name="to_village"
                id="to_village"
                value={p.to_village}
                disabled={!editable}
                onChange={(e) => handleChange(p, e)}
              />
            </div>
          </div>}
        </div>
      </div>
      <div>
        <div className="form-outline">
          <label className="form-label" htmlFor="company">
            <b>Name of company</b>
          </label>
          <br />
          <input
            className="form-control"
            type="text"
            name="company"
            id="company"
            value={p.company}
            disabled={!editable}
            onChange={(e) => handleChange(p, e)}
          />
        </div>
      </div>
      {editable && <div className="remove-btn" onClick={e => {
        if (type == 'Departure') {
          departureLeavePlaces.splice(idx, 1);
          setDepartureLeavePlaces([...departureLeavePlaces]);
        } else {
          returnLeavePlaces.splice(idx, 1);
          setReturnLeavePlaces([...returnLeavePlaces]);
        }
      }}><RemoveCircleOutlineIcon /></div>}
    </div>)
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (viewMode) {
      const payload = {
        employee_no: loggedUser.employee_no,
        leave_places: [].concat(departureLeavePlaces.map(l => ({ ...l, place_type: 'Departure' })))
          .concat(returnLeavePlaces.map(l => ({ ...l, place_type: 'Return' })))
      };

      for (const key in event) {
        if (Object.prototype.hasOwnProperty.call(event, key)) {
          let el = event[key];
          if (el && el instanceof dayjs) {
            el = el.format("YYYY-MM-DD");
          }
          payload[key] = el;
        }
      }

      leaveUpdate.mutate(payload);
      return;
    }
    if (!event.start_date) {
      toast.error("Please select start date!");
      return;
    }

    if (!event.end_date) {
      toast.error("Please select end date!");
      return;
    }

    const arr = [
      'Recreation Leave',
      'Compassionate Leave',
      'Furlough Leave',
      'Maternity Leave',
      'Paternity Leave',
      'Sick Leave'
    ];
    const isShow = arr.includes(event.type_of_leave);
    console.log(event.type_of_leave, isShow)
    if (isShow) {
      const leave_credits = Number(leaveCredits.find(t => t.type == event.type_of_leave.split(' ')[0])?.available || 0);
      console.log({ leave_credits, no_of_days: event.no_of_days })
      if (Number(event.no_of_days) > leave_credits) {
        toast.error(`You have ${event.type_of_leave} credits of ${leave_credits} day(s) only, for extra days you need to apply for Leave Without Pay (LWOP).`, { className: 'big-toast' });
        return;
      }
    }

    if (!event.declare_statements_checked) {
      toast.error("You must agree to the statement!");
      return;
    }

    // start_date: event.start_date ? event.start_date.format("YYYY-MM-DD") : null,
    // end_date: event.end_date ? event.end_date.format("YYYY-MM-DD") : null
    const payload = {
      employee_no: loggedUser.employee_no,
      leave_places: [].concat(departureLeavePlaces.map(l => ({ ...l, place_type: 'Departure' })))
        .concat(returnLeavePlaces.map(l => ({ ...l, place_type: 'Return' })))
    };

    for (const key in event) {
      if (Object.prototype.hasOwnProperty.call(event, key)) {
        let el = event[key];
        if (el && el instanceof dayjs) {
          el = el.format("YYYY-MM-DD");
        }
        payload[key] = el;
      }
    }

    leaveCreate.mutate(payload);
  };

  const calcBusinessDays = (startDate, endDate) => {
    var day = startDate.clone();
    var businessDays = 0;

    while (day.isBefore(endDate, 'day') || day.isSame(endDate, 'day')) {
      if (day.day() != 0 && day.day() != 6) businessDays++;
      day = day.add(1, 'day');
    }
    return businessDays;
  }

  let linkBack = '';
  if (admin_location_id) {
    linkBack = `/${pageUserType}/${admin_location_id}/leave`;
  } else {
    linkBack = `/leave${type == 'All' ? '' : `/${pageUserType}`}`;
  }

  const getLeaveReportURL = (row) => {
    let url = Config.api_url + "/v1/admin/leave/print_leave_form?id=" + row.id;
    return url;
  };

  const getLeaveReturnReportURL = (row) => {
    let url = Config.api_url + "/v1/admin/leave/print_leave_return_form?id=" + row.return_info?.id;
    return url;
  };

  const addDepartures = () => {
    departureLeavePlaces.push({});
    setDepartureLeavePlaces([...departureLeavePlaces]);
  }

  const addReturns = () => {
    returnLeavePlaces.push({});
    setReturnLeavePlaces([...returnLeavePlaces]);
  }

  return (
    <div className="wrapper">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="content">
        <div className="profile">
          <div className="profileContainer">
            {(event == null || leaveCredits == null) && <div>
              <div style={{ padding: 20, textAlign: 'center' }}>
                <CircularProgress size={40} color="inherit" />
              </div>
            </div>}
            {event != null && leaveCredits != null && <div className="profileSection">
              <Link to={linkBack} className="backButtonSection">
                <KeyboardBackspaceIcon className="backButton" />
              </Link>
              <br />
              <br />

              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="container">
                  <div className="row">
                    <div className="row">
                      <div className="col col-4">
                        <h1>Apply for Leave</h1>
                      </div>
                      <div className="col col-8">
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                          {viewMode && isReturned && <EmployeeReportLink
                            url={getLeaveReturnReportURL(event)} className={'saveBtn btn btn-primary btn-block mb-3'}
                            filename={`leave_return_${event.employee_info?.name_report || event.employee_no}`}
                          >
                            Print Return from Leave Report
                          </EmployeeReportLink>}
                          {viewMode && isApproved && <EmployeeReportLink
                            url={getLeaveReportURL(event)} className={'saveBtn btn btn-primary btn-block mb-3'}
                            filename={`leave_${event.employee_info?.name_report || event.employee_no}`}
                          >
                            Print Report
                          </EmployeeReportLink>}
                          {!viewMode && <button
                            type="submit"
                            className="saveBtn btn btn-primary btn-block mb-3"
                          >
                            Submit
                          </button>}
                          {!isReturned && viewMode && !permissions.employee_user && event?.return_info != null && <button
                            type="button"
                            className={'saveBtn btn btn-primary btn-block mb-3' + (isReturned ? ' disabled' : '')}
                            disabled={isReturned}
                            onClick={e => {
                              let linkBack = '';
                              if (admin_location_id) {
                                linkBack = `/${pageUserType}/${admin_location_id}/leave/return/view/${event?.return_info.id}`;
                              } else {
                                linkBack = `/leave${type == 'All' ? '' : `/${pageUserType}`}/return/view/${event?.return_info.id}`;
                              }
                              navigate(linkBack);
                            }}
                          >
                            {!isReturned ? 'View Return Form' : 'Returned To Work'}
                          </button>}
                          {viewMode && !isApproved && <button
                            type="button"
                            className={'saveBtn btn btn-primary btn-block mb-3'}
                            onClick={e => handleDeleteLeave(e)}
                          >
                            Delete
                          </button>}
                          {viewMode && (isApproved || (!permissions.employee_user && !permissions.salary_officer_user)) && <button
                            type="submit"
                            className={'saveBtn btn btn-primary btn-block mb-3' + (isApproved ? ' disabled' : '')}
                            disabled={isApproved}
                          >
                            {isApproved ? 'Processed' : 'Save Changes'}
                          </button>}
                        </div>
                      </div>
                    </div>
                    <hr />

                    <div className="row">
                      <div className="row" style={{ margin: '0px' }}>
                        <div className="col col-4">
                          <div className="form-outline">
                            <label className="form-label" htmlFor="type_of_leave">
                              <b>Type of leave</b>
                            </label>
                            <br />
                            <select
                              className="form-control"
                              name="type_of_leave"
                              id="type_of_leave"
                              value={event?.type_of_leave}
                              required
                              disabled={!editable}
                              onChange={(e) => {
                                const arr = [
                                  'Recreation Leave',
                                  'Compassionate Leave',
                                  'Furlough Leave',
                                  'Maternity Leave',
                                  'Paternity Leave',
                                  'Sick Leave'
                                ];
                                const isShow = arr.includes(e.target.value);
                                setShowLeaveCredits(isShow);
                                let leave_credits = null;
                                if (isShow) {
                                  leave_credits = leaveCredits.find(t => t.type == e.target.value.split(' ')[0])?.available || 0;
                                }
                                setEvent({ ...event, leave_credits, [e.target.name]: e.target.value });
                              }}
                            >
                              <option key={'none'} value={''}>{''}</option>
                              {(loggedUser.gender == 'M' ? TYPE_OF_LEAVE_M : TYPE_OF_LEAVE_F).map((item) => (
                                <option key={item} value={item}>{item}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                        {showLeaveCredits && <div className="col col-4">
                          <div className="form-outline">
                            <label className="form-label" htmlFor="leave_credits">
                              <b>Leave credits</b>
                            </label>
                            <br />
                            <input
                              className="form-control"
                              type="text"
                              name="leave_credits"
                              id="leave_credits"
                              value={event?.leave_credits}
                              disabled={true}
                            />
                          </div>
                        </div>}
                        {event?.type_of_leave == 'Other' && <div className="col col-8">
                          <div className="form-outline">
                            <label className="form-label" htmlFor="leave_name">
                              <b>Leave name</b>
                            </label>
                            <br />
                            <input
                              className="form-control"
                              type="text"
                              name="leave_name"
                              id="leave_name"
                              value={event?.leave_name}
                              onChange={(e) => handleChange(e)}
                              disabled={!editable}
                            />
                          </div>
                        </div>}
                      </div>
                      <div className="col col-4">
                        <div className="form-outline">
                          <label className="form-label">
                            <b>Leave start date</b>
                          </label>
                          <br />
                          <DatePicker className="date-input"
                            value={event?.start_date}
                            disabled={!editable}
                            onAccept={value => {
                              let start_date = value;
                              let end_date = event.end_date;

                              if (!end_date) {
                                end_date = start_date;
                              }

                              if (start_date.toDate() > end_date.toDate()) {
                                end_date = start_date;
                              }
                              const no_of_days = calcBusinessDays(start_date, end_date);

                              if (end_date) {
                                var resumption_date = end_date.clone().add(1, 'day');

                                while (resumption_date.day() == 0 || resumption_date.day() == 6) {
                                  resumption_date = resumption_date.add(1, 'day');
                                }

                                setEvent({ ...event, start_date, end_date, no_of_days, resumption_date });
                              } else {
                                setEvent({ ...event, start_date, end_date, no_of_days });
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="col col-4">
                        <div className="form-outline">
                          <label className="form-label">
                            <b>Leave end date</b>
                          </label>
                          <br />
                          <DatePicker className="date-input"
                            value={event?.end_date}
                            disabled={!editable}
                            onAccept={value => {
                              let start_date = event.start_date;
                              let end_date = value;

                              if (!start_date) {
                                start_date = end_date;
                              }

                              if (start_date.toDate() > end_date.toDate()) {
                                start_date = end_date;
                              }
                              const no_of_days = calcBusinessDays(start_date, end_date);

                              if (end_date) {
                                var resumption_date = end_date.clone().add(1, 'day');

                                while (resumption_date.day() == 0 || resumption_date.day() == 6) {
                                  resumption_date = resumption_date.add(1, 'day');
                                }

                                setEvent({ ...event, start_date, end_date, no_of_days, resumption_date });
                              } else {
                                setEvent({ ...event, start_date, end_date, no_of_days });
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="col col-4">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="no_of_days">
                            <b>No of days</b>
                          </label>
                          <br />
                          <input
                            className="form-control"
                            type="text"
                            name="no_of_days"
                            id="no_of_days"
                            value={event?.no_of_days}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col col-4">
                        <div className="form-outline">
                          <label className="form-label">
                            <b>Resumption date</b>
                          </label>
                          <br />
                          <DatePicker className="date-input"
                            value={event?.resumption_date} disabled
                          />
                        </div>
                      </div>
                      <div className="col col-4">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="medical_cert">
                            <b>Medical certificate</b>
                          </label>
                          <br />
                          <select
                            className="form-control"
                            name="medical_cert"
                            id="medical_cert"
                            value={event?.medical_cert}
                            onChange={(e) => handleChange(e)}
                            disabled={!editable}
                          >
                            <option key={'none'} value={''}>{''}</option>
                            <option key={'Yes'} value={'Yes'}>{'Yes'}</option>
                            <option key={'No'} value={'No'}>{'No'}</option>
                          </select>
                        </div>
                      </div>
                      {event?.medical_cert == 'Yes' && <div className="col col-4">
                        <div className="form-outline">
                          <label className="form-label">
                            <b>Upload medical certificate</b>
                            {event?.medical_cert_file != null && <Link style={{ paddingLeft: '7px' }}
                              to={`${Config.api_url}/v1/admin/employee/file/${event.medical_cert_file}`} target="_blank">
                              View uploaded file
                            </Link>}
                          </label>
                          <br />
                          <div>
                            <UploadFile
                              invalidateQueries="employees"
                              url={`${Config.api_url}/v1/admin/employee/file`}
                              name="file"
                              callback={src => setEvent({ ...event, medical_cert_file: src })}
                              disabled={!editable}
                            />
                          </div>
                        </div>
                      </div>}
                      <div className="col col-4">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="advanced_pay">
                            <b>Advanced pay</b>
                          </label>
                          <br />
                          <select
                            className="form-control"
                            name="advanced_pay"
                            id="advanced_pay"
                            value={event?.advanced_pay}
                            onChange={(e) => handleChange(e)}
                            disabled={!editable}
                          >
                            <option key={'none'} value={''}>{''}</option>
                            <option key={'Yes'} value={'Yes'}>{'Yes'}</option>
                            <option key={'No'} value={'No'}>{'No'}</option>
                          </select>
                        </div>
                      </div>
                      {event?.advanced_pay == 'Yes' && <div className="col col-4">
                        <div className="form-outline">
                          <label className="form-label">
                            <b>Upload advanced pay</b>
                            {event?.advanced_pay_file != null && <Link style={{ paddingLeft: '7px' }}
                              to={`${Config.api_url}/v1/admin/employee/file/${event.advanced_pay_file}`} target="_blank">
                              View uploaded file
                            </Link>}
                          </label>
                          <br />
                          <div>
                            <UploadFile
                              invalidateQueries="employees"
                              url={`${Config.api_url}/v1/admin/employee/file`}
                              name="file"
                              callback={src => setEvent({ ...event, advanced_pay_file: src })}
                              disabled={!editable}
                            />
                          </div>
                        </div>
                      </div>}
                      <div className="col col-4">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="supporting_docs">
                            <b>Supporting docs</b>
                          </label>
                          <br />
                          <select
                            className="form-control"
                            name="supporting_docs"
                            id="supporting_docs"
                            value={event?.supporting_docs}
                            onChange={(e) => handleChange(e)}
                            disabled={!editable}
                          >
                            <option key={'none'} value={''}>{''}</option>
                            <option key={'Yes'} value={'Yes'}>{'Yes'}</option>
                            <option key={'No'} value={'No'}>{'No'}</option>
                          </select>
                        </div>
                      </div>
                      {event?.supporting_docs == 'Yes' && <div className="col col-4">
                        <div className="form-outline">
                          <label className="form-label">
                            <b>Upload supporting docs</b>
                            {event?.supporting_docs_file != null && <Link style={{ paddingLeft: '7px' }}
                              to={`${Config.api_url}/v1/admin/employee/file/${event.supporting_docs_file}`} target="_blank">
                              View uploaded file
                            </Link>}
                          </label>
                          <br />
                          <div>
                            <UploadFile
                              invalidateQueries="employees"
                              url={`${Config.api_url}/v1/admin/employee/file`}
                              name="file"
                              callback={src => setEvent({ ...event, supporting_docs_file: src })}
                              disabled={!editable}
                            />
                          </div>
                        </div>
                      </div>}
                      {(event?.type_of_leave == 'Recreation Leave' || event?.type_of_leave == 'Furlough Leave') && <div className="col col-8">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="leave_tobe_sepent_in">
                            <b>Leave to be Spent in</b>
                          </label>
                          <br />
                          <select
                            className="form-control"
                            name="leave_tobe_sepent_in"
                            id="leave_tobe_sepent_in"
                            value={event?.leave_tobe_sepent_in}
                            onChange={(e) => handleChange(e)}
                            disabled={!editable}
                          >
                            <option key={'none'} value={''}>{''}</option>
                            {leaveToBeSpent.map((item) => (
                              <option key={item} value={item}>{item}</option>
                            ))}
                          </select>
                        </div>
                      </div>}
                      {(event?.type_of_leave == 'Recreation Leave' || event?.type_of_leave == 'Furlough Leave') && <div
                        className="row" style={{ margin: '0px' }}>
                        <div className="col col-4">
                          <div className="form-outline">
                            <label className="form-label" htmlFor="leave_fare">
                              <b>Do you require a leave fare</b>
                            </label>
                            <br />
                            <select
                              className="form-control"
                              name="leave_fare"
                              id="leave_fare"
                              value={event?.leave_fare}
                              onChange={(e) => handleChange(e)}
                              disabled={!editable}
                            >
                              <option key={'none'} value={''}>{''}</option>
                              <option key={'Yes'} value={'Yes'}>{'Yes'}</option>
                              <option key={'No'} value={'No'}>{'No'}</option>
                            </select>
                          </div>
                        </div>
                      </div>}
                      <div className="header3">
                        Leave address for communication
                      </div>
                      <div className="row" style={{ margin: '0px' }}>
                        <div className="col col-4">
                          <div className="form-outline">
                            <label className="form-label" htmlFor="mobile_number">
                              <b>Mobile number</b>
                            </label>
                            <br />
                            <input
                              className="form-control"
                              type="text"
                              name="mobile_number"
                              id="mobile_number"
                              value={event?.mobile_number}
                              onChange={(e) => handleChange(e)}
                              disabled={!editable}
                            />
                          </div>
                        </div>
                        <div className="col col-4">
                          <div className="form-outline">
                            <label className="form-label" htmlFor="email_address">
                              <b>Email address</b>
                            </label>
                            <br />
                            <input
                              className="form-control"
                              type="text"
                              name="email_address"
                              id="email_address"
                              value={event?.email_address}
                              onChange={(e) => handleChange(e)}
                              disabled={!editable}
                            />
                          </div>
                        </div>
                      </div>
                      {(event?.type_of_leave == 'Recreation Leave' || event?.type_of_leave == 'Furlough Leave') && <>
                        <div className="header3">
                          Spouse and beneficiaries under 18 years old
                        </div>
                        {(!beneficiaries || beneficiaries.length == 0) && !['Recreation Leave', 'Furlough Leave'].includes(event.type_of_leave) && <div className="no-beneficiaries">
                          The list is empty.
                        </div>}
                        {((beneficiaries && beneficiaries.length > 0) || ['Recreation Leave', 'Furlough Leave'].includes(event.type_of_leave)) && <div className="beneficiaries">
                          <div className="header">
                            <div>Full Name</div>
                            <div>Date of Birth</div>
                            <div>Age</div>
                          </div>
                          {employee != null && ['Recreation Leave', 'Furlough Leave'].includes(event.type_of_leave) && <div>
                            <div>{employee.fullname || (employee.given_name + ' ' + employee.surname)}</div>
                            <div>{employee.date_of_birth ? moment(employee.date_of_birth).format('D MMM YYYY') : ''}</div>
                            <div>{employee.date_of_birth ? new Date().getFullYear() - new Date(employee.date_of_birth).getFullYear() : ''}</div>
                          </div>}
                          {beneficiaries.map((b, idx) => {
                            return <div key={idx}>
                              <div>{b.given_name + ' ' + b.surname}</div>
                              <div>{b.dob}</div>
                              <div>{b.age}</div>
                            </div>
                          })}
                        </div>}
                      </>}
                      {(event?.type_of_leave == 'Recreation Leave' || event?.type_of_leave == 'Furlough Leave') && <>
                        <div className="mt-3 num_of_beneficiaries">
                          <div className="header2">Departure</div>
                          {editable && <div className="num_of_beneficiaries_icon" onClick={e => addDepartures()}><AddCircleOutlineOutlinedIcon /></div>}
                        </div>
                        {getPlaces(departureLeavePlaces, 'Departure')}
                        <div className="mt-3 num_of_beneficiaries">
                          <div className="header2">Returning</div>
                          {editable && <div className="num_of_beneficiaries_icon" onClick={e => addReturns()}><AddCircleOutlineOutlinedIcon /></div>}
                        </div>
                        {getPlaces(returnLeavePlaces, 'Return')}
                        <div className="row" style={{ margin: '0px' }}>
                          <div className="col col-4">
                            <div className="form-outline">
                              <label className="form-label" htmlFor="is_traveling_time_required">
                                <b>Is traveling time required</b>
                              </label>
                              <br />
                              <select
                                className="form-control"
                                name="is_traveling_time_required"
                                id="is_traveling_time_required"
                                value={event?.is_traveling_time_required}
                                disabled={!editable}
                                onChange={(e) => handleChange(e)}
                              >
                                <option key={'none'} value={''}>{''}</option>
                                <option key={'Yes'} value={'Yes'}>{'Yes'}</option>
                                <option key={'No'} value={'No'}>{'No'}</option>
                              </select>
                            </div>
                          </div>
                          {event?.is_traveling_time_required == 'Yes' && <div className="col col-4">
                            <div className="form-outline">
                              <label className="form-label">
                                <b>Upload file</b>
                                {event?.is_traveling_time_required_file != null && <Link style={{ paddingLeft: '7px' }}
                                  to={`${Config.api_url}/v1/admin/employee/file/${event.is_traveling_time_required_file}`} target="_blank">
                                  View uploaded file
                                </Link>}
                              </label>
                              <br />
                              <div>
                                <UploadFile
                                  invalidateQueries="employees"
                                  url={`${Config.api_url}/v1/admin/employee/file`}
                                  name="file"
                                  callback={src => setEvent({ ...event, is_traveling_time_required_file: src })}
                                  disabled={!editable}
                                />
                              </div>
                            </div>
                          </div>}
                        </div>
                      </>}
                      {!viewMode && event?.type_of_leave && <div className="declare-statements">
                        <Checkbox size="medium" onChange={e => {
                          setEvent({ ...event, declare_statements_checked: e.target.checked });
                        }} />
                        <div>I declare that the statements made in this application are correct{(event?.type_of_leave == 'Recreation Leave' || event?.type_of_leave == 'Furlough Leave') ? ` and I choose to have my ${event?.type_of_leave} in ${event?.leave_tobe_sepent_in || ''}` : ''}.</div>
                      </div>}

                      {!permissions.employee_user && viewMode && <>
                        <div className="header3" style={{ marginTop: '20px' }}>
                          Recommendation by Branch/Section Head
                        </div>
                        <div className="row leave-box">
                          <div className="col col-3">
                            <div className="declare-statements2">
                              <Checkbox size="medium" checked={event.branch_head_status == 'Recommended'}
                                disabled={isApproved || !permissions.branch_head_user}
                                onChange={e => {
                                  setEvent({ ...event, branch_head_status: e.target.checked ? 'Recommended' : '' });
                                }} />
                              <div>Recommended</div>
                            </div>
                            <div className="declare-statements2">
                              <Checkbox size="medium" checked={event.branch_head_status == 'Not Recommended'}
                                disabled={isApproved || !permissions.branch_head_user}
                                onChange={e => {
                                  setEvent({ ...event, branch_head_status: e.target.checked ? 'Not Recommended' : '' });
                                }} />
                              <div>Not Recommended</div>
                            </div>
                          </div>
                          {event?.branch_head_status == 'Not Recommended' && <div className="col col-3">
                            <div className="form-outline">
                              <label className="form-label">
                                <b>Upload file</b>
                                {event?.branch_head_status_file != null && <Link style={{ paddingLeft: '7px' }}
                                  to={`${Config.api_url}/v1/admin/employee/file/${event.branch_head_status_file}`} target="_blank">
                                  View uploaded file
                                </Link>}
                              </label>
                              <br />
                              <div>
                                <UploadFile
                                  invalidateQueries="employees"
                                  url={`${Config.api_url}/v1/admin/employee/file`}
                                  name="file"
                                  disabled={isApproved || !permissions.branch_head_user}
                                  callback={src => setEvent({ ...event, branch_head_status_file: src })}
                                />
                              </div>
                            </div>
                          </div>}
                          <div className="col col-6">
                            <div className="form-outline">
                              <label className="form-label" htmlFor="branch_head_status_relief_arrangements">
                                <b>Relief Arrangements</b>
                              </label>
                              <br />
                              <textarea
                                className="form-control"
                                type="text"
                                name="branch_head_status_relief_arrangements"
                                id="branch_head_status_relief_arrangements"
                                rows={3}
                                disabled={isApproved || !permissions.branch_head_user}
                                value={event?.branch_head_status_relief_arrangements}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>
                          {event?.branch_head != null && <div className="leave-timestamp">{event.branch_head.name_report + ', ' + (event.branch_head.occup_pos_title || '') + ', ' + moment(event.branch_head_updated_at).format('D MMM YYYY hh:mm:ss A')}</div>}
                        </div>
                        {![
                          'Sick Leave',
                          'Compassionate Leave',
                          'Maternity Leave',
                          'Paternity Leave',
                        ].includes(event?.type_of_leave) && <>
                            <div className="header3" style={{ marginTop: '10px' }}>
                              Approval by Division Head
                            </div>
                            <div className="row leave-box">
                              <div className="col col-3">
                                <div className="declare-statements2">
                                  <Checkbox size="medium" checked={event.division_head_status == 'Approved'}
                                    disabled={isApproved || (!permissions.division_head_user && !isDivisionHead)}
                                    onChange={e => {
                                      setEvent({ ...event, division_head_status: e.target.checked ? 'Approved' : '' });
                                    }} />
                                  <div>Approved</div>
                                </div>
                                <div className="declare-statements2">
                                  <Checkbox size="medium" checked={event.division_head_status == 'Not Approved'}
                                    disabled={isApproved || (!permissions.division_head_user && !isDivisionHead)}
                                    onChange={e => {
                                      setEvent({ ...event, division_head_status: e.target.checked ? 'Not Approved' : '' });
                                    }} />
                                  <div>Not Approved</div>
                                </div>
                              </div>
                              <div className="col col-6">
                                <div className="form-outline">
                                  <label className="form-label" htmlFor="division_head_status_remark">
                                    <b>Remarks</b>
                                  </label>
                                  <br />
                                  <textarea
                                    className="form-control"
                                    type="text"
                                    name="division_head_status_remark"
                                    id="division_head_status_remark"
                                    rows={3}
                                    disabled={isApproved || (!permissions.division_head_user && !isDivisionHead)}
                                    value={event?.division_head_status_remark}
                                    onChange={(e) => handleChange(e)}
                                  />
                                </div>
                              </div>
                              {event?.division_head != null && <div className="leave-timestamp">{event.division_head.name_report + ', ' + (event.division_head.occup_pos_title || '') + ', ' + moment(event.division_head_updated_at).format('D MMM YYYY hh:mm:ss A')}</div>}
                            </div>
                            <div className="header3" style={{ marginTop: '10px' }}>
                              Department Delegate / Authorised Officer
                            </div>
                            <div className="row leave-box">
                              <div className="col col-6">
                                <label className="form-label"><b>Eligible for Travel Warrant</b></label>
                                <div className="declare-statements2">
                                  <Checkbox size="medium" checked={event.eligible_for_travel_warrant == 'Yes'}
                                    disabled={isApproved || !permissions.agency_head_delegate_user}
                                    onChange={e => {
                                      setEvent({ ...event, eligible_for_travel_warrant: e.target.checked ? 'Yes' : '' });
                                    }} />
                                  <div>Yes</div>
                                </div>
                                <div className="declare-statements2">
                                  <Checkbox size="medium" checked={event.eligible_for_travel_warrant == 'No'}
                                    disabled={isApproved || !permissions.agency_head_delegate_user}
                                    onChange={e => {
                                      setEvent({ ...event, eligible_for_travel_warrant: e.target.checked ? 'No' : '' });
                                    }} />
                                  <div>No</div>
                                </div>
                              </div>
                              {event?.authorized_officer != null && <div className="leave-timestamp">{event.authorized_officer.name_report + ', ' + (event.authorized_officer.occup_pos_title || '') + ', ' + moment(event.authorized_officer_updated_at).format('D MMM YYYY hh:mm:ss A')}</div>}
                            </div>
                          </>}
                      </>}
                      {viewMode && <div style={{ display: 'flex', gap: '15px', paddingTop: '25px' }}>
                        <div style={{ paddingTop: '4px', fontSize: '18px' }}>Leave Status:</div>
                        <button
                          type="button"
                          style={{ float: 'unset' }}
                          className={'saveBtn btn btn-primary btn-block mb-3' + ((isApproved || !permissions.salary_officer_user) ? ' disabled' : '')}
                          disabled={isApproved || !permissions.salary_officer_user}
                          onClick={e => {
                            leaveProcess.mutate({
                              id: event.id
                            });
                          }}
                        >
                          {isApproved ? 'Processed' : 'Pending'}
                        </button>
                      </div>}
                    </div>
                  </div>
                </div>
              </form>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaveApply;
