import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../APIs/API";
import Sidebar from "../components/Sidebar";
import "../styles/profile.scss";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useMutation } from "react-query";
import { DatePicker } from "@mui/x-date-pickers";
import {useMe} from "../hooks/useMe";
import {Switch} from "@mui/material";

const EventAdd = ({ type }) => {
  const {user: loggedUser} = useMe();

  const pageUserType = type === "Non-Teacher" ? "non-teachers" : "teachers";
  const { admin_location_id } = useParams();
  const navigate = useNavigate();
  const eventCreate = useMutation((body) => new API().addEvent(body), {
    onSuccess(data) {
      if (data) {
        toast.success("Event created Successfully!");
        let linkBack = '';
        if (admin_location_id) {
          linkBack = `/${pageUserType}/${admin_location_id}/events/view/${data["data"]["id"]}`;
        } else {
          linkBack = `/events${type == 'All' ? '' : `/${pageUserType}`}/view/${data["data"]["id"]}`;
        }
        navigate(linkBack);
      } else {
        toast.error("Event creation Failed!");
      }
    },
  });

  let initialEvent = {
    admin_location_id: admin_location_id,
    event_name: '',
    date: null,
    to_date: null,
    is_recurring: false,
    user_type: ((type && type != 'All') ? type : ''),
    created_by: loggedUser?.email
  };

  const [event, setEvent] = useState(initialEvent);

  const handleChange = (e) => {
    setEvent({ ...event, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!event.date) {
      toast.error("Please select date!");
      return;
    }
    eventCreate.mutate({
      ...event,
      date: event.date ? event.date.format("YYYY-MM-DD") : null,
      to_date: event.to_date ? event.to_date.format("YYYY-MM-DD") : null
    });
  };

  let linkBack = '';
  if (admin_location_id) {
    linkBack = `/${pageUserType}/${admin_location_id}/events`;
  } else {
    linkBack = `/events${type == 'All' ? '' : `/${pageUserType}`}`;
  }

  return (
    <div className="wrapper">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="content">
        <div className="profile">
          <div className="profileContainer">
            <div className="profileSection">
              <Link
                to={linkBack}
                className="backButtonSection"
              >
                <KeyboardBackspaceIcon className="backButton" />
              </Link>
              <br />
              <br />

              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="container">
                  <div className="row">
                    <div className="row">
                      <div className="col col-sm">
                        <h1>Create New Event</h1>
                      </div>
                      <div className="col col-sm">
                        <button
                          type="submit"
                          className="saveBtn btn btn-primary btn-block mb-3"
                        >
                          Create
                        </button>
                      </div>
                    </div>
                    <hr />

                    <div className="row">
                      <div className="col col-8">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="event_name">
                            <b>Event name</b>
                          </label>
                          <br />
                          <input
                            className="form-control"
                            type="text"
                            name="event_name"
                            id="event_name"
                            value={event?.event_name}
                            onChange={(e) => handleChange(e)}
                            required
                          />
                        </div>
                        <div className="row">
                          <div className="col col-6">
                            <div className="form-outline">
                              <label className="form-label">
                                <b>From date</b>
                              </label>
                              <br />
                              <DatePicker className="date-input"
                                value={event?.date}
                                onAccept={value => {
                                  let date = value;
                                  let to_date = event.to_date;

                                  if (!to_date) {
                                    to_date = date;
                                  }

                                  if (date.toDate() > to_date.toDate()) {
                                    to_date = date;
                                  }
                                  setEvent({ ...event, date, to_date });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col col-6">
                            <div className="form-outline">
                              <label className="form-label">
                                <b>To date</b>
                              </label>
                              <br />
                              <DatePicker className="date-input"
                                value={event?.to_date}
                                onAccept={value => {
                                  let date = event.date;
                                  let to_date = value;

                                  if (!date) {
                                    date = to_date;
                                  }

                                  if (date.toDate() > to_date.toDate()) {
                                    date = to_date;
                                  }

                                  setEvent({ ...event, date, to_date });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col col-6">
                            <div className="form-outline">
                              <label className="form-label" htmlFor="is_recurring">
                                <b>Recurring event</b>
                              </label>
                              <br/>
                              <Switch
                                checked={event?.is_recurring}
                                onChange={() =>
                                  setEvent({ ...event, is_recurring: !event?.is_recurring })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col col-4">

                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventAdd;
