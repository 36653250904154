import React, {useEffect, useState} from "react";
import {Link, useParams, useNavigate} from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {Modal} from "antd";
import {toast} from "react-toastify";
import {useMutation} from "react-query";
import API from "../APIs/API";
import Sidebar from "../components/Sidebar";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {Switch} from "@mui/material";
import {useMe} from "../hooks/useMe";

import "../styles/profile.scss";

const EventView = ({type}) => {
  const {permissions} = useMe();

  let pageUserType = type === "Non-Teacher" ? "non-teachers" : "teachers";
  const {admin_location_id, id} = useParams();
  let initialEvent = {
    admin_location_id: admin_location_id,
    event_name: '',
    date: null,
    to_date: null,
    is_recurring: false,
    created_by: null,
    user_type: ((type && type != 'All') ? type : ''),
    admin_location: null
  }

  const navigate = useNavigate();
  const [adminLocation, setAdminLocation] = useState({
    admin_location: "",
    admin_desc: "",
  });
  const [event, setEvent] = useState(initialEvent);
  const [editable, setEdit] = useState(false);

  let linkBack = '';

  if (admin_location_id) {
    linkBack = `/${pageUserType}/${admin_location_id}/events`;
  } else {
    linkBack = `/events${type == 'All' ? '' : `/${pageUserType}`}`;
  }

  const deleteEvent = useMutation({
    mutationFn: () => new API().deleteEvent(id),
    onSuccess: (data) => {
      if (data) {
        toast.success("Event deleted successfully");
        navigate(linkBack);
      } else {
        toast.error("Failed to delete Event!");
      }
    },
  });
  const updateEvent = useMutation((body) => new API().updateEvent(body), {
    onSuccess: (data) => {
      if (data) {
        toast.success("Event Updated Successfully!");
      } else {
        toast.error("Event Update Failed!");
      }
    }
  })

  useEffect(() => {
    new API().getEventById(id).then((data) => {
      const eventData = data["data"]["event"];
      setEvent({
        ...eventData,
        date: eventData.date ? dayjs(eventData.date) : null,
        to_date: eventData.to_date ? dayjs(eventData.to_date) : null
      });
      setAdminLocation({...data["data"]["admin_location"]});
    });
  }, [id]);

  useEffect(() => {
    if (event) {
      if (adminLocation.admin_location) {
        return;
      }

      console.log('event.user_type', editable, event.user_type)

      if (event.user_type == '' && permissions.superRead) {
        setEdit(true);
      }
    
      if (event.user_type == 'Non-Teacher' && permissions.non_teacher) {
        setEdit(true);
      }
    
      if (event.user_type == 'Teacher' && permissions.teacher) {
        setEdit(true);
      }
    }
  }, [event])

  const handleChange = (e) => {
    setEvent({...event, [e.target.name]: e.target.value});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateEvent.mutate({
      ...event,
      date: event.date ? event.date.format("YYYY-MM-DD") : null,
      to_date: event.to_date ? event.to_date.format("YYYY-MM-DD") : null
    });
  };
  const handleDeleteEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();

    Modal.confirm({
      title: "Are you sure?",
      content: "Are you sure you want to delete this event?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        deleteEvent.mutate();
      },
    });
  };

  return (
    <div className="wrapper">
      <div className="sidebar">
        <Sidebar/>
      </div>
      <div className="content">
        <div className="profile">
          <div className="profileContainer">
            <div className="profileSection">
              <Link
                to={linkBack}
                className="backButtonSection"
              >
                <KeyboardBackspaceIcon className="backButton"/>
              </Link>
              <br/>
              <br/>
              <form>
                <div className="container">
                  <div className="row">
                    <div className="row">
                      <div className="col col-sm">
                        <h1>Event Details</h1>
                      </div>
                      {editable && !permissions.employee_user && <div className="col col-sm d-flex justify-content-end">
                        <button
                          type="submit"
                          className="saveBtn btn btn-primary btn-block mb-3"
                          onClick={(e) => handleSubmit(e)}
                        >
                          Save changes
                        </button>
                      </div>}
                    </div>
                    <hr/>
                    <div className="row">
                      <div className="col col-8">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="event_name">
                            <b>Event name</b>
                          </label>
                          <br />
                          <input
                            className="form-control"
                            type="text"
                            name="event_name"
                            id="event_name"
                            value={event?.event_name}
                            onChange={(e) => handleChange(e)}
                            disabled={!editable}
                            required
                          />
                        </div>
                        <div className="row">
                          <div className="col col-6">
                            <div className="form-outline">
                              <label className="form-label">
                                <b>From date</b>
                              </label>
                              <br />
                              <DatePicker className="date-input"
                                value={event?.date}
                                disabled={!editable}
                                onAccept={value => {
                                  let date = value;
                                  let to_date = event.to_date;

                                  if (!to_date) {
                                    to_date = date;
                                  }

                                  if (date.toDate() > to_date.toDate()) {
                                    to_date = date;
                                  }
                                  setEvent({ ...event, date, to_date });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col col-6">
                            <div className="form-outline">
                              <label className="form-label">
                                <b>To date</b>
                              </label>
                              <br />
                              <DatePicker className="date-input"
                                value={event?.to_date}
                                disabled={!editable}
                                onAccept={value => {
                                  let date = event.date;
                                  let to_date = value;

                                  if (!date) {
                                    date = to_date;
                                  }

                                  if (date.toDate() > to_date.toDate()) {
                                    date = to_date;
                                  }

                                  setEvent({ ...event, date, to_date });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col col-6">
                            <div className="form-outline">
                              <label className="form-label" htmlFor="is_recurring">
                                <b>Recurring event</b>
                              </label>
                              <br/>
                              <Switch
                                checked={event?.is_recurring}
                                disabled={!editable}
                                onChange={() =>
                                  setEvent({ ...event, is_recurring: !event?.is_recurring })
                                }
                              />
                            </div>
                          </div>
                          <div className="col col-6">
                            <div className="form-outline">
                              <label className="form-label" htmlFor="created_by">
                                <b>Created by</b>
                              </label>
                              <br/>
                              <input
                                className="form-control"
                                type="text"
                                name="created_by"
                                id="created_by"
                                value={event?.creator}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col col-4">

                      </div>
                    </div>
                  </div>
                </div>
              </form>
              {editable && <hr/>}
              {editable && !permissions.employee_user && <div className="container">
                <div className="row">
                  <div className="col col-sm">
                    <button
                      className="app-button app-button-primary"
                      onClick={handleDeleteEvent}
                    >
                      Delete Event
                    </button>
                  </div>
                  <div className="col col-sm"></div>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventView;
