import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Users from "./pages/Users";
import Reports from "./pages/Reports";
import ReportsDailyAttendance from "./pages/ReportsDailyAttendance";
import ProtectedRoutes from "./common/ProtectedRoutes";
import UserProfile from "./pages/UserProfile";
import UserView from "./pages/UserView";
import UserAdd from "./pages/UserAdd";
import Employees from "./pages/Employees";
import EmployeeView from "./pages/EmployeeView";
import EmployeeAdd from "./pages/EmployeeAdd";
import AdminLocations from "./pages/AdminLocations";
import AdminLocationView from "./pages/AdminLocationView";
import Devices from "./pages/Devices";
import DeviceAdd from "./pages/DeviceAdd";
import DeviceView from "./pages/DeviceView";
import AdminLocationUsers from "./pages/AdminLocationUsers";
import UserAddAdmin from "./pages/UserAddAdmin";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import QRCodes from "./pages/QRCodes";
import AdminLocationAdd from "./pages/AdminLocationAdd";
import AdminLocationUserView from "./pages/AdminLocationUserView";
import ReportsFortnightlyAttendance from "./pages/ReportsFortnightlyAttendance";
import ReportsWeeklyAttendance from "./pages/ReportsWeeklyAttendance";
import ReportsMonthlyAttendance from "./pages/ReportsMonthlyAttendance";
import ArchiveEmployees from "./pages/ArchiveEmployees";
import ArchiveEmployeeView from "./pages/ArchiveEmployeeView";
import { NON_TEACHER, TEACHER } from "./constants";
import SuperReadReports from "./pages/chart_report/SuperReadReports";
import SuperReadChartDaily from "./pages/chart_report/SuperReadChartDaily";
import SuperReadChartWeekly from "./pages/chart_report/SuperReadChartWeekly";
import SuperReadChartMonthly from "./pages/chart_report/SuperReadChartMonthly";
import SuperReadChartFortnightly from "./pages/chart_report/SuperReadChartFortnightly";
import NonTeacherReports from "./pages/chart_report/NonTeacherReports";
import TeacherReports from "./pages/chart_report/TeacherReports";
import TeacherChartDaily from "./pages/chart_report/TeacherChartDaily";
import TeacherChartWeekly from "./pages/chart_report/TeacherChartWeekly";
import TeacherChartMonthly from "./pages/chart_report/TeacherChartMonthly";
import TeacherChartFortnightly from "./pages/chart_report/TeacherChartFortnightly";
import NonTeacherChartDaily from "./pages/chart_report/NonTeacherChartDaily";
import NonTeacherChartWeekly from "./pages/chart_report/NonTeacherChartWeekly";
import NonTeacherChartMonthly from "./pages/chart_report/NonTeacherChartMonthly";
import NonTeacherChartFortnightly from "./pages/chart_report/NonTeacherChartFortnightly";
import ReportsPayDocking from "./pages/ReportsPayDockingAttendance";

import Staff from "./pages/Staff";
import StaffForm from "./pages/StaffForm";

import AuditTrial from "./pages/AuditTrial";
import Events from "./pages/Events";
import EventAdd from "./pages/EventAdd";
import EventView from "./pages/EventView";

import Leave from "./pages/Leave";
import LeaveApply from "./pages/LeaveApply";
import LeaveReturn from "./pages/LeaveReturn";
import LeaveCredits from "./pages/LeaveCredits";

import Divisions from "./pages/Divisions";
import DivisionAdd from "./pages/DivisionAdd";

import Branch from "./pages/Branch";
import BranchAdd from "./pages/BranchAdd";

import Hr from "./pages/Hr";
import HrAdd from "./pages/HrAdd";

import "./styles/app.scss";

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="app">
        <ToastContainer
          position="top-center"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          closeButton={false}
        />
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />

            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route
              path="/resetpassword/:email/:token"
              element={<ResetPassword />}
            />

            <Route element={<ProtectedRoutes />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />

              <Route path="/users">
                <Route index element={<Users />} />
                <Route path=":user_id" element={<UserView />} />
                <Route path="add" element={<UserAdd />} />
              </Route>

              <Route path="/employees">
                <Route path=":user_id" element={<EmployeeView />} />
              </Route>

              <Route
                path="/:user_type/:admin_location_id/users/add"
                element={<UserAddAdmin />}
              />
              <Route
                path="/:user_type/:admin_location_id/users/:user_id"
                element={<AdminLocationUserView />}
              />
              <Route path="/events">
                <Route index element={<Events type={"All"} />} />
                <Route path="add" element={<EventAdd type={"All"} />} />
                <Route path="view/:id" element={<EventView type={"All"} />} />
                <Route path="non-teachers">
                  <Route index element={<Events type={"Non-Teacher"} />} />
                  <Route path="add" element={<EventAdd type={"Non-Teacher"} />} />
                  <Route path="view/:id" element={<EventView type={"Non-Teacher"} />} />
                </Route>
                <Route path="teachers">
                  <Route index element={<Events type={"Teacher"} />} />
                  <Route path="add" element={<EventAdd type={"Teacher"} />} />
                  <Route path="view/:id" element={<EventView type={"Teacher"} />} />
                </Route>
              </Route>
              <Route path="/non-teachers">
                <Route
                  index
                  element={<AdminLocations type={"Non-Teacher"} />}
                />
                <Route
                  path=":admin_location_id"
                  element={<Employees type={"Non-Teacher"} />}
                />
                <Route
                  path="add"
                  element={<AdminLocationAdd type={NON_TEACHER} />}
                />
                <Route
                  path=":admin_location_id/:employee_no"
                  element={<EmployeeView type={"Non-Teacher"} />}
                />
                <Route
                  path=":admin_location_id/add"
                  element={<EmployeeAdd type={"Non-Teacher"} />}
                />
                <Route
                  path=":admin_location_id/update"
                  element={<AdminLocationView type={"Non-Teacher"} />}
                />
                <Route
                  path=":admin_location_id/devices"
                  element={<Devices type={"Non-Teacher"} />}
                />
                <Route
                  path=":admin_location_id/devices/add"
                  element={<DeviceAdd type={"Non-Teacher"} />}
                />
                <Route
                  path=":admin_location_id/devices/view/:id"
                  element={<DeviceView type={"Non-Teacher"} />}
                />
                <Route
                  path=":admin_location_id/users"
                  element={<AdminLocationUsers type={"Non-Teacher"} />}
                />
                <Route
                  path=":admin_location_id/qrcodes"
                  element={<QRCodes type={"Non-Teacher"} />}
                />
                <Route
                  path=":admin_location_id/staff"
                  element={<Staff type={"Non-Teacher"} />}
                />
                <Route
                  path=":admin_location_id/staff/view/:id"
                  element={<StaffForm viewMode={true} type={"Non-Teacher"} />}
                />
                <Route
                  path=":admin_location_id/staff/add"
                  element={<StaffForm type={"Non-Teacher"} />}
                />
                <Route
                  path=":admin_location_id/events"
                  element={<Events type={"Non-Teacher"} check_admin_loc_id={true} />}
                />
                <Route
                  path=":admin_location_id/events/add"
                  element={<EventAdd />}
                />
                <Route
                  path=":admin_location_id/events/view/:id"
                  element={<EventView />}
                />
                <Route
                  path=":admin_location_id/leave"
                  element={<Leave type={"Non-Teacher"} check_admin_loc_id={true} />}
                />
                <Route
                  path=":admin_location_id/leave/apply"
                  element={<LeaveApply type={"Non-Teacher"} />}
                />
                <Route
                  path=":admin_location_id/leave/view/:id"
                  element={<LeaveApply viewMode={true} type={"Non-Teacher"} />}
                />
                <Route
                  path=":admin_location_id/leave/credits"
                  element={<LeaveCredits type={"Non-Teacher"} />}
                />
                <Route
                  path=":admin_location_id/leave/return"
                  element={<LeaveReturn type={"Non-Teacher"} />}
                />
                <Route
                  path=":admin_location_id/leave/return/view/:id"
                  element={<LeaveReturn viewMode={true} type={"Non-Teacher"} />}
                />
                <Route
                  path=":admin_location_id/divisions"
                  element={<Divisions type={"Non-Teacher"} check_admin_loc_id={true} />}
                />
                <Route
                  path=":admin_location_id/divisions/add"
                  element={<DivisionAdd type={"Non-Teacher"} />}
                />
                <Route
                  path=":admin_location_id/divisions/view/:id"
                  element={<DivisionAdd viewMode={true} type={"Non-Teacher"} />}
                />
                <Route
                  path=":admin_location_id/branch"
                  element={<Branch type={"Non-Teacher"} check_admin_loc_id={true} />}
                />
                <Route
                  path=":admin_location_id/branch/add"
                  element={<BranchAdd type={"Non-Teacher"} />}
                />
                <Route
                  path=":admin_location_id/branch/view/:id"
                  element={<BranchAdd viewMode={true} type={"Non-Teacher"} />}
                />
                <Route
                  path=":admin_location_id/hr"
                  element={<Hr type={"Non-Teacher"} check_admin_loc_id={true} />}
                />
                <Route
                  path=":admin_location_id/hr/add"
                  element={<HrAdd type={"Non-Teacher"} />}
                />
                <Route
                  path=":admin_location_id/hr/view/:id"
                  element={<HrAdd viewMode={true} type={"Non-Teacher"} />}
                />
              </Route>

              <Route path="/teachers">
                <Route index element={<AdminLocations type={"Teacher"} />} />
                <Route
                  path="add"
                  element={<AdminLocationAdd type={TEACHER} />}
                />
                <Route
                  path=":admin_location_id"
                  element={<Employees type={"Teacher"} />}
                />
                <Route
                  path=":admin_location_id/:employee_no"
                  element={<EmployeeView type={"Teacher"} />}
                />
                <Route
                  path=":admin_location_id/add"
                  element={<EmployeeAdd type={"Teacher"} />}
                />
                <Route
                  path=":admin_location_id/update"
                  element={<AdminLocationView type={"Teacher"} />}
                />
                <Route
                  path=":admin_location_id/devices"
                  element={<Devices type={"Teacher"} />}
                />
                <Route
                  path=":admin_location_id/devices/add"
                  element={<DeviceAdd type={"Teacher"} />}
                />
                <Route
                  path=":admin_location_id/devices/view/:id"
                  element={<DeviceView type={"Teacher"} />}
                />
                <Route
                  path=":admin_location_id/users"
                  element={<AdminLocationUsers type={"Teacher"} />}
                />
                <Route
                  path=":admin_location_id/qrcodes"
                  element={<QRCodes type={"Teacher"} />}
                />
                <Route
                  path=":admin_location_id/staff"
                  element={<Staff type={"Teacher"} />}
                />
                <Route
                  path=":admin_location_id/staff/view/:id"
                  element={<StaffForm viewMode={true} type={"Teacher"} />}
                />
                <Route
                  path=":admin_location_id/staff/add"
                  element={<StaffForm type={"Teacher"} />}
                />
                <Route
                  path=":admin_location_id/events"
                  element={<Events type={"Teacher"} check_admin_loc_id={true} />}
                />
                <Route
                  path=":admin_location_id/events/add"
                  element={<EventAdd />}
                />
                <Route
                  path=":admin_location_id/events/view/:id"
                  element={<EventView />}
                />
                <Route
                  path=":admin_location_id/leave"
                  element={<Leave type={"Teacher"} check_admin_loc_id={true} />}
                />
                <Route
                  path=":admin_location_id/leave/apply"
                  element={<LeaveApply type={"Teacher"} />}
                />
                <Route
                  path=":admin_location_id/leave/credits"
                  element={<LeaveCredits type={"Teacher"} />}
                />
                <Route
                  path=":admin_location_id/leave/view/:id"
                  element={<LeaveApply viewMode={true} type={"Teacher"} />}
                />
                <Route
                  path=":admin_location_id/leave/return"
                  element={<LeaveReturn type={"Teacher"} />}
                />
                <Route
                  path=":admin_location_id/leave/return/view/:id"
                  element={<LeaveReturn viewMode={true} type={"Teacher"} />}
                />
                <Route
                  path=":admin_location_id/divisions"
                  element={<Divisions type={"Teacher"} check_admin_loc_id={true} />}
                />
                <Route
                  path=":admin_location_id/divisions/add"
                  element={<DivisionAdd type={"Teacher"} />}
                />
                <Route
                  path=":admin_location_id/divisions/view/:id"
                  element={<DivisionAdd viewMode={true} type={"Teacher"} />}
                />
                <Route
                  path=":admin_location_id/branch"
                  element={<Branch type={"Teacher"} check_admin_loc_id={true} />}
                />
                <Route
                  path=":admin_location_id/branch/add"
                  element={<BranchAdd type={"Teacher"} />}
                />
                <Route
                  path=":admin_location_id/branch/view/:id"
                  element={<BranchAdd viewMode={true} type={"Teacher"} />}
                />
                <Route
                  path=":admin_location_id/hr"
                  element={<Hr type={"Teacher"} check_admin_loc_id={true} />}
                />
                <Route
                  path=":admin_location_id/hr/add"
                  element={<HrAdd type={"Teacher"} />}
                />
                <Route
                  path=":admin_location_id/hr/view/:id"
                  element={<HrAdd viewMode={true} type={"Teacher"} />}
                />
              </Route>
              <Route path="/archive">
                <Route index element={<ArchiveEmployees />} />
                <Route
                  path="view/:employee_no"
                  element={<ArchiveEmployeeView />}
                />
              </Route>
              <Route path="/super_read_report">
                <Route index element={<SuperReadReports />} />
                <Route path="daily" element={<SuperReadChartDaily />} />
                <Route path="weekly" element={<SuperReadChartWeekly />} />
                <Route path="monthly" element={<SuperReadChartMonthly />} />
                <Route
                  path="fortnightly"
                  element={<SuperReadChartFortnightly />}
                />
              </Route>
              <Route path="/non_teacher_report">
                <Route index element={<NonTeacherReports />} />
                <Route path="daily" element={<NonTeacherChartDaily />} />
                <Route path="weekly" element={<NonTeacherChartWeekly />} />
                <Route path="monthly" element={<NonTeacherChartMonthly />} />
                <Route
                  path="fortnightly"
                  element={<NonTeacherChartFortnightly />}
                />
              </Route>
              <Route path="/teacher_report">
                <Route index element={<TeacherReports />} />
                <Route path="daily" element={<TeacherChartDaily />} />
                <Route path="weekly" element={<TeacherChartWeekly />} />
                <Route path="monthly" element={<TeacherChartMonthly />} />
                <Route
                  path="fortnightly"
                  element={<TeacherChartFortnightly />}
                />
              </Route>
              <Route path="/reports">
                <Route index element={<Reports />} />
                <Route
                  path="location-level/:usertype/:admin_location_id"
                  element={<Reports />}
                />
                <Route
                  path="daily/:usertype?/:admin_location_id?"
                  element={<ReportsDailyAttendance />}
                />
                <Route
                  path="weekly/:usertype?/:admin_location_id?"
                  element={<ReportsWeeklyAttendance />}
                />
                <Route
                  path="fortnightly/:usertype?/:admin_location_id?"
                  element={<ReportsFortnightlyAttendance />}
                />
                <Route
                  path="monthly/:usertype?/:admin_location_id?"
                  element={<ReportsMonthlyAttendance />}
                />
                <Route
                  path="pay-docking/:usertype?/:admin_location_id?"
                  element={<ReportsPayDocking />}
                />
              </Route>
              <Route path="/profile" element={<UserProfile />} />
              <Route
                  path="/audit"
                  element={<AuditTrial />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </LocalizationProvider>
  );
}

export default App;
