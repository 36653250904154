import Sidebar from "../components/Sidebar";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useQuery } from "react-query";
import { makeQueryString } from "../APIs/request";
import { usePaginationParams } from "../hooks/usePaginationParams";
import { useMe } from "../hooks/useMe";
import "../styles/list.scss";
import API from "../APIs/API";
import { ADMIN_LOCATION_LEVEL_USER_OPTIONS } from "../constants";
import { UploadExcel } from "../components/UploadExcel";
import { EmployeeReportLink } from "../components/EmployeeReportLink";
import Config from "../config";

const staffColumns = [
  {
    field: "admin_location",
    headerName: "Admin Location",
    width: 200,
  },
  {
    field: "admin_desc",
    headerName: "Admin Desc",
    width: 200,
  },
  {
    field: "position_no",
    headerName: "Position No",
    width: 200,
  },
  {
    field: "occup_pos_title",
    headerName: "Occup Pos Title",
    width: 200,
  },
  {
    field: "attached",
    headerName: "Attached",
    width: 200,
  },
  {
    field: "award",
    headerName: "Award",
    width: 200,
  },
  {
    field: "award_desc",
    headerName: "Award Desc",
    width: 200,
  },
  {
    field: "classification",
    headerName: "Classification",
    width: 200,
  },
  {
    field: "class_desc",
    headerName: "Class Desc",
    width: 200,
  },
  {
    field: "account_no",
    headerName: "Account No",
    width: 200,
  },
  {
    field: "account_no_desc",
    headerName: "Account No Desc",
    width: 200,
  },
  {
    field: "paypoint",
    headerName: "Paypoint",
    width: 200,
  },
  {
    field: "paypoint_desc",
    headerName: "Paypoint Desc",
    width: 200,
  },
];

const Staff = ({ type }) => {
  const { admin_location_id } = useParams();
  const { gridParams, queryParams } = usePaginationParams();
  const [adminNoDesc, setAdminNoDesc] = useState("All");
  const [paypointDesc, setPaypointDesc] = useState("All");
  const { permissions, user: loggedUser } = useMe();

  const pageUserType = type === "Non-Teacher" ? "non-teachers" : "teachers";

  const { data: adminLocation } = useQuery({
    queryFn: async () => {
      const result = await new API().getAdminLocationById(admin_location_id);
      if (result) {
        return result["data"];
      }
      return null;
    },
  });

  const { data: accountNoDescList = [] } = useQuery(["account_no_desc_list"], {
    queryFn: async () => {
      const result = await new API().getAccountNoDescList(admin_location_id);
      if (result) {
        if (
          loggedUser?.access_privilege === ADMIN_LOCATION_LEVEL_USER_OPTIONS.ACCOUNT_NO_DESC_USER
          || loggedUser?.access_privilege === ADMIN_LOCATION_LEVEL_USER_OPTIONS.PAYPOINT_DESC_USER
        ) {
          setAdminNoDesc(loggedUser.account_no_desc);
        }
        return ["All", ...result["data"]];
      }
      return ["All"];
    },
  });

  const { data: employees = [], isFetching } = useQuery(
    [
      "employees",
      adminNoDesc,
      paypointDesc,
      makeQueryString(queryParams, {
        admin_location_id,
      }),
    ],
    {
      queryFn: async () => {
        let search = {};
        if (adminNoDesc !== "All") {
          search["account_no_desc"] = adminNoDesc;
        }
        if (paypointDesc !== "All") {
          search["paypoint_desc"] = paypointDesc;
        }
        const queryString = makeQueryString(queryParams, search, {
          admin_location_id,
        });
        const result = await new API().getAllStaffs(queryString);
        if (result != null) {
          return result;
        }
        return [];
      },
    },
    { keepPreviousData: true },
  );

  const { data: paypointDescList = [] } = useQuery(["paypoint_desc_list"], {
    queryFn: async () => {
      const result = await new API().getPaypointDescList(
        admin_location_id,
        adminNoDesc,
      );
      if (result) {
        if (
          loggedUser.access_privilege ===
          ADMIN_LOCATION_LEVEL_USER_OPTIONS.PAYPOINT_DESC_USER
        ) {
          setPaypointDesc(loggedUser.paypoint_desc);
        }
        return ["All", ...result["data"]];
      }
      return ["All"];
    },
    enabled: adminNoDesc !== "All",
  });

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 120,
      sortable: false,
      renderCell: (params) => {
        let linkTo = `/${pageUserType}/${admin_location_id}/staff/view/${params.row.id}`;

        return (
          <div className="cellAction">
            <Link
              to={linkTo}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
          </div>
        );
      },
    },
  ];

  const transformedRows = employees?.rows?.map((row) => ({
    ...row
  }));

  const getStaffEstablishmentReportURL = (row) => {
    let search = {};
    if (adminNoDesc !== "All") {
      search["account_no_desc"] = adminNoDesc;
    }
    if (paypointDesc !== "All") {
      search["paypoint_desc"] = paypointDesc;
    }
    const queryString = makeQueryString(queryParams, search, {
      admin_location_id,
    });
    let url = Config.api_url + "/v1/admin/staff/print_staff_establishment" + queryString;
    return url;
  };

  const getUnattachedStaffReportURL = (row) => {
    let search = {};
    if (adminNoDesc !== "All") {
      search["account_no_desc"] = adminNoDesc;
    }
    if (paypointDesc !== "All") {
      search["paypoint_desc"] = paypointDesc;
    }
    const queryString = makeQueryString(queryParams, search, {
      admin_location_id,
    });
    let url = Config.api_url + "/v1/admin/staff/print_unattached_staff_establishment" + queryString;
    return url;
  };

  return (
    <div className="wrapper">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="content">
        <div className="list">
          <div className="listContainer">
            <div className="datatable">
              <Link to={`/${pageUserType}`} className="backButtonSection">
                <KeyboardBackspaceIcon className="backButton" />
              </Link>
              <br />
              <br />
              <div className="datatableTitle" style={{ marginBottom: '5px' }}>
                {adminLocation?.admin_desc ? `${adminLocation.admin_desc} Staff Establishment` : ''}
              </div>
              <div className="datatableTitle" style={{ justifyContent: 'flex-end' }}>
                <div style={{ display: 'flex', gap: '7px', minWidth: '350px', justifyContent: 'flex-end' }}>
                  {permissions.super && (
                    <EmployeeReportLink
                      url={getUnattachedStaffReportURL()} className={'saveBtn addBtn btn btn-primary btn-block'}
                      filename={`unattached_staff_establishment_${adminLocation?.admin_desc}`}
                    >
                      Print Unattached Staff Report
                    </EmployeeReportLink>
                  )}
                  {permissions.super && (
                    <EmployeeReportLink
                      url={getStaffEstablishmentReportURL()} className={'saveBtn addBtn btn btn-primary btn-block'}
                      filename={`staff_establishment_${adminLocation?.admin_desc}`}
                    >
                      Print Report
                    </EmployeeReportLink>
                  )}
                  {permissions.super && (
                    <UploadExcel type={'staff'} adminLocationId={admin_location_id} title={'Upload'} />
                  )}
                  {permissions.super && (
                    <Link
                      to={`/${pageUserType}/${admin_location_id}/staff/add`}
                      className="link"
                    >
                      <button className="addBtn" style={{ float: 'unset' }}>Add New</button>
                    </Link>
                  )}
                </div>
              </div>

              <div className="searchSection">
                <div className="row">
                  <div className="col col-sm-4">
                    <TextField
                      id="outlined-disabled"
                      label="Admin desc"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      value={adminLocation?.admin_desc ?? ""}
                    />
                  </div>
                  <div className="col col-sm-4">
                    <Autocomplete
                      disablePortal
                      id="combo-box"
                      options={accountNoDescList}
                      fullWidth
                      disabled={
                        !loggedUser || loggedUser?.access_privilege ===
                        ADMIN_LOCATION_LEVEL_USER_OPTIONS.ACCOUNT_NO_DESC_USER ||
                        loggedUser?.access_privilege ===
                        ADMIN_LOCATION_LEVEL_USER_OPTIONS.PAYPOINT_DESC_USER
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Account no desc" />
                      )}
                      onChange={(e, value) => setAdminNoDesc(value)}
                      disableClearable
                      value={adminNoDesc}
                    />
                  </div>
                  <div className="col col-sm-4">
                    <Autocomplete
                      hidden={adminNoDesc === "All"}
                      disablePortal
                      id="combo-box"
                      options={paypointDescList}
                      fullWidth
                      disabled={
                        !loggedUser || loggedUser?.access_privilege ===
                        ADMIN_LOCATION_LEVEL_USER_OPTIONS.PAYPOINT_DESC_USER
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Paypoint desc" />
                      )}
                      onChange={(e, value) => setPaypointDesc(value)}
                      disableClearable
                      value={paypointDesc}
                    />
                  </div>
                </div>
              </div>
              <br />
              <DataGrid
                className="datagrid"
                rows={transformedRows || []}
                columns={actionColumn.concat(staffColumns)}
                rowCount={employees?.count ?? 0}
                loading={isFetching}
                {...gridParams}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Staff;
